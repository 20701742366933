export const removeQueryParams = (value: string): string => {
  try {
    const normalizedUrl =
      value.startsWith('http://') || value.startsWith('https://') ? value : `https://${value}`;

    const parsedUrl = new URL(normalizedUrl);
    return `${parsedUrl.origin}${parsedUrl.pathname}`;
  } catch (error) {
    return value;
  }
};
