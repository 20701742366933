import { getBaseURL } from 'core/common/utils/getBaseURL';
import { getOrganicChannel } from 'core/common/utils/getOrganicChannel';
import { removeQueryParams } from 'core/common/utils/removeQueryParams';
import { UtmSource } from 'core/user/entities/UtmTags';
import { ChannelAttributionStrategy } from './ChannelAttributionStrategy';

export class OrganicChannelAttributionStrategy implements ChannelAttributionStrategy {
  private readonly referrer: string;

  constructor(referrer: string) {
    this.referrer = referrer;
  }

  getUtmTags() {
    return {
      utm_source: UtmSource.ORGANIC,
      seo_referrer: removeQueryParams(this.referrer),
      seo_landing_page: getBaseURL(),
      seo_channel: getOrganicChannel(this.referrer) ?? '',
    };
  }
}
