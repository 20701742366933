import { ToEntity } from 'core/common/mappers';
import { PalmPhoto } from '../entities';
import { PalmPhotoDTO } from '../services/palmDetection/dto';

export class PalmDtoMapper implements ToEntity<PalmPhoto, PalmPhotoDTO> {
  toEntity(dto: PalmPhotoDTO): PalmPhoto {
    return {
      photoId: dto.palmistry_photo_id,
      photo: `https://${dto.palmistry_photo_template}`,
      coordinates: {
        lifeLinePts: dto.palmistry_coordinate.life_line_pts,
        heartLinePts: dto.palmistry_coordinate.heart_line_pts,
        marriageLinePts: dto.palmistry_coordinate.marriedge_line_pts,
        headLinePts: dto.palmistry_coordinate.head_line_pts,
        moneyLinePts: dto.palmistry_coordinate.money_line_pts,
        thumbFingerTip: dto.palmistry_coordinate.thumb_finger_tip,
        indexFingerTip: dto.palmistry_coordinate.index_finger_tip,
        middleFingerTip: dto.palmistry_coordinate.middle_finger_tip,
        ringFingerTip: dto.palmistry_coordinate.ring_finger_tip,
        pinkyFingerTip: dto.palmistry_coordinate.pinky_finger_tip,
      },
    };
  }
}
