import { ChannelAttributionService } from './ChannelAttributionService';
import { ChannelAttributionStrategy } from './ChannelAttributionStrategy';
import { DirectChannelAttributionStrategy } from './DirectChannelAttributionStrategy';

export class ChannelAttributionClientService implements ChannelAttributionService {
  private attributionStrategy: ChannelAttributionStrategy;

  constructor() {
    this.attributionStrategy = new DirectChannelAttributionStrategy();
  }

  getUtmTags() {
    return this.attributionStrategy.getUtmTags();
  }

  setAttributionStrategy(attributionUtmTagsStrategy: ChannelAttributionStrategy) {
    this.attributionStrategy = attributionUtmTagsStrategy;
  }
}
