import {
  PaymentError,
  PaymentFormMerchantData,
  PaymentFormPaypalData,
  PaymentProcessSteps,
  ResignFormData,
} from '../entities';

export type PaymentsState = {
  paymentProcessStep: PaymentProcessSteps;
  paymentError: PaymentError | null;
  formMerchantData: PaymentFormMerchantData | null;
  formPaypalData: PaymentFormPaypalData | null;
  resignFormData: ResignFormData | null;
  orderId: string;
};

export const paymentsInitialState: PaymentsState = {
  paymentProcessStep: PaymentProcessSteps.IDLE,
  paymentError: null,
  formMerchantData: null,
  formPaypalData: null,
  resignFormData: null,
  orderId: '',
};
