import amplitude, { Callback } from 'amplitude-js';
import { SpanStatusCode, toSpanAttributes } from 'core/common/observability/entities';
import { ObservabilitySystem } from 'core/common/observability/interfaces';
import { isSuccessStatusCode } from 'core/common/utils/http';
import { AmplitudeService } from '../../interfaces';

export class HttpAmplitudeService implements AmplitudeService {
  private instance: amplitude.AmplitudeClient;

  private userId: string;

  private funnelStage = 'appnebula';

  constructor(
    private readonly observabilitySystem: ObservabilitySystem,
    apiKey: string,
    apiEndpoint: string,
  ) {
    this.instance = amplitude.getInstance();
    this.instance.init(apiKey, undefined, {
      apiEndpoint,
      deviceIdFromUrlParam: true,
    });
    this.userId = '';
  }

  logEvent(
    eventName: string,
    data?: Record<string, unknown>,
    callback?: Callback,
    errorCallback?: Callback,
  ) {
    const span = this.observabilitySystem.startSpan('track_analytics_amplitude', {
      attributes: { eventName, ...toSpanAttributes(data || {}) },
    });

    this.instance.logEvent(
      eventName,
      data,
      (code, response, details) => {
        if (isSuccessStatusCode(code)) {
          span.addEvent('Succeed', {
            code,
            response,
            reason: details && details.reason,
          });
          span.end();
        }

        if (callback) {
          callback(code, response, details);
        }
      },
      (code, body, details) => {
        span.addEvent('Failed', {
          code,
          body,
          reason: details && details.reason,
        });
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: body,
        });
        span.end();

        if (errorCallback) {
          errorCallback(code, body, details);
        }
      },
    );
  }

  setUserProperties(userData: Record<string, unknown>) {
    this.instance.setUserProperties(userData);
  }

  funnelOpened(funnelName: string) {
    this.setUserProperties({
      quiz: funnelName,
    });

    this.logEvent('funnel_open_appnebula', {
      funnel: funnelName,
    });
  }

  flowOpened(flowName: string): void {
    this.setUserProperties({
      nfb_flow: flowName,
    });

    this.logEvent('flow_open_appnebula', {
      nfb_flow: flowName,
    });
  }

  trackUserLanguage(language: string): void {
    this.setUserProperties({
      language,
    });

    this.logEvent('user_language_set_appnebula', {
      language,
    });
  }

  launch() {
    if (!localStorage.getItem('launched')) {
      localStorage.setItem('launched', 'true');
      this.logEvent('launch_first_time', {
        funnel_stage: this.funnelStage,
      });
    } else if (!sessionStorage.getItem('launched')) {
      sessionStorage.setItem('launched', 'true');
      this.logEvent('launch', {
        funnel_stage: this.funnelStage,
      });
    }
  }

  getDeviceId(): string {
    return this.instance.options.deviceId || '';
  }

  getSessionId(): string {
    return String(this.instance.getSessionId());
  }

  trackEvent(quizEvent: string, eventProperties: Record<string, unknown> = {}) {
    this.logEvent(quizEvent, eventProperties);
  }

  setUserId(userId: string) {
    this.instance.setUserId(userId);
    this.userId = userId;
  }

  setEmailConsent(value: string) {
    this.logEvent('consent_page_click', {
      context: value,
    });
  }

  setUserEmail(email: string) {
    this.setUserProperties({ email });
  }

  setUserIdProperty(id: string) {
    this.setUserProperties({ appnebula_id: id });
  }

  flowProcessingPagePopupOpen(value: string) {
    this.logEvent('flow_processing_page_popup_open', {
      context: value,
    });
  }

  flowProcessingPagePopupClicked(value: string) {
    this.logEvent('flow_processing_page_popup_clicked', {
      context: value,
    });
  }

  landingPageOpened(userId: string, pageName: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('landing_page_open', {
      context: pageName,
    });
  }

  landingPageClick(userId: string, pageName: string, productId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('landing_page_click', {
      event_value: 'get',
      context: pageName,
      product_id: productId,
    });
  }

  setUserExperimentGroupName(experimentName: string, groupName: string) {
    this.setUserProperties({
      [experimentName]: groupName,
    });
  }

  policyPageOpened(pageName: string, contextName: string, userId: string) {
    this.logEvent('policies_page_open', {
      page_name: pageName,
      funnel_stage: this.funnelStage,
    });

    this.setUserProperties({
      user_id: userId,
      context: contextName,
    });
  }

  faqPageOpened(pageName: string, contextName: string, userId: string) {
    this.logEvent('faq_page_open', {
      page_name: pageName,
      funnel_stage: this.funnelStage,
    });

    this.setUserProperties({
      user_id: userId,
      context: contextName,
    });
  }

  contactUsPageOpened(pageName: string, contextName: string, userId: string) {
    this.logEvent('support_form_open', {
      page_name: pageName,
      funnel_stage: this.funnelStage,
    });

    this.setUserProperties({
      user_id: userId,
      context: contextName,
    });
  }

  discountLpOpened() {
    this.logEvent('lp_addition_discount_open');
  }

  discountLpGetReport() {
    this.logEvent('lp_addition_discount_get_click');
  }

  paymentCardClick() {
    this.logEvent('card_button_click', {
      funnel_stage: this.funnelStage,
    });
  }

  googlePayClick() {
    this.logEvent('googlepay_button_click', {
      funnel_stage: this.funnelStage,
    });
  }

  applePayClick() {
    this.logEvent('applepay_button_click', {
      funnel_stage: this.funnelStage,
    });
  }

  paypalPayClick() {
    this.logEvent('paypal_button_click', {
      funnel_stage: this.funnelStage,
    });
  }

  paymentCardChosen(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('payment_card_chosen', {
      funnel_stage: this.funnelStage,
    });
  }

  appleBtnChosen(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('payment_apple_pay_chosen', {
      funnel_stage: this.funnelStage,
    });
  }

  googleBtnChosen(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('payment_googlepay_chosen', {
      funnel_stage: this.funnelStage,
    });
  }

  paymentPaypalChosen(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('payment_paypal_chosen', {
      funnel_stage: this.funnelStage,
    });
  }

  paymentError(userId: string, reason: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('payment_error', {
      reason,
      funnel_stage: this.funnelStage,
    });
  }

  paymentRetried(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('payment_retry', {
      funnel_stage: this.funnelStage,
    });
  }

  paymentSuccess({ productId, paymentMethod }: { productId: string; paymentMethod: string }) {
    this.setUserProperties({
      product_id: productId,
      payment_method: paymentMethod,
    });
    this.logEvent('payment_success', {
      product_id: productId,
      funnel_stage: this.funnelStage,
    });
  }

  upsellLpOpened(userId: string) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('lp_upsell_screen_open');
  }

  upsellLpGetReport(product: string) {
    this.logEvent('lp_upsell_get_report_click', {
      product_id: product,
    });
  }

  upsellLpSkip() {
    this.logEvent('lp_upsell_skip_click');
  }

  upsellCreditsLpOpened() {
    this.logEvent('lp_upsell_credits_open');
  }

  upsellCreditsLpGetReport(product: string) {
    this.logEvent('lp_upsell_credits_get_click', {
      product_id: product,
    });
  }

  consultationUpsellGetOffer(product: string) {
    this.logEvent('consultation_upsell_get_offer_click', {
      product_id: product,
    });
  }

  consultationUpsellWithDiscountGetOffer(product: string) {
    this.logEvent('consultation_upsell_with_discount_get_offer_click', {
      product_id: product,
    });
  }

  readingsPackUpsellGetOffer(product: string) {
    this.logEvent('readings_pack_upsell_get_offer_click', {
      product_id: product,
    });
  }

  readingsPackUpsellWithDiscountGetOffer(product: string) {
    this.logEvent('readings_pack_upsell_with_discount_get_offer_click', {
      product_id: product,
    });
  }

  upsellCreditsLpSkip() {
    this.logEvent('lp_upsell_credits_skip_click');
  }

  onIntroPageOpened() {
    this.logEvent('appnebula_intro_open');
  }

  onExpertPageOpened() {
    this.logEvent('appnebula_expert_open');
  }

  onQuestionsPageOpened() {
    this.logEvent('appnebula_questions_open');
  }

  onCreditsPageOpened() {
    this.logEvent('appnebula_credits_open');
  }

  onMatchingPageOpened(expertId: string) {
    this.logEvent('matching_screen_open', {
      expert_id: expertId,
    });
  }

  onMatchingPageClicked() {
    this.logEvent('matching_screen_clicked');
  }

  onLoadingPageOpened() {
    this.logEvent('appnebula_loading_screen_open');
  }

  emailMarketingLpOpened(userId: string, data: { page: string; view: string; source: string }) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('landing_page_open', data);
  }

  emailMarketingLpScrolledToEnd(
    userId: string,
    data: { page: string; view: string; source: string },
  ) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('landing_page_scrolled', data);
  }

  emailMarketingLpGetProduct(
    userId: string,
    data: {
      page: string;
      productId: string;
      button: string;
      source: string;
    },
  ) {
    this.setUserProperties({
      user_id: userId,
    });
    this.logEvent('landing_page_click', {
      page: data.page,
      product_id: data.productId,
      button: data.button,
      source: data.source,
    });
  }

  flowPersonalInfoPolicyPageOpened() {
    this.logEvent('flow_personal_info_policy_open');
  }

  flowPersonalInfoPolicyPageClicked(context: 'do_not_sell' | 'return') {
    this.logEvent('flow_personal_info_policy_open', {
      context,
    });
  }

  hiddenTrialOpen() {
    this.logEvent('hidden_trial_open');
  }

  hiddenTrialClick() {
    this.logEvent('hidden_trial_click');
  }

  hiddenTrialSkip() {
    this.logEvent('hidden_trial_skip');
  }

  hiddenTrialClose() {
    this.logEvent('hidden_trial_close');
  }

  emailUpsellScreenOpen(context: string) {
    this.logEvent('lp_email_upsell_screen_open', {
      context,
    });
  }

  purchaseSucceed(productId: string) {
    this.logEvent('payment_success', {
      product_id: productId,
      funnel_stage: this.funnelStage,
    });
  }

  purchaseError(productId: string) {
    this.logEvent('payment_error', {
      product_id: productId,
      funnel_stage: this.funnelStage,
    });
  }

  cameraLaunched() {
    this.logEvent('camera_ok');
  }

  cameraErrorPopUpOpened() {
    this.logEvent('camera_error_popup_open');
  }

  cameraErrorPopUpClicked() {
    this.logEvent('camera_error_popup_clicked');
  }

  setUserAge(age: number) {
    this.setUserProperties({
      user_age: age,
    });
  }

  upsellPremiumScreenOpened() {
    this.logEvent('upsell_premium_screen_open');
  }

  upsellPremiumScreenClicked() {
    this.logEvent('upsell_premium_screen_click');
  }

  upsellPremiumScreenGetReport(offerId: string) {
    this.logEvent('upsell_premium_screen_click', {
      product_id: offerId,
    });
  }

  consultationUpsellScreenOpened() {
    this.logEvent('consultation_upsell_screen_open');
  }

  readingsPackUpsellScreenOpened() {
    this.logEvent('readings_pack_upsell_screen_open');
  }

  consultationUpsellWithDiscountScreenOpened() {
    this.logEvent('consultation_upsell_with_discount_screen_open');
  }

  readingsPackUpsellWithDiscountScreenOpened() {
    this.logEvent('readings_pack_upsell_with_discount_screen_open');
  }

  natalChartUpsellScreenOpened() {
    this.logEvent('natal_chart_upsell_screen_open');
  }

  consultationUpsellScreenClicked() {
    this.logEvent('consultation_upsell_screen_click');
  }

  consultationUpsellWithDiscountScreenClicked() {
    this.logEvent('consultation_upsell_with_discount_screen_click');
  }

  readingsPackUpsellScreenClicked() {
    this.logEvent('readings_pack_upsell_screen_click');
  }

  readingsPackUpsellWithDiscountScreenClicked() {
    this.logEvent('readings_pack_upsell_with_discount_screen_click');
  }

  natalChartUpsellScreenClicked() {
    this.logEvent('natal_chart_upsell_screen_click');
  }

  phoneNumberScreenOpen() {
    this.logEvent('appnebula_phone_number_open');
  }

  phoneNumberScreenAdd() {
    this.logEvent('appnebula_phone_number_add');
  }

  phoneNumberScreenSkip() {
    this.logEvent('appnebula_phone_number_skip');
  }

  w2aPromoScreenClick(value: string) {
    this.logEvent('appnebula_w2a_promo_screen_click', { event_value: value });
  }

  w2aPromoScreenOpen() {
    this.logEvent('appnebula_w2a_promo_screen_open');
  }

  earlyW2APromoScreenOpen() {
    this.logEvent('appnebula_early_web2app_open');
  }

  earlyW2APromoScreenClick(value: string) {
    this.logEvent('appnebula_early_web2app_click', { context: value });
  }

  humanDesignReportMissed() {
    this.instance.logEvent('appnebula_hd_report_missed');
  }

  secretDiscountOpen() {
    this.logEvent('flow_secret_discount_open');
  }

  secretDiscountClick() {
    this.logEvent('flow_secret_discount_click');
  }

  secretDiscountClose() {
    this.logEvent('flow_secret_discount_close');
  }
}
