import { HTMLAttributes, MouseEvent, TouchEvent, useRef } from 'react';
import { useIsMobile } from 'core/theme/hooks';

type Params = {
  onLongPressStart: () => void;
  onLongPressEnd: () => void;
  onShortPress: (e: TouchEvent | MouseEvent) => void;
  longPressTreshholdMs?: number;
};

export const useScreenPress = ({
  onLongPressStart,
  onLongPressEnd,
  onShortPress,
  longPressTreshholdMs = 200,
}: Params) => {
  const { isMobile } = useIsMobile();

  const isLongPress = useRef(false);
  const longPressTimerId = useRef<NodeJS.Timeout | null>(null);

  const onPressStart = () => {
    isLongPress.current = false;
    longPressTimerId.current = setTimeout(() => {
      onLongPressStart();
      isLongPress.current = true;
    }, longPressTreshholdMs);
  };

  const onPressEnd = (event: TouchEvent | MouseEvent) => {
    if (longPressTimerId.current) {
      clearTimeout(longPressTimerId.current);
    }

    if (!isLongPress.current) {
      onShortPress(event);
    }

    onLongPressEnd();
    isLongPress.current = false;
  };

  const onContextMenu = (event: MouseEvent) => {
    event.preventDefault();
  };

  return {
    onMouseDown: !isMobile ? onPressStart : undefined,
    onMouseUp: !isMobile ? onPressEnd : undefined,
    onTouchStart: isMobile ? onPressStart : undefined,
    onTouchEnd: isMobile ? onPressEnd : undefined,
    onContextMenu,
  } satisfies HTMLAttributes<HTMLDivElement>;
};
