import { normalizeError } from 'core/common/errors';
import { Logger } from 'core/common/services/logger/interfaces';
import { RootInitialState } from 'core/common/store';
import { LocalStorageState, PersistentStorage, SessionStorageState } from './entities';
import { PersistentStorageService } from './interfaces';

export class PersistentStorageClientService implements PersistentStorageService {
  private keyPrefix = 'nebula';
  private logger: Logger;

  constructor(logger: Logger) {
    this.logger = logger;
  }

  load(): Partial<PersistentStorage> {
    try {
      return {
        ...this.loadStateFromLocalStorage(),
        ...this.loadStateFromSessionStorage(),
      };
    } catch (error) {
      this.logger.error(normalizeError(error));
      throw error;
    }
  }

  save(store: RootInitialState): void {
    try {
      this.saveToLocalStorage(store);
      this.saveToSessionStorage(store);
    } catch (error) {
      this.logger.error(normalizeError(error));
      throw error;
    }
  }

  private loadStateFromLocalStorage(): LocalStorageState {
    const serializedLocalStorage = localStorage.getItem(this.keyPrefix);

    if (serializedLocalStorage === null) {
      return {};
    }
    const {
      userProfile,
      experts,
      offers,
      humanDesign,
      featureFlags,
      subscriptions,
      userLocation,
    }: RootInitialState = JSON.parse(serializedLocalStorage);

    return {
      userProfile,
      experts,
      offers,
      humanDesign,
      featureFlags,
      subscriptions,
      userLocation,
    };
  }

  private loadStateFromSessionStorage(): SessionStorageState {
    const serializedSessionStorage = sessionStorage.getItem(this.keyPrefix);

    if (!serializedSessionStorage) {
      return {};
    }
    const { auth, payments } = JSON.parse(serializedSessionStorage);

    return {
      auth,
      payments,
    };
  }

  private saveToLocalStorage(store: RootInitialState): void {
    const serializedLocalStorage = JSON.stringify({
      userProfile: store.userProfile,
      experts: store.experts,
      offers: store.offers,
      humanDesign: store.humanDesign,
      featureFlags: store.featureFlags,
    });

    localStorage.setItem(this.keyPrefix, serializedLocalStorage);
  }

  private saveToSessionStorage(store: RootInitialState): void {
    const serializedSessionStorage = JSON.stringify({
      auth: store.auth,
      payments: store.payments,
    });

    sessionStorage.setItem(this.keyPrefix, serializedSessionStorage);
  }
}
