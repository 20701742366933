import { AnalyticsService } from '../../interfaces';

export class AnalyticsServerService implements AnalyticsService {
  getAttributionParameters() {
    return {
      fbp: '',
      fbc: '',
    };
  }

  getDeviceId() {
    return '';
  }

  getSessionId() {
    return '';
  }

  trackEvent() {
    return null;
  }

  funnelOpened() {
    return null;
  }

  flowOpened() {
    return null;
  }
  trackUserLanguage() {
    return null;
  }

  updateUserProperties() {
    return null;
  }

  setUserAge() {
    return null;
  }

  optOtSellUserData() {
    return null;
  }

  setUserId() {
    return null;
  }

  setIsUserTester() {
    return null;
  }

  setEmailConsent() {
    return null;
  }

  paymentPlanChosen() {
    return null;
  }

  flowProcessingPagePopupOpen() {
    return null;
  }

  flowProcessingPagePopupClicked() {
    return null;
  }

  paymentFormOpened() {
    return null;
  }

  policyPageOpened() {
    return null;
  }

  faqPageOpened() {
    return null;
  }

  contactUsPageOpened() {
    return null;
  }

  setUserExperimentGroupName() {
    return null;
  }

  launch() {
    return null;
  }

  landingPageOpened() {
    return null;
  }

  landingPageClick() {
    return null;
  }

  discountLpOpened() {
    return null;
  }

  discountLpGetReport() {
    return null;
  }

  paymentClick() {
    return null;
  }

  paymentCardChosen() {
    return null;
  }

  paymentPaypalChosen() {
    return null;
  }

  paymentRetried() {
    return null;
  }

  paymentError() {
    return null;
  }

  async paymentSuccess() {
    return null;
  }

  upsellLpOpened() {
    return null;
  }

  upsellLpGetReport() {
    return null;
  }

  upsellLpSkip() {
    return null;
  }

  upsellCreditsLpOpened() {
    return null;
  }

  upsellCreditsLpGetReport() {
    return null;
  }

  consultationUpsellGetOffer() {
    return null;
  }

  consultationUpsellWithDiscountGetOffer() {
    return null;
  }

  readingsPackUpsellGetOffer() {
    return null;
  }

  readingsPackUpsellWithDiscountScreenClicked() {
    return null;
  }

  readingsPackUpsellWithDiscountGetOffer() {
    return null;
  }

  upsellCreditsLpSkip() {
    return null;
  }

  emailUpsellScreenOpen() {
    return null;
  }

  onIntroPageOpened() {
    return null;
  }

  onExpertPageOpened() {
    return null;
  }

  onQuestionsPageOpened() {
    return null;
  }

  onCreditsPageOpened() {
    return null;
  }

  onMatchingPageOpened() {
    return null;
  }

  onMatchingPageClicked() {
    return null;
  }

  onLoadingPageOpened() {
    return null;
  }

  emailMarketingLpOpened() {
    return null;
  }

  emailMarketingLpScrolledToEnd() {
    return null;
  }

  emailMarketingLpGetProduct() {
    return null;
  }

  addToCart() {
    return null;
  }

  initiateCheckout() {
    return null;
  }

  addEmail() {
    return null;
  }

  flowPersonalInfoPolicyPageOpened() {
    return null;
  }

  flowPersonalInfoPolicyPageClicked() {
    return null;
  }

  hiddenTrialOpen() {
    return null;
  }

  hiddenTrialClick() {
    return null;
  }

  hiddenTrialSkip() {
    return null;
  }

  hiddenTrialClose() {
    return null;
  }

  purchaseSucceed() {
    return null;
  }

  purchaseError() {
    return null;
  }

  cameraLaunched() {
    return null;
  }

  cameraErrorPopUpOpened() {
    return null;
  }

  cameraErrorPopUpClicked() {
    return null;
  }

  upsellPremiumScreenOpened() {
    return null;
  }

  consultationUpsellScreenOpened() {
    return null;
  }

  readingsPackUpsellScreenOpened() {
    return null;
  }

  consultationUpsellWithDiscountScreenOpened() {
    return null;
  }

  readingsPackUpsellWithDiscountScreenOpened() {
    return null;
  }

  upsellPremiumScreenClicked() {
    return null;
  }

  upsellPremiumScreenGetReport() {
    return null;
  }

  consultationUpsellScreenClicked() {
    return null;
  }

  consultationUpsellWithDiscountScreenClicked() {
    return null;
  }

  readingsPackUpsellScreenClicked() {
    return null;
  }

  phoneNumberScreenOpen() {
    return null;
  }

  phoneNumberScreenAdd() {
    return null;
  }

  phoneNumberScreenSkip() {
    return null;
  }

  w2aPromoScreenClick() {
    return null;
  }

  w2aPromoScreenOpen() {
    return null;
  }

  earlyW2APromoScreenOpen() {
    return null;
  }

  earlyW2APromoScreenClick() {
    return null;
  }

  lead() {
    return null;
  }

  natalChartUpsellScreenOpened() {
    return null;
  }

  natalChartUpsellScreenClicked() {
    return null;
  }

  natalChartUpsellGetReport() {
    return null;
  }

  addUserId() {
    return null;
  }

  humanDesignReportMissed() {
    return null;
  }

  secretDiscountClose() {
    return null;
  }

  secretDiscountOpen() {
    return null;
  }

  secretDiscountClick() {
    return null;
  }
}
