import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Locales, notPaidUtmSources } from 'core/user/entities';
import { useUserLocation } from 'core/user/hooks';
import { getUtmSource } from '../store/system';

export const useExtendedPolicy = () => {
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(true);
  const [displayPolicyError, setDisplayPolicyError] = useState(false);

  const utmSource = useSelector(getUtmSource);
  const { locale } = useUserLocation();

  useEffect(() => {
    const isNotPaidUtmSource = notPaidUtmSources.includes(utmSource);
    const isUSA = locale === Locales.US;

    if (isNotPaidUtmSource && isUSA) {
      setIsPolicyAccepted(false);
    }
  }, [utmSource, locale]);

  const handlePolicyClick = (accepted: boolean) => {
    setIsPolicyAccepted(accepted);

    if (accepted) {
      setDisplayPolicyError(false);
    }
  };

  return {
    isPolicyAccepted,
    displayPolicyError,
    setDisplayPolicyError,
    handlePolicyClick,
  };
};
