import { PHASE_PRODUCTION_BUILD } from 'next/constants';
import { SECOND } from 'core/common/constants';
import { Config } from 'core/common/contexts';
import { CreditsAmount, PurchaseProductName } from 'core/offers/entities';

export const config: Config = {
  appVersion: process.env.NEXT_PUBLIC_CI_COMMIT_REF_NAME || 'fallback-release',
  appEnvironment: process.env.NEXT_PUBLIC_APP_ENV ?? '',
  address: '62 Athalassas Avenue, Mezzanine Floor, Strovolos, 2012 Nicosia, Cyprus',
  geocodeUrl: process.env.NEXT_PUBLIC_GEOCODE_URL ?? '',
  geocodeApiKey: 'ge-38779bc84df71d40',
  apiPrefix: 'v1',
  applePayContainerId: 'apple-pay-button-container',
  googlePayContainerId: 'google-pay-button-container',
  supportCenter: 'https://osupporto-help.zendesk.com/hc/en-us',
  supportEmail: 'support@nebulahoroscope.com',
  dateOfTerms: '15 September 2023',
  dateOfMoneyBack: '24 November 2021',
  appName: process.env.NEXT_PUBLIC_APP_NAME ?? '',
  rootDomain: process.env.NEXT_PUBLIC_ROOT_DOMAIN ?? '',
  appleSupport: 'https://support.apple.com/en-us/HT202039',
  googleSupport:
    'https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en',
  testingTokenCookieKey: 'tester_token',
  smartlookApiKey: 'f10901819fac158bd2c175efcd9d205e8e91e9d0',
  apiUrl: process.env.NEXT_PUBLIC_API_URL ?? '',
  palmApiUrl: process.env.NEXT_PUBLIC_PALM_API_URL ?? '',
  mainAppUrl: process.env.NEXT_PUBLIC_MAIN_APP_URL ?? '',
  domainUrl: process.env.NEXT_PUBLIC_DOMAIN_URL ?? '',
  amplitudeApiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY ?? '',
  amplitudeApiEndpoint: process.env.NEXT_PUBLIC_AMPLITUDE_API_ENDPOINT ?? '',
  pinterestPixelId: '2612995561264',
  expensivePriceTreshold: 6,
  shopifyCardTarotDeck: 'https://ac460a-3.myshopify.com/cart/45322200088866:1?channel=buy_button',
  askNebulaUrl: process.env.NEXT_PUBLIC_ASK_NEBULA_URL ?? '',
  palmPhotoShortSide: 248,
  palmPhotoLongSide: 330,
  palmAnimationDuration: 3 * SECOND,
  isProduction: process.env.NEXT_PUBLIC_APP_ENV === 'production',
  isStage: process.env.NEXT_PUBLIC_APP_ENV === 'stage',
  isDevelopment: process.env.NODE_ENV === 'development',
  isBuildPhase: process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD,
  sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN ?? '',
  addressUS: '500 N RAINBOW BLVD LAS VEGAS, NV 89107',
  addressAE: 'Dubai, United Arab Emirates',
  companyName: 'Spiritual Nebula Limited',
  cdn: 'https://media.asknebula.com',
  oneTrust: {
    key: process.env.NEXT_PUBLIC_ONE_TRUST_KEY ?? '',
  },
  growthbook: {
    apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_API_URL ?? '',
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY ?? '',
  },
  creditsOfferIds: {
    [CreditsAmount.PLAN_150]: [
      PurchaseProductName.APP_SUBSCRIPTION_6_93_USD_intro_offer_1w_4w_39_99,
      PurchaseProductName.APP_SUBSCRIPTION_6_93_EUR_intro_offer_1w_4w_39_99,
      PurchaseProductName.APP_SUBSCRIPTION_13_50_USD_intro_offer_1w_4w_39_99_timer,
      PurchaseProductName.APP_SUBSCRIPTION_13_50_EUR_intro_offer_1w_4w_39_99_timer,
      PurchaseProductName.APP_SUBSCRIPTION_1_USD_intro_offer_1w_4w_23_99_secret_discount,
      PurchaseProductName.APP_SUBSCRIPTION_1_EUR_intro_offer_1w_4w_23_99_secret_discount,
    ],
    [CreditsAmount.PLAN_210]: [
      PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_29,
      PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_29,
      PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_29,
      PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_29,
      PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_9_SECRET_DISCOUNT,
    ],
    [CreditsAmount.PLAN_300]: [
      PurchaseProductName.APP_SUBSCRIPTION_15_19_USD_intro_offer_4w_4w_39_99,
      PurchaseProductName.APP_SUBSCRIPTION_15_19_EUR_intro_offer_4w_4w_39_99,
      PurchaseProductName.APP_SUBSCRIPTION_39_99_USD_intro_offer_4w_4w_39_99_timer,
      PurchaseProductName.APP_SUBSCRIPTION_39_99_EUR_intro_offer_4w_4w_39_99_timer,
      PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_39,
      PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_39,
      PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_39,
      PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_39,
      PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_55,
      PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_55,
      PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_55,
      PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_55,
      PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_19_SECRET_DISCOUNT_TIER_1,
      PurchaseProductName.APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_39,
      PurchaseProductName.APP_SUBSCRIPTION_5_EUR_7D_TRIAL_30D_39,
      PurchaseProductName.APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_39,
      PurchaseProductName.APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_39,
      PurchaseProductName.APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_55,
      PurchaseProductName.APP_SUBSCRIPTION_5_EUR_7D_TRIAL_30D_55,
      PurchaseProductName.APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_55,
      PurchaseProductName.APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_55,
      PurchaseProductName.APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_19_SECRET_DISCOUNT,
    ],
    [CreditsAmount.PLAN_360]: [
      PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_45,
      PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_45,
      PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_45,
      PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_45,
      PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_65,
      PurchaseProductName.APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_65,
      PurchaseProductName.APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_65,
      PurchaseProductName.APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_65,
      PurchaseProductName.APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_19_SECRET_DISCOUNT,
      PurchaseProductName.APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_45,
      PurchaseProductName.APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_45,
      PurchaseProductName.APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_65,
      PurchaseProductName.APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_65,
    ],
    [CreditsAmount.PLAN_900]: [
      PurchaseProductName.APP_SUBSCRIPTION_25_99_USD_intro_offer_12w_12w_69_99,
      PurchaseProductName.APP_SUBSCRIPTION_25_99_EUR_intro_offer_12w_12w_69_99,
      PurchaseProductName.APP_SUBSCRIPTION_69_99_USD_intro_offer_12w_12w_69_99_timer,
      PurchaseProductName.APP_SUBSCRIPTION_69_99_EUR_intro_offer_12w_12w_69_99_timer,
    ],
  },
  grafanaLogger: {
    url: process.env.NEXT_PUBLIC_GRAFANA_URL || '',
  },
  funnelBuilder: {
    apiHost: process.env.NEXT_PUBLIC_FUNNEL_BUILDER_API_URL ?? '',
    apiKey: process.env.NEXT_PUBLIC_FUNNEL_BUILDER_API_KEY ?? '',
  },
  SVGSprite: {
    inputPath: 'svg-sprites/assets/icons',
    outputPath: 'common/assets/images/sprites',
    typesOutputPath: 'svg-sprites/entities',
    outputName: 'sprite.svg',
  },
  observability: {
    defaultTracerName: 'default_tracer',
    tracesUrl: process.env.NEXT_PUBLIC_TRACES_URL ?? '',
    httpInstrumentationIgnoreUrls: [process.env.NEXT_PUBLIC_GRAFANA_URL].filter(
      Boolean,
    ) as Array<string>,
    httpInstrumentationSpanRate: 0.2,
  },
  buildCache: {
    path: '/.build-request-cache',
  },
  ebanxUrl: 'https://www.ebanx.com/pt-br/legal',
  clarityId: process.env.NEXT_PUBLIC_CLARITY_ID ?? '',
  supportBot: {
    url: 'https://static.zdassets.com/ekr/snippet.js?key=b57b661b-8a7f-40e1-9e62-0f2630788e9f',
    tags: ['appnebula'],
  },
  funnelBuilderTrustedOrigins: [
    'http://localhost:3333',
    'https://funnel-builder.obrio.net',
    'https://stage-funnel-builder.obrio.net',
  ],
  supportPhoneNumber: {
    alias: '+1 646-603-1815',
    original: '+16466031815',
  },
};
