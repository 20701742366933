import { parseQueryParams, stringifyQueryParams } from './getQueryParams';

export const appendQueryParams = (url: string, params: Record<string, string>) => {
  const { href, search, origin, pathname } = new URL(url);

  const initialURLParams = parseQueryParams(search.slice(1));

  const combinedSearchParams = stringifyQueryParams({
    ...initialURLParams,
    ...params,
  });

  if (!combinedSearchParams) return href;

  return `${origin}${pathname}?${combinedSearchParams}`;
};
