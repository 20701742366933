import validator from 'core/common/utils/validator';
import { FunnelImageSchema } from './FunnelData';

export enum StoryVariant {
  INTRO = 'INTRO',
  BASIC = 'BASIC',
  MEDIA = 'MEDIA',
}

const StoryColorVariantSchema = validator.union([
  validator.literal('DARK'),
  validator.literal('LIGHT'),
]);

export type StoryColorVariant = validator.infer<typeof StoryColorVariantSchema>;

const StoryIntroContentSchema = validator.object({
  image: FunnelImageSchema,
  title: validator.string(),
  text: validator.string(),
  caption: validator.string().optional(),
  footerIcon: FunnelImageSchema.optional(),
  colorVariant: StoryColorVariantSchema.optional(),
  variant: validator.literal(StoryVariant.INTRO),
});

export type StoryIntroContent = validator.infer<typeof StoryIntroContentSchema>;

const StoryBasicContentSchema = validator.object({
  image: FunnelImageSchema,
  title: validator.string(),
  text: validator.string(),
  colorVariant: StoryColorVariantSchema.optional(),
  variant: validator.literal(StoryVariant.BASIC),
});

export type StoryBasicContent = validator.infer<typeof StoryBasicContentSchema>;

const StoryMediaContentSchema = validator.object({
  headerImage: FunnelImageSchema.optional(),
  image: FunnelImageSchema.optional(),
  title: validator.string(),
  text: validator.string(),
  colorVariant: StoryColorVariantSchema.optional(),
  variant: validator.literal(StoryVariant.MEDIA),
});

export type StoryMediaContent = validator.infer<typeof StoryMediaContentSchema>;

export const StorySchema = validator.union([
  StoryIntroContentSchema,
  StoryBasicContentSchema,
  StoryMediaContentSchema,
]);

export type Story = validator.infer<typeof StorySchema>;
