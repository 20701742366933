import { ToEntity } from 'core/common/mappers';
import { FunnelConfig } from '../entities';
import { FlowDto, FunnelDto } from '../services';

export class FunnelDtoMapper implements ToEntity<FunnelConfig, FunnelDto> {
  toEntity(dto: FunnelDto): FunnelConfig {
    return {
      link: dto.link,
      flows: dto.flows.map((flow: FlowDto) => ({
        id: flow.id,
        name: flow.name,
        initialScreen: flow.initial_screen,
        language: flow.language,
        offers: flow.offers,
        screens: flow.screens,
        translations: flow.translations,
        attributes: flow.attributes,
      })),
      name: dto.name,
      analytics: dto.analytics,
    };
  }
}
