import { FeatureFlagsConfig } from './entities';

export const antiFraudPurchaseConfirmationExperimentName = 'anti_fraud_purchase_confirmation';
export const antiFraudFirstRebillExperimentName = 'anti_fraud_first_rebill';

export enum AntiFraudPurchaseConfirmationExperimentNameGroup {
  CONTROL = 'control',
  TEST = 'test',
}

export enum AntiFraudFirstRebillExperimentNameGroup {
  CONTROL = 'control',
  TEST = 'test',
}

export const featureFlagsConfig: FeatureFlagsConfig = {
  features: {
    [antiFraudPurchaseConfirmationExperimentName]: {
      defaultValue: AntiFraudPurchaseConfirmationExperimentNameGroup.CONTROL,
    },
    [antiFraudFirstRebillExperimentName]: {
      defaultValue: AntiFraudFirstRebillExperimentNameGroup.CONTROL,
    },
  },
};
