import { ApiError } from 'core/common/errors';
import { BaseService } from 'core/common/services';
import { getLocalization } from 'core/localization/utils/getLocalization';
import { ResignError } from 'core/payments/errors';
import {
  CreateOrderParams,
  Order,
  OrderDataType,
  PaymentFormData,
  ResignFormData,
} from '../../entities';
import {
  OrderDtoMapper,
  OrderQuizDtoMapper,
  PaymentFormDataDtoMapper,
  ResignFormDataDtoMapper,
} from '../../mappers';
import { OrderDTO, PaymentFormDataDto, ProductDTO, ResignFormDataDTO } from '../dto';

export class PurchaseService extends BaseService {
  async createPayment(orderId: string): Promise<PaymentFormData> {
    const dtoMapper = new PaymentFormDataDtoMapper();

    const { data } = await this.httpClient.get<PaymentFormDataDto>(
      `/orders/${orderId}/payments-new`,
    );

    return dtoMapper.toEntity(data);
  }

  async createPaymentFromProductName(
    orderId: string,
    productName: string,
  ): Promise<PaymentFormData> {
    const { data } = await this.httpClient.post(`/orders/${orderId}`, {
      data: {
        offer_name: productName,
      },
    });

    return this.createPayment(data.order_id);
  }

  async createOrder(userData: CreateOrderParams): Promise<Order> {
    const localization = getLocalization();
    const dtoMapper = new OrderDtoMapper();

    const { data } = await this.httpClient.post('/orders', {
      data: userData,
      headers: {
        localization,
      },
    });

    return dtoMapper.toEntity(data);
  }

  async getOrder(order: string, params: string): Promise<OrderDTO> {
    const dtoMapper = new OrderQuizDtoMapper();

    const { data } = await this.httpClient.get<OrderDTO>(`/orders/${order}?${params}`);

    return dtoMapper.toEntity(data);
  }

  async createOrderFromEmail(orderId: string, params: string): Promise<PaymentFormData> {
    const dtoMapper = new PaymentFormDataDtoMapper();

    const { data } = await this.httpClient.get(`/orders/${orderId}/payments-new?${params}`);

    return dtoMapper.toEntity(data);
  }

  async getProduct(productName: string): Promise<ProductDTO> {
    const { data } = await this.httpClient.get<ProductDTO>(`/products/${productName}`);

    return data;
  }

  async purchaseReport(reportType: string, orderData?: OrderDataType) {
    try {
      const { data } = await this.httpClient.post('/orders/recurring', {
        data: {
          order_data: orderData || {},
          offer_name: reportType,
          project: 'Nebula',
        },
      });

      return data;
    } catch (err) {
      if (err instanceof ApiError && err.message === 'Resign required') {
        throw new ResignError(err);
      }
      throw err;
    }
  }

  async getResignForm(reportType: string, tokenId: string): Promise<ResignFormData> {
    const dtoMapper = new ResignFormDataDtoMapper();

    const { data } = await this.httpClient.post<ResignFormDataDTO>('/orders/resign-form', {
      data: {
        token_id: tokenId,
        offer_name: reportType,
        project: 'Nebula',
      },
    });

    return dtoMapper.toEntity(data);
  }

  async createOrderByOfferId(offerId: string): Promise<Order> {
    const dtoMapper = new OrderDtoMapper();

    const { data } = await this.httpClient.post<OrderDTO>(`/orders/upsell/${offerId}`);

    return dtoMapper.toEntity(data);
  }
}
