import { LoadingStatuses } from 'core/common/entities';
import { getRandomNumberWithinRange } from 'core/common/utils/getRandomNumberWithinRange';
import { Gender, RelationshipStatuses, RelationshipTypes } from 'core/funnel/entities';
import { PurchaseProductName } from 'core/offers/entities';
import { ZodiacTypes } from 'core/zodiac/entities';
import { UserProfile } from '../../entities';

export const userProfileInitialState: UserProfile = {
  gender: Gender.FEMALE,
  date: '',
  zodiac: ZodiacTypes.AQUARIUS,
  place: [],
  location: null,
  time: null,
  partnerGender: Gender.MALE,
  partnerDate: '',
  partnerZodiac: ZodiacTypes.AQUARIUS,
  totalUsersGenderZodiac: '',
  partnerPlace: [],
  partnerLocation: null,
  partnerTime: null,
  email: '',
  name: '',
  partnerName: '',
  initialStep: '',
  step: 0,
  // todo: unify the property of relationshipType
  relationshipStatus: RelationshipStatuses.SINGLE,
  relationshipType: RelationshipTypes.SINGLE,
  id: '',
  scan: {
    photoId: '',
    photo: '',
    coordinates: {
      lifeLinePts: [],
      heartLinePts: [],
      marriageLinePts: [],
      headLinePts: [],
      moneyLinePts: [],
      thumbFingerTip: { x: '', y: '' },
      indexFingerTip: { x: '', y: '' },
      middleFingerTip: { x: '', y: '' },
      ringFingerTip: { x: '', y: '' },
      pinkyFingerTip: { x: '', y: '' },
    },
  },
  plan: {
    displayPrice: '$13.67',
    productId: PurchaseProductName.APP_SUBSCRIPTION_THIRTEEN_DOLLAR_7D_TRIAL_PREMIUM_2W_19,
  },
  selectedPlanName: PurchaseProductName.APP_SUBSCRIPTION_THIRTEEN_DOLLAR_7D_TRIAL_PREMIUM_2W_19,
  selectedTimeUpPlanName:
    PurchaseProductName.APP_SUBSCRIPTION_THIRTEEN_DOLLAR_7D_TRIAL_PREMIUM_2W_19,
  currentlyAffecting: [],
  relationshipGoal: '',
  quizLength: '',
  astrologyKnowledge: '',
  relateToStatement: '',
  mostImportant: '',
  goodEnough: '',
  idealDate: '',
  futurePartner: '',
  idealPartner: '',
  decisions: '',
  birthTime: '',
  palmReadingGoal: '',
  wpGoal: '',
  challenges: '',
  relationship: '',
  mainPain: '',
  topics: '',
  orderData: {
    subjects: [
      {
        assigned_to: 'partner_1',
        birth_place: {
          place: '',
          longitude: 0,
          latitude: 0,
        },
        gender: '',
        birth_date: '',
        birth_time: '',
      },
      {
        assigned_to: 'partner_2',
        birth_place: {
          place: '',
          latitude: 0,
          longitude: 0,
        },
        gender: '',
        birth_date: '',
        birth_time: '',
      },
    ],
  },
  updateUserInformationStatus: LoadingStatuses.IDLE,
  downloadAppLink: '',
  downloadAppLinkLoadingStatus: LoadingStatuses.IDLE,
  // TODO fix on prod/stage builder
  ancestor_data: '',
  nature_data: '',
  tradition_data: '',
  crowdComfort_data: '',
  intuitiveHealing_data: '',
  moonPhase_data: '',
  worryPartnerLeaves: '',
  traumaScore: getRandomNumberWithinRange(7, 8),
  relationshipSuccessDay: getRandomNumberWithinRange(1, 28),
};
