import { UtmSource } from 'core/user/entities/UtmTags';
import { ChannelAttributionStrategy } from './ChannelAttributionStrategy';

export class DirectChannelAttributionStrategy implements ChannelAttributionStrategy {
  getUtmTags() {
    return {
      utm_source: UtmSource.DIRECT,
    };
  }
}
