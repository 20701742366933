import { useEffect } from 'react';
import { SECOND, TEN_MINUTES_IN_MS } from 'core/common/constants';
import { useLocalStorage } from './useLocalStorage';

type CountdownProps = {
  key: string;
  duration?: number;
  stop?: number;
};

export const useCountdown = ({
  key,
  duration = TEN_MINUTES_IN_MS,
  stop = 0,
}: CountdownProps): { specialPlanCountdown: number } => {
  const [specialPlanCountdown, setSpecialPlanCountdown] = useLocalStorage(key, duration);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (specialPlanCountdown > stop) {
      timer = setTimeout(() => {
        setSpecialPlanCountdown(specialPlanCountdown - SECOND);
      }, SECOND);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [specialPlanCountdown, setSpecialPlanCountdown, stop]);

  return { specialPlanCountdown };
};
