import { useContext } from 'react';
import { ThemeContext } from 'core/theme/contexts';
import { getTheme } from '../utils/getTheme';

export const useGetTheme = () => {
  const themeType = useContext(ThemeContext);
  const theme = getTheme(themeType);

  return theme;
};
