import { createContext, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { FeatureFlags } from '../services';

export const FeatureFlagsContext = createContext<{ featureFlags: FeatureFlags }>(null!);

export type FeatureFlagsProviderProps = {
  children?: ReactNode;
  featureFlags: FeatureFlags;
};
const maxTryCount = 50;

export const FeatureFlagsProvider = ({ children, featureFlags }: FeatureFlagsProviderProps) => {
  const [updateCount, setStateUpdateCount] = useState(0);
  const tryCount = useRef(0);

  useEffect(() => {
    const noop = () => {};

    if (!featureFlags) return noop();
    featureFlags.setStateUpdateHandler(() => {
      // in cause when user can't provide enough data for initialization GrowthBook prevent infinite loop
      // after moving to sdk form GrowthBook the problem must be gone
      if (tryCount.current >= maxTryCount) {
        return;
      }
      tryCount.current += 1;
      setStateUpdateCount((value) => value + 1);
    });

    return () => {
      featureFlags.setStateUpdateHandler(noop);
    };
  }, [featureFlags, setStateUpdateCount]);

  const providerValue = useMemo(() => {
    return { featureFlags };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags, updateCount]);

  return (
    <FeatureFlagsContext.Provider value={providerValue}>{children}</FeatureFlagsContext.Provider>
  );
};
