import { UnionConditionsSchema } from 'core/common/entities';
import validator from 'core/common/utils/validator';
import { UpsellV2Schema } from 'core/offers/entities';
import { PalmLinesSchema } from 'core/palmistry/entities';
import { FunnelImageSchema, DeckSliderCardSchema } from './FunnelData';

export enum SectionType {
  FAQ = 'FAQ',
  INFO = 'INFO',
  OFFER = 'OFFER',
  TRIAL_OFFER = 'TRIAL_OFFER',
  GUIDE = 'GUIDE',
  BADGE = 'BADGE',
  QUOTE = 'QUOTE',
  FAQ_V2 = 'FAQ_V2',
  BANNER = 'BANNER',
  RATING = 'RATING',
  REVIEWS = 'REVIEWS',
  INSIDES = 'INSIDES',
  PAYMENT = 'PAYMENT',
  EXPERTS = 'EXPERTS',
  BENEFITS = 'BENEFITS',
  PARTNERS = 'PARTNERS',
  GUARANTEE = 'GUARANTEE',
  HIGHLIGHTS = 'HIGHLIGHTS',
  PAYMENT_V2 = 'PAYMENT_V2',
  ADVANTAGES = 'ADVANTAGES',
  COMPARISON = 'COMPARISON',
  INTRO_OFFER = 'INTRO_OFFER',
  DECK_SLIDER = 'DECK_SLIDER',
  FEATURED_IN = 'FEATURED_IN',
  GUARANTEE_V2 = 'GUARANTEE_V2',
  MENTIONED_IN = 'MENTIONED_IN',
  PAYWALL_HERO = 'PAYWALL_HERO',
  WHAT_YOU_GET = 'WHAT_YOU_GET',
  PALM_ANALYSIS = 'PALM_ANALYSIS',
  SUCCESS_ORDER = 'SUCCESS_ORDER',
  UPSELL_OFFERS = 'UPSELL_OFFERS',
  NEBULA_RATING = 'NEBULA_RATING',
  DISCOUNT_MODAL = 'DISCOUNT_MODAL',
  PAYWALL_HEADER = 'PAYWALL_HEADER',
  PAYWALL_FOOTER = 'PAYWALL_FOOTER',
  INTRO_OFFER_V2 = 'INTRO_OFFER_V2',
  PLANS_OVERVIEW = 'PLANS_OVERVIEW',
  DISCOUNT_TICKET = 'DISCOUNT_TICKET',
  DISCOUNT_BANNER = 'DISCOUNT_BANNER',
  UPSELL_GUIDANCE = 'UPSELL_GUIDANCE',
  BLURRED_CONTENT = 'BLURRED_CONTENT',
  BANNER_CLICKABLE = 'BANNER_CLICKABLE',
  REVIEWS_CAROUSEL = 'REVIEWS_CAROUSEL',
  DISCOUNT_MODAL_V2 = 'DISCOUNT_MODAL_V2',
  RELATIONSHIP_PLAN = 'RELATIONSHIP_PLAN',
  BANNER_WITH_TIMER = 'BANNER_WITH_TIMER',
  READINGS_PACK_SLIDER = 'READINGS_PACK_SLIDER',
  MONEY_BACK_GUARANTEE = 'MONEY_BACK_GUARANTEE',
  ADVANTAGES_WITH_EMOJI = 'ADVANTAGES_WITH_EMOJI',
  STICKY_PAYMENT_BUTTON = 'STICKY_PAYMENT_BUTTON',
  ATTACHMENT_STYLE_PROFILE = 'ATTACHMENT_STYLE_PROFILE',
  UPSELL_CONSULTATION_OFFER = 'UPSELL_CONSULTATION_OFFER',
  UPSELL_READINGS_PACK_OFFER = 'UPSELL_READINGS_PACK_OFFER',
  BANNER_WITH_TIMER_AND_OFFER = 'BANNER_WITH_TIMER_AND_OFFER',
  UPSELL_CONSULTATION_INSIGHTS = 'UPSELL_CONSULTATION_INSIGHTS',
  UPSELL_CONSULTATION_BENEFITS = 'UPSELL_CONSULTATION_BENEFITS',
  UPSELL_CONSULTATION_GUARANTEE = 'UPSELL_CONSULTATION_GUARANTEE',
  LEGACY_LIST = 'LEGACY_LIST',
  LEGACY_FOOTER = 'LEGACY_FOOTER',
  LEGACY_BANNER = 'LEGACY_BANNER',
  LEGACY_REVIEWS = 'LEGACY_REVIEWS',
  LEGACY_INFO_PANEL = 'LEGACY_INFO_PANEL',
  LEGACY_MEDIA_PANEL = 'LEGACY_MEDIA_PANEL',
  LEGACY_PRICE_PANEL = 'LEGACY_PRICE_PANEL',
  LEGACY_MONEY_BACK_GUARANTEE = 'LEGACY_MONEY_BACK_GUARANTEE',
  SUBSCRIPTION_DISCOUNT = 'SUBSCRIPTION_DISCOUNT',
}

const ConditionSchema = validator.record(validator.string(), UnionConditionsSchema);

const ImageSchema = validator.object({
  alt: validator.string(),
  src: validator.string().url(),
});

export const SectionAttributesSchema = validator.object({
  content: validator.record(validator.string(), validator.unknown()),
  condition: ConditionSchema.optional(),
  experiment: ConditionSchema.optional(),
  sectionType: validator.nativeEnum(SectionType),
});

export type SectionAttributes = validator.infer<typeof SectionAttributesSchema>;

export const PageSectionSchema = validator.object({
  attributes: validator.array(SectionAttributesSchema),
});

// BANNER
const SectionBannerAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    analyticsKey: validator.string().optional(),
    title: validator.string(),
    specialOffer: validator
      .object({
        title: validator.string(),
        subTitle: validator.string(),
        prevPrice: validator.string(),
      })
      .optional(),
  }),
  sectionType: validator.literal(SectionType.BANNER),
});
export type SectionBannerAttributes = validator.infer<typeof SectionBannerAttributesSchema>;

const SectionBannerSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionBannerAttributesSchema),
});

// BANNER_WITH_TIMER
const SectionBannerWithTimerAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    buttonText: validator.string(),
    title: validator.string().optional(),
    minLabel: validator.string().optional(),
    secLabel: validator.string().optional(),
  }),
  sectionType: validator.literal(SectionType.BANNER_WITH_TIMER),
});
export type SectionBannerWithTimerAttributes = validator.infer<
  typeof SectionBannerWithTimerAttributesSchema
>;

const SectionBannerWithTimerSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionBannerWithTimerAttributesSchema),
});

// BANNER_WITH_TIMER_AND_OFFER
const SectionBannerWithTimerAndOfferAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string().optional(),
    minLabel: validator.string().optional(),
    secLabel: validator.string().optional(),
    specialOffer: validator
      .object({
        title: validator.string(),
        subTitle: validator.string(),
        prevPrice: validator.string(),
      })
      .optional(),
  }),
  sectionType: validator.literal(SectionType.BANNER_WITH_TIMER_AND_OFFER),
});
export type SectionBannerWithTimerAndOfferAttributes = validator.infer<
  typeof SectionBannerWithTimerAndOfferAttributesSchema
>;

const SectionBannerWithTimerAndOfferSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionBannerWithTimerAndOfferAttributesSchema),
});

// STICKY_PAYMENT_BUTTON
const SectionStickyPaymentButtonAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    button: validator.string(),
  }),
  sectionType: validator.literal(SectionType.STICKY_PAYMENT_BUTTON),
});

// INFO
const SectionInfoAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    image: ImageSchema.optional(),
    title: validator.string().optional(),
    description: validator.string().optional(),
  }),
  sectionType: validator.literal(SectionType.INFO),
});
export type SectionInfoAttributes = validator.infer<typeof SectionInfoAttributesSchema>;

const SectionInfoSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionInfoAttributesSchema),
});

// ADVANTAGES
const SectionAdvantagesAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string().optional(),
    description: validator.string().optional(),
    advantages: validator.array(
      validator.object({
        text: validator.string(),
        title: validator.string(),
        image: ImageSchema,
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.ADVANTAGES),
});
export type SectionAdvantagesAttributes = validator.infer<typeof SectionAdvantagesAttributesSchema>;

const SectionAdvantagesSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionAdvantagesAttributesSchema),
});

// OFFER
const SectionOfferAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    analyticsKey: validator.string().optional(),
    goal: validator.object({
      image: ImageSchema,
      title: validator.string(),
    }),
    title: validator.string(),
    offerData: validator.object({
      title: validator.string(),
      button: validator.string(),
      fullPrice: validator.string(),
      disclaimer: validator.string(),
      priceTitle: validator.string(),
      totalToday: validator.object({
        price: validator.string(),
        title: validator.string(),
      }),
      description: validator.string(),
      guaranteedText: validator.string(),
      linkSubscriptionPolicy: validator.string(),
    }),
    titleFaq: validator.string(),
    paymentDialogDescription: validator.string(),
  }),
  sectionType: validator.literal(SectionType.OFFER),
});
export type SectionOfferAttributes = validator.infer<typeof SectionOfferAttributesSchema>;

const SectionOfferSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionOfferAttributesSchema),
});

// TRIAL OFFER
const SectionTrialOfferAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    analyticsKey: validator.string().optional(),
    goal: validator.object({
      image: ImageSchema,
      title: validator.string(),
    }),
    title: validator.string(),
    offerData: validator.object({
      title: validator.string(),
      button: validator.string(),
      fullPrice: validator.string(),
      disclaimer: validator.string(),
      priceTitle: validator.string(),
      totalToday: validator.object({
        price: validator.string(),
        title: validator.string(),
      }),
      description: validator.string(),
      guaranteedText: validator.string(),
      linkSubscriptionPolicy: validator.string(),
    }),
    titleFaq: validator.string(),
    paymentDialogDescription: validator.string(),
  }),
  sectionType: validator.literal(SectionType.TRIAL_OFFER),
});

export type SectionTrialOfferAttributes = validator.infer<typeof SectionTrialOfferAttributesSchema>;

const SectionTrialOfferSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionTrialOfferAttributesSchema),
});

// GUIDE
const SectionGuideAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    icon: validator.string(),
    list: validator.array(
      validator.object({
        text: validator.string(),
        title: validator.string(),
      }),
    ),
    image: ImageSchema,
    title: validator.string(),
    userDetails: validator.object({
      title: validator.string(),
      subTitle: validator.string(),
      dateOfBirthTitle: validator.string(),
    }),
  }),
  sectionType: validator.literal(SectionType.GUIDE),
});
export type SectionGuideAttributes = validator.infer<typeof SectionGuideAttributesSchema>;

const SectionGuideSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionGuideAttributesSchema),
});

// BLURRED CONTENT
const SectionBlurredContentAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    analyticsKey: validator.string().optional(),
    title: validator.string(),
    subTitle: validator.string(),
    image: ImageSchema,
    list: validator.array(validator.string()).optional(),
    body: validator.string().optional(),
    overlayText: validator.string().optional(),
    overlayButton: validator.string().optional(),
  }),
  sectionType: validator.literal(SectionType.BLURRED_CONTENT),
});
export type SectionBlurredContentAttributes = validator.infer<
  typeof SectionBlurredContentAttributesSchema
>;

const SectionBlurredContentSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionBlurredContentAttributesSchema),
});

// BENEFITS
const SectionBenefitsAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    list: validator.array(
      validator.object({
        title: validator.string(),
        image: ImageSchema,
      }),
    ),
    title: validator.string(),
  }),
  sectionType: validator.literal(SectionType.BENEFITS),
});
export type SectionBenefitsAttributes = validator.infer<typeof SectionBenefitsAttributesSchema>;

const SectionBenefitsSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionBenefitsAttributesSchema),
});

// REVIEWS
const SectionReviewsAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    list: validator.array(
      validator.object({
        id: validator.number(),
        date: validator.string(),
        text: validator.string(),
        initials: validator.string(),
        nickname: validator.string(),
        percent: validator.number().optional(),
      }),
    ),
    title: validator.string(),
  }),
  sectionType: validator.literal(SectionType.REVIEWS),
});
export type SectionReviewsAttributes = validator.infer<typeof SectionReviewsAttributesSchema>;

const SectionReviewsSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionReviewsAttributesSchema),
});

// QUOTE
const SectionQuoteAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    quotesImage: ImageSchema,
    testifierImage: ImageSchema,
  }),
  sectionType: validator.literal(SectionType.QUOTE),
});
export type SectionQuoteAttributes = validator.infer<typeof SectionQuoteAttributesSchema>;

const SectionQuoteSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionQuoteAttributesSchema),
});

// MENTIONED_IN
const SectionMentionedInAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    image: ImageSchema,
    title: validator.string(),
  }),
  sectionType: validator.literal(SectionType.MENTIONED_IN),
});
export type SectionMentionedInAttributes = validator.infer<
  typeof SectionMentionedInAttributesSchema
>;

const SectionMentionedInSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionMentionedInAttributesSchema),
});

// INSIDES
const SectionInsidesAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    image: ImageSchema,
    title: validator.string(),
    description: validator.string().optional(),
  }),
  sectionType: validator.literal(SectionType.INSIDES),
});
export type SectionInsidesAttributes = validator.infer<typeof SectionInsidesAttributesSchema>;

const SectionInsidesSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionInsidesAttributesSchema),
});

// FAQ
const SectionFaqAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    list: validator.array(
      validator.object({
        text: validator.string(),
        title: validator.string(),
      }),
    ),
    title: validator.string(),
  }),
  sectionType: validator.literal(SectionType.FAQ),
});
export type SectionFaqAttributes = validator.infer<typeof SectionFaqAttributesSchema>;

const SectionFaqSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionFaqAttributesSchema),
});

// PAYMENT
const SectionPaymentAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    text: validator.string(),
  }),
  sectionType: validator.literal(SectionType.PAYMENT),
});
export type SectionPaymentAttributes = validator.infer<typeof SectionPaymentAttributesSchema>;

const SectionPaymentSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionPaymentAttributesSchema),
});

// MONEY_BACK_GUARANTEE
const SectionMoneyBackGuaranteeAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    text: validator.string(),
    title: validator.string(),
    linkPolicy: validator.string(),
    imageAppRating: ImageSchema,
    imageGuarantee: ImageSchema,
  }),
  sectionType: validator.literal(SectionType.MONEY_BACK_GUARANTEE),
});
export type SectionMoneyBackGuaranteeAttributes = validator.infer<
  typeof SectionMoneyBackGuaranteeAttributesSchema
>;

const SectionMoneyBackGuaranteeSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionMoneyBackGuaranteeAttributesSchema),
});

// PAYWALL_HEADER
const SectionPaywallHeaderAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    discountText: validator.string().optional(),
    buttonText: validator.string(),
    minLabel: validator.string(),
    secLabel: validator.string(),
  }),
  sectionType: validator.literal(SectionType.PAYWALL_HEADER),
});
export type SectionPaywallHeaderAttributes = validator.infer<
  typeof SectionPaywallHeaderAttributesSchema
>;

const SectionPaywallHeaderSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionPaywallHeaderAttributesSchema),
});

// PAYWALL_HERO
const SectionPaywallHeroAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    backgroundImage: FunnelImageSchema,
    userGoal: validator.object({
      labelImage: FunnelImageSchema,
      goalLabel: validator.string(),
      goal: validator.string(),
      analysisText: validator.string(),
      progress: validator.number(),
    }),
  }),
  sectionType: validator.literal(SectionType.PAYWALL_HERO),
});
export type SectionPaywallHeroAttributes = validator.infer<
  typeof SectionPaywallHeroAttributesSchema
>;

const SectionPaywallHeroSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionPaywallHeroAttributesSchema),
});

// DECK_SLIDER
const SectionDeckSliderAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    swipeImage: FunnelImageSchema,
    disclaimer: validator.object({
      text: validator.string(),
      image: FunnelImageSchema,
    }),
    cards: validator.array(DeckSliderCardSchema),
    nextCardButtonText: validator.string(),
  }),
  sectionType: validator.literal(SectionType.DECK_SLIDER),
});
export type SectionDeckSliderAttributes = validator.infer<typeof SectionDeckSliderAttributesSchema>;

const SectionDeckSliderSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionDeckSliderAttributesSchema),
});

// COMPARISON
const SectionComparisonAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    image: FunnelImageSchema,
    before: validator.object({
      title: validator.string(),
      list: validator.array(
        validator.object({
          icon: validator.string(),
          text: validator.string(),
        }),
      ),
    }),
    after: validator.object({
      title: validator.string(),
      list: validator.array(
        validator.object({
          icon: validator.string(),
          text: validator.string(),
        }),
      ),
    }),
  }),
  sectionType: validator.literal(SectionType.COMPARISON),
});
export type SectionComparisonAttributes = validator.infer<typeof SectionComparisonAttributesSchema>;

const SectionComparisonSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionComparisonAttributesSchema),
});

// PLANS_OVERVIEW
const SectionPlansOverviewAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    decorationImage: FunnelImageSchema,
    title: validator.string(),
    periods: validator.array(validator.string()),
    plans: validator.array(
      validator.object({
        title: validator.string(),
        activePeriods: validator.array(validator.string()),
      }),
    ),
    activeUsers: validator.object({
      image: FunnelImageSchema,
      text: validator.string(),
    }),
    button: validator.string(),
  }),
  sectionType: validator.literal(SectionType.PLANS_OVERVIEW),
});
export type SectionPlansOverviewAttributes = validator.infer<
  typeof SectionPlansOverviewAttributesSchema
>;

const SectionPlansOverviewSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionPlansOverviewAttributesSchema),
});

// INTRO_OFFER
const SectionIntroOfferAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    purchaseButtonText: validator.string(),
    guaranteeText: validator.string(),
    paymentMethods: validator.array(FunnelImageSchema).optional(),
  }),
  sectionType: validator.literal(SectionType.INTRO_OFFER),
});
export type SectionIntroOfferAttributes = validator.infer<typeof SectionIntroOfferAttributesSchema>;

const SectionIntroOfferSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionIntroOfferAttributesSchema),
});

// INTRO_OFFER_V2
const SectionIntroOfferV2AttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    purchaseButtonText: validator.string(),
    guaranteeText: validator.string(),
    paymentMethods: validator.array(FunnelImageSchema).optional(),
    caption: validator
      .object({
        icon: FunnelImageSchema,
        mainText: validator.string(),
        secondaryText: validator.string(),
      })
      .optional(),
  }),
  sectionType: validator.literal(SectionType.INTRO_OFFER_V2),
});
export type SectionIntroOfferV2Attributes = validator.infer<
  typeof SectionIntroOfferV2AttributesSchema
>;

const SectionIntroOfferV2Schema = PageSectionSchema.extend({
  attributes: validator.array(SectionIntroOfferV2AttributesSchema),
});

// WHAT_YOU_GET
const SectionWhatYouGetAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string().optional(),
    list: validator.array(
      validator.object({
        icon: validator.string(),
        text: validator.string(),
      }),
    ),
    listDecorationEmoji: validator.string().optional(),
  }),
  sectionType: validator.literal(SectionType.WHAT_YOU_GET),
});
export type SectionWhatYouGetAttributes = validator.infer<typeof SectionWhatYouGetAttributesSchema>;

const SectionWhatYouGetSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionWhatYouGetAttributesSchema),
});

// PALM_ANALYSIS
const SectionPalmAnalysisAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    expertMessage: validator
      .object({
        image: FunnelImageSchema,
        title: validator.string(),
        subtitle: validator.string(),
        rating: validator.number(),
        message: validator.string(),
      })
      .optional(),
    palmAnalysis: validator.array(
      validator.object({
        line: PalmLinesSchema,
        image: FunnelImageSchema,
        points: validator.array(validator.string()),
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.PALM_ANALYSIS),
});
export type SectionPalmAnalysisAttributes = validator.infer<
  typeof SectionPalmAnalysisAttributesSchema
>;

const SectionPalmAnalysisSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionPalmAnalysisAttributesSchema),
});

// FEATURED_IN
const SectionFeaturedInAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    images: validator.array(FunnelImageSchema),
  }),
  sectionType: validator.literal(SectionType.FEATURED_IN),
});
export type SectionFeaturedInAttributes = validator.infer<typeof SectionFeaturedInAttributesSchema>;

const SectionFeaturedInSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionFeaturedInAttributesSchema),
});

// REVIEWS_CAROUSEL
const SectionReviewsCarouselAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string().optional(),
    list: validator.array(
      validator.object({
        date: validator.string(),
        review: validator.string(),
        userName: validator.string(),
        userAvatar: FunnelImageSchema.optional(),
        verified: validator
          .object({
            icon: FunnelImageSchema,
            text: validator.string(),
          })
          .optional(),
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.REVIEWS_CAROUSEL),
});
export type SectionReviewsCarouselAttributes = validator.infer<
  typeof SectionReviewsCarouselAttributesSchema
>;

const SectionReviewsCarouselSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionReviewsCarouselAttributesSchema),
});

// RATING
const SectionRatingAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    rating: validator.number(),
    list: validator.array(
      validator.object({
        image: FunnelImageSchema.optional(),
        supText: validator.string().optional(),
        subText: validator.string(),
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.RATING),
});
export type SectionRatingAttributes = validator.infer<typeof SectionRatingAttributesSchema>;

const SectionRatingSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionRatingAttributesSchema),
});

// FAQ
const SectionFaqV2AttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    questions: validator.array(
      validator.object({
        title: validator.string(),
        answer: validator.string(),
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.FAQ_V2),
});
export type SectionFaqV2Attributes = validator.infer<typeof SectionFaqV2AttributesSchema>;

const SectionFaqV2Schema = PageSectionSchema.extend({
  attributes: validator.array(SectionFaqV2AttributesSchema),
});

// GUARANTEE
const SectionGuaranteeAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    description: validator.string(),
  }),
  sectionType: validator.literal(SectionType.GUARANTEE),
});
export type SectionGuaranteeAttributes = validator.infer<typeof SectionGuaranteeAttributesSchema>;

const SectionGuaranteeSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionGuaranteeAttributesSchema),
});

// GUARANTEE_V2
const SectionGuaranteeV2AttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    description: validator.string(),
    image: FunnelImageSchema,
  }),
  sectionType: validator.literal(SectionType.GUARANTEE_V2),
});

export type SectionGuaranteeV2Attributes = validator.infer<
  typeof SectionGuaranteeV2AttributesSchema
>;

const SectionGuaranteeV2Schema = PageSectionSchema.extend({
  attributes: validator.array(SectionGuaranteeV2AttributesSchema),
});

// PAYMENT_V2
const SectionPaymentV2AttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({}),
  sectionType: validator.literal(SectionType.PAYMENT_V2),
});
export type SectionPaymentV2Attributes = validator.infer<typeof SectionPaymentV2AttributesSchema>;

const SectionPaymentV2Schema = PageSectionSchema.extend({
  attributes: validator.array(SectionPaymentV2AttributesSchema),
});

// DISCOUNT_MODAL
const SectionDiscountModalAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    benefitList: validator.array(validator.string()),
    withNebulaTitle: validator.string(),
    withNebulaIcons: validator.array(validator.string()),
    withoutNebulaTitle: validator.string(),
    withoutNebulaIcons: validator.array(validator.string()),
    footer: validator.string(),
    ctaButtonText: validator.string(),
  }),
  sectionType: validator.literal(SectionType.DISCOUNT_MODAL),
});
export type SectionDiscountModalAttributes = validator.infer<
  typeof SectionDiscountModalAttributesSchema
>;

const SectionDiscountModalSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionDiscountModalAttributesSchema),
});

// DISCOUNT_MODAL_V2
const SectionDiscountModalV2AttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    subtitle: validator.string(),
    footer: validator.object({
      title: validator.string(),
      subtitle: validator.string(),
      image: FunnelImageSchema,
    }),
    image: FunnelImageSchema,
    buttonText: validator.string(),
  }),
  sectionType: validator.literal(SectionType.DISCOUNT_MODAL_V2),
});

export type SectionDiscountModalV2Attributes = validator.infer<
  typeof SectionDiscountModalV2AttributesSchema
>;

const SectionDiscountModalV2Schema = PageSectionSchema.extend({
  attributes: validator.array(SectionDiscountModalV2AttributesSchema),
});

// BADGE
const SectionBadgeAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
  }),
  sectionType: validator.literal(SectionType.BADGE),
});
export type SectionBadgeAttributes = validator.infer<typeof SectionBadgeAttributesSchema>;

const SectionBadgeSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionBadgeAttributesSchema),
});

// SUBSCRIPTION_DISCOUNT
const SectionSubscriptionDiscountAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    discountValue: validator.string(),
    discountText: validator.string(),
  }),
  sectionType: validator.literal(SectionType.SUBSCRIPTION_DISCOUNT),
});
export type SectionSubscriptionDiscountAttributes = validator.infer<
  typeof SectionSubscriptionDiscountAttributesSchema
>;

const SectionSubscriptionDiscountSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionSubscriptionDiscountAttributesSchema),
});

// PAYWALL_FOOTER
const SectionPaywallFooterAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    termsTitle: validator.string(),
    privacyTitle: validator.string(),
  }),
  sectionType: validator.literal(SectionType.PAYWALL_FOOTER),
});
export type SectionPaywallFooterAttributes = validator.infer<
  typeof SectionPaywallFooterAttributesSchema
>;

const SectionPaywallFooterSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionPaywallFooterAttributesSchema),
});

// SUCCESS_ORDER
const SectionSuccessOrderAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    text: validator.string(),
    icon: FunnelImageSchema,
  }),
  sectionType: validator.literal(SectionType.SUCCESS_ORDER),
});

export type SectionSuccessOrderAttributes = validator.infer<
  typeof SectionSuccessOrderAttributesSchema
>;

const SectionSuccessOrderSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionSuccessOrderAttributesSchema),
});

// UPSELL_GUIDANCE
const SectionUpsellGuidanceAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    list: validator.array(
      validator.object({
        icon: FunnelImageSchema,
        text: validator.string(),
      }),
    ),
    image: FunnelImageSchema,
  }),
  sectionType: validator.literal(SectionType.UPSELL_GUIDANCE),
});

export type SectionUpsellGuidanceAttributes = validator.infer<
  typeof SectionUpsellGuidanceAttributesSchema
>;

const SectionUpsellGuidanceSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionUpsellGuidanceAttributesSchema),
});

// UPSELL_CONSULTATION_BENEFITS
const SectionUpsellConsultationBenefitsAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    image: FunnelImageSchema,
    benefits: validator.array(
      validator.object({
        icon: FunnelImageSchema,
        title: validator.string(),
        text: validator.string(),
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.UPSELL_CONSULTATION_BENEFITS),
});

export type SectionUpsellConsultationBenefitsAttributes = validator.infer<
  typeof SectionUpsellConsultationBenefitsAttributesSchema
>;

const SectionUpsellConsultationBenefitsSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionUpsellConsultationBenefitsAttributesSchema),
});

// UPSELL_CONSULTATION_INSIGHTS
const SectionUpsellConsultationInsightsAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    cards: validator.array(
      validator.object({
        image: FunnelImageSchema,
        title: validator.string(),
        text: validator.string(),
      }),
    ),
    benefits: validator.array(
      validator.object({
        icon: FunnelImageSchema,
        title: validator.string(),
        description: validator.string(),
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.UPSELL_CONSULTATION_INSIGHTS),
});

export type SectionUpsellConsultationInsightsAttributes = validator.infer<
  typeof SectionUpsellConsultationInsightsAttributesSchema
>;

const SectionUpsellConsultationInsightsSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionUpsellConsultationInsightsAttributesSchema),
});

// UPSELL_CONSULTATION_OFFER
const SectionUpsellConsultationOfferAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    image: FunnelImageSchema,
    rating: validator.object({
      icon: FunnelImageSchema,
      text: validator.string(),
    }),
    title: validator.string(),
    offer: validator.object({
      list: validator.array(
        validator.object({
          icon: FunnelImageSchema,
          text: validator.string(),
        }),
      ),
      disclaimer: validator.string(),
      buttonText: validator.string(),
      skipButtonText: validator.string(),
      discount: validator.string(),
    }),
  }),
  sectionType: validator.literal(SectionType.UPSELL_CONSULTATION_OFFER),
});

export type SectionUpsellConsultationOfferAttributes = validator.infer<
  typeof SectionUpsellConsultationOfferAttributesSchema
>;

const SectionUpsellConsultationOfferSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionUpsellConsultationOfferAttributesSchema),
});

// EXPERTS
const SectionExpertsAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    experts: validator.array(
      validator.object({
        avatar: FunnelImageSchema,
        name: validator.string(),
        description: validator.string(),
        details: validator.array(
          validator.object({
            title: validator.string(),
            subtitle: validator.string(),
            titleIcon: FunnelImageSchema.optional(),
          }),
        ),
        expertFields: validator.object({
          title: validator.string(),
          items: validator.array(validator.string()),
        }),
        readMoreButtonText: validator.string(),
        readLessButtonText: validator.string(),
      }),
    ),
    image: FunnelImageSchema,
  }),
  sectionType: validator.literal(SectionType.EXPERTS),
});

export type SectionExpertsAttributes = validator.infer<typeof SectionExpertsAttributesSchema>;

const SectionExpertsSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionExpertsAttributesSchema),
});

// UPSELL_CONSULTATION_GUARANTEE
const SectionUpsellConsultationGuaranteeAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    icon: FunnelImageSchema,
    title: validator.string(),
    topListText: validator.string(),
    list: validator.array(validator.string()),
    footerText: validator.string(),
  }),
  sectionType: validator.literal(SectionType.UPSELL_CONSULTATION_GUARANTEE),
});

export type SectionUpsellConsultationGuaranteeAttributes = validator.infer<
  typeof SectionUpsellConsultationGuaranteeAttributesSchema
>;

const SectionUpsellConsultationGuaranteeSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionExpertsAttributesSchema),
});

// DISCOUNT_BANNER
const SectionDiscountBannerAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    discount: validator.string(),
    oldDiscount: validator.string().optional(),
    caption: validator.string(),
    image: FunnelImageSchema.optional(),
  }),
  sectionType: validator.literal(SectionType.DISCOUNT_BANNER),
});

export type SectionDiscountBannerAttributes = validator.infer<
  typeof SectionDiscountBannerAttributesSchema
>;

const SectionDiscountBannerSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionDiscountBannerAttributesSchema),
});

// READINGS_PACK_SLIDER
const SectionReadingsPackSliderAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    readings: validator.array(
      validator.object({
        image: FunnelImageSchema,
        title: validator.string(),
        details: validator.array(
          validator.object({
            icon: FunnelImageSchema,
            text: validator.string(),
          }),
        ),
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.READINGS_PACK_SLIDER),
});

export type SectionReadingsPackSliderAttributes = validator.infer<
  typeof SectionReadingsPackSliderAttributesSchema
>;

const SectionReadingsPackSliderSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionReadingsPackSliderAttributesSchema),
});

// UPSELL_READINGS_PACK_OFFER
const SectionUpsellReadingsPackOfferAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    discount: validator.string(),
    disclaimer: validator.string(),
    buttonText: validator.string(),
    skipButtonText: validator.string(),
  }),
  sectionType: validator.literal(SectionType.UPSELL_READINGS_PACK_OFFER),
});

export type SectionUpsellReadingsPackOfferAttributes = validator.infer<
  typeof SectionUpsellReadingsPackOfferAttributesSchema
>;

const SectionUpsellReadingsPackOfferSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionUpsellReadingsPackOfferAttributesSchema),
});

// UPSELL_OFFERS
const SectionUpsellOffersAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    subtitle: validator.string(),
    offers: validator.object({
      numerologyAnalysis: UpsellV2Schema.omit({ offerId: true, price: true, oldPrice: true }),
      tarotReading: UpsellV2Schema.omit({ offerId: true, price: true, oldPrice: true }),
      palmistryGuide: UpsellV2Schema.omit({ offerId: true, price: true, oldPrice: true }),
      ultraPack: UpsellV2Schema.omit({ offerId: true, price: true, oldPrice: true }),
    }),
    buttonText: validator.string(),
    skipButtonText: validator.string(),
    disclaimer: validator.string().optional(),
  }),
  sectionType: validator.literal(SectionType.UPSELL_OFFERS),
});

export type SectionUpsellOffersAttributes = validator.infer<
  typeof SectionUpsellOffersAttributesSchema
>;

const SectionUpsellOffersSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionUpsellOffersAttributesSchema),
});

// RELATIONSHIP_PLAN
const SectionRelationshipPlanAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    rating: validator.object({
      rating: validator.number(),
      text: validator.string(),
    }),
    title: validator.string(),
    subtitle: validator.string(),
    withoutPlan: validator.object({
      label: validator.string(),
      title: validator.string(),
      icon: FunnelImageSchema,
      image: FunnelImageSchema,
      keyPoints: validator.array(
        validator.object({
          text: validator.string(),
          icon: validator.string(),
        }),
      ),
    }),
    withPlan: validator.object({
      label: validator.string(),
      title: validator.string(),
      icon: FunnelImageSchema,
      image: FunnelImageSchema,
      keyPoints: validator.array(
        validator.object({
          text: validator.string(),
          icon: validator.string(),
        }),
      ),
    }),
  }),
  sectionType: validator.literal(SectionType.RELATIONSHIP_PLAN),
});

export type SectionRelationshipPlanAttributes = validator.infer<
  typeof SectionRelationshipPlanAttributesSchema
>;

const SectionRelationshipPlanSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionRelationshipPlanAttributesSchema),
});

// ATTACHMENT_STYLE_PROFILE
const SectionAttachmentStyleProfileAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    cardTitle: validator.string(),
    gender: validator.string(),
    zodiac: validator.string().optional(),
    birthDate: validator
      .object({
        icon: validator.string(),
        title: validator.string(),
        value: validator.string(),
      })
      .optional(),
    birthTime: validator.object({
      icon: validator.string(),
      title: validator.string(),
      value: validator.string(),
    }),
    birthPlace: validator.object({
      icon: validator.string(),
      title: validator.string(),
      value: validator.string(),
    }),
    trauma: validator.object({
      title: validator.string(),
      value: validator.string(),
      image: FunnelImageSchema,
      icon: validator.string(),
    }),
    genderContent: validator.object({
      title: validator.string(),
      value: validator.string(),
      icon: validator.string(),
    }),
  }),
  sectionType: validator.literal(SectionType.ATTACHMENT_STYLE_PROFILE),
});

export type SectionAttachmentStyleProfileAttributes = validator.infer<
  typeof SectionAttachmentStyleProfileAttributesSchema
>;

const SectionAttachmentStyleProfileSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionAttachmentStyleProfileAttributesSchema),
});

// ADVANTAGES_WITH_EMOJI
const SectionAdvantagesWithEmojiAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string().optional(),
    list: validator.array(
      validator.object({
        icon: FunnelImageSchema,
        text: validator.string(),
      }),
    ),
    listDecorationEmoji: validator.string().optional(),
  }),
  sectionType: validator.literal(SectionType.ADVANTAGES_WITH_EMOJI),
});

export type SectionAdvantagesWithEmojiAttributes = validator.infer<
  typeof SectionAdvantagesWithEmojiAttributesSchema
>;

const SectionAdvantagesWithEmojiSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionAdvantagesWithEmojiAttributesSchema),
});

// HIGHLIGHTS
const SectionHighlightsAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    list: validator.array(
      validator.object({
        backgroundImage: FunnelImageSchema,
        title: validator.string(),
        text: validator.string(),
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.HIGHLIGHTS),
});

export type SectionHighlightsAttributes = validator.infer<typeof SectionHighlightsAttributesSchema>;

const SectionHighlightsSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionHighlightsAttributesSchema),
});

// PARTNERS
const SectionPartnersAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
  }),
  sectionType: validator.literal(SectionType.PARTNERS),
});

export type SectionPartnersAttributes = validator.infer<typeof SectionPartnersAttributesSchema>;

const SectionPartnersSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionPartnersAttributesSchema),
});

// BANNER_CLICKABLE
const SectionBannerClickableAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    bannerTitle: validator.string(),
    bannerText: validator.string(),
    backgroundImage: FunnelImageSchema.optional(),
  }),
  sectionType: validator.literal(SectionType.BANNER_CLICKABLE),
});

export type SectionBannerClickableAttributes = validator.infer<
  typeof SectionBannerClickableAttributesSchema
>;

const SectionBannerClickableSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionBannerClickableAttributesSchema),
});

// DISCOUNT_TICKET
const SectionDiscountTicketAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    discountValue: validator.string(),
    discountText: validator.string(),
  }),
  sectionType: validator.literal(SectionType.DISCOUNT_TICKET),
});

export type SectionDiscountTicketAttributes = validator.infer<
  typeof SectionDiscountTicketAttributesSchema
>;

const SectionDiscountTicketSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionDiscountTicketAttributesSchema),
});

// NEBULA_RATING
const SectionNebulaRatingAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    rating: validator.number(),
    text: validator.string(),
  }),
  sectionType: validator.literal(SectionType.NEBULA_RATING),
});

export type SectionNebulaRatingAttributes = validator.infer<
  typeof SectionNebulaRatingAttributesSchema
>;

const SectionNebulaRatingSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionNebulaRatingAttributesSchema),
});

// LEGACY_BANNER
const SectionLegacyBannerAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    image: ImageSchema,
    title: validator.string().optional(),
    subTitle: validator.string().optional(),
  }),
  sectionType: validator.literal(SectionType.LEGACY_BANNER),
});
export type SectionLegacyBannerAttributes = validator.infer<
  typeof SectionLegacyBannerAttributesSchema
>;

const SectionLegacyBannerSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionLegacyBannerAttributesSchema),
});

// LEGACY_MEDIA_PANEL
const SectionLegacyMediaPanelAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    image: ImageSchema,
    title: validator.string().optional(),
    subTitle: validator.string().optional(),
  }),
  sectionType: validator.literal(SectionType.LEGACY_MEDIA_PANEL),
});
export type SectionLegacyMediaPanelAttributes = validator.infer<
  typeof SectionLegacyMediaPanelAttributesSchema
>;

const SectionLegacyMediaPanelSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionLegacyMediaPanelAttributesSchema),
});

// LEGACY_REVIEWS
const SectionLegacyReviewsAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    list: validator.array(
      validator.object({
        date: validator.string(),
        text: validator.string(),
        image: ImageSchema,
        nickname: validator.string(),
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.LEGACY_REVIEWS),
});

export type SectionLegacyReviewsAttributes = validator.infer<
  typeof SectionLegacyReviewsAttributesSchema
>;

const SectionLegacyReviewsSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionLegacyReviewsAttributesSchema),
});

// LEGACY_PRICE_PANEL
const SectionLegacyPricePanelAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    badgeNew: validator.string(),
    priceNew: validator.string(),
    pricePrev: validator.string(),
    titleNew: validator.string(),
    titlePrev: validator.string(),
  }),
  sectionType: validator.literal(SectionType.LEGACY_PRICE_PANEL),
});

export type SectionLegacyPricePanelAttributes = validator.infer<
  typeof SectionLegacyPricePanelAttributesSchema
>;

const SectionLegacyPricePanelSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionLegacyPricePanelAttributesSchema),
});

// LEGACY_LIST
const SectionLegacyListAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string(),
    list: validator.array(
      validator.object({
        text: validator.string(),
      }),
    ),
  }),
  sectionType: validator.literal(SectionType.LEGACY_LIST),
});

export type SectionLegacyListAttributes = validator.infer<typeof SectionLegacyListAttributesSchema>;

const SectionLegacyListSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionLegacyListAttributesSchema),
});

// LEGACY_INFO_PANEL
const SectionLegacyInfoAttributesPanelSchema = SectionAttributesSchema.extend({
  content: validator.object({
    title: validator.string().optional(),
    panel: validator.object({
      text: validator.string(),
    }),
  }),
  sectionType: validator.literal(SectionType.LEGACY_INFO_PANEL),
});

export type SectionLegacyInfoPanelAttributes = validator.infer<
  typeof SectionLegacyInfoAttributesPanelSchema
>;

const SectionLegacyInfoPanelSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionLegacyInfoAttributesPanelSchema),
});

// LEGACY_MONEY_BACK_GUARANTEE
const SectionLegacyMoneyBackGuaranteeAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    text: validator.string(),
    link: validator.object({
      text: validator.string(),
      href: validator.string().optional(),
    }),
    title: validator.string(),
    textInscription: validator.string().optional(),
  }),
  sectionType: validator.literal(SectionType.LEGACY_MONEY_BACK_GUARANTEE),
});

export type SectionLegacyMoneyBackGuaranteeAttributes = validator.infer<
  typeof SectionLegacyMoneyBackGuaranteeAttributesSchema
>;

const SectionLegacyMoneyBackGuaranteeSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionLegacyMoneyBackGuaranteeAttributesSchema),
});

// LEGACY_FOOTER
const SectionLegacyFooterAttributesSchema = SectionAttributesSchema.extend({
  content: validator.object({
    button: validator.string(),
  }),
  sectionType: validator.literal(SectionType.LEGACY_FOOTER),
});

export type SectionLegacyFooterAttributes = validator.infer<
  typeof SectionLegacyFooterAttributesSchema
>;

const SectionLegacyFooterSchema = PageSectionSchema.extend({
  attributes: validator.array(SectionLegacyFooterAttributesSchema),
});

export type SectionFaqType = validator.infer<typeof SectionFaqSchema>;
export type SectionInfoType = validator.infer<typeof SectionInfoSchema>;
export type SectionOfferType = validator.infer<typeof SectionOfferSchema>;
export type SectionTrialOfferType = validator.infer<typeof SectionTrialOfferSchema>;
export type SectionQuoteType = validator.infer<typeof SectionQuoteSchema>;
export type SectionGuideType = validator.infer<typeof SectionGuideSchema>;
export type SectionBadgeType = validator.infer<typeof SectionBadgeSchema>;
export type SectionFaqV2Type = validator.infer<typeof SectionFaqV2Schema>;
export type SectionBannerType = validator.infer<typeof SectionBannerSchema>;
export type SectionBannerWithTimerType = validator.infer<typeof SectionBannerWithTimerSchema>;
export type SectionBannerWithTimerAndOfferType = validator.infer<
  typeof SectionBannerWithTimerAndOfferSchema
>;
export type SectionRatingType = validator.infer<typeof SectionRatingSchema>;
export type SectionExpertsType = validator.infer<typeof SectionExpertsSchema>;
export type SectionPaymentType = validator.infer<typeof SectionPaymentSchema>;
export type SectionInsidesType = validator.infer<typeof SectionInsidesSchema>;
export type SectionReviewsType = validator.infer<typeof SectionReviewsSchema>;
export type SectionPartnersType = validator.infer<typeof SectionPartnersSchema>;
export type SectionBenefitsType = validator.infer<typeof SectionBenefitsSchema>;
export type SectionGuaranteeType = validator.infer<typeof SectionGuaranteeSchema>;
export type SectionPaymentV2Type = validator.infer<typeof SectionPaymentV2Schema>;
export type SectionHighlightsType = validator.infer<typeof SectionHighlightsSchema>;
export type SectionWhatYouGetType = validator.infer<typeof SectionWhatYouGetSchema>;
export type SectionAdvantagesType = validator.infer<typeof SectionAdvantagesSchema>;
export type SectionComparisonType = validator.infer<typeof SectionComparisonSchema>;
export type SectionDeckSliderType = validator.infer<typeof SectionDeckSliderSchema>;
export type SectionIntroOfferType = validator.infer<typeof SectionIntroOfferSchema>;
export type SectionFeaturedInType = validator.infer<typeof SectionFeaturedInSchema>;
export type SectionGuaranteeV2Type = validator.infer<typeof SectionGuaranteeV2Schema>;
export type SectionMentionedInType = validator.infer<typeof SectionMentionedInSchema>;
export type SectionPaywallHeroType = validator.infer<typeof SectionPaywallHeroSchema>;
export type SectionNebulaRatingType = validator.infer<typeof SectionNebulaRatingSchema>;
export type SectionIntroOfferV2Type = validator.infer<typeof SectionIntroOfferV2Schema>;
export type SectionUpsellOffersType = validator.infer<typeof SectionUpsellOffersSchema>;
export type SectionPalmAnalysisType = validator.infer<typeof SectionPalmAnalysisSchema>;
export type SectionSuccessOrderType = validator.infer<typeof SectionSuccessOrderSchema>;
export type SectionPaywallFooterType = validator.infer<typeof SectionPaywallFooterSchema>;
export type SectionDiscountModalType = validator.infer<typeof SectionDiscountModalSchema>;
export type SectionPaywallHeaderType = validator.infer<typeof SectionPaywallHeaderSchema>;
export type SectionPlansOverviewType = validator.infer<typeof SectionPlansOverviewSchema>;
export type SectionDiscountBannerType = validator.infer<typeof SectionDiscountBannerSchema>;
export type SectionDiscountTicketType = validator.infer<typeof SectionDiscountTicketSchema>;
export type SectionUpsellGuidanceType = validator.infer<typeof SectionUpsellGuidanceSchema>;
export type SectionBlurredContentType = validator.infer<typeof SectionBlurredContentSchema>;
export type SectionDiscountModalV2Type = validator.infer<typeof SectionDiscountModalV2Schema>;
export type SectionReviewsCarouselType = validator.infer<typeof SectionReviewsCarouselSchema>;
export type SectionBannerClickableType = validator.infer<typeof SectionBannerClickableSchema>;
export type SectionRelationshipPlanType = validator.infer<typeof SectionRelationshipPlanSchema>;
export type SectionMoneyBackGuaranteeType = validator.infer<typeof SectionMoneyBackGuaranteeSchema>;
export type SectionReadingsPackSliderType = validator.infer<typeof SectionReadingsPackSliderSchema>;
export type SectionAdvantagesWithEmojiType = validator.infer<
  typeof SectionAdvantagesWithEmojiSchema
>;
export type SectionAttachmentStyleProfileType = validator.infer<
  typeof SectionAttachmentStyleProfileSchema
>;
export type ectionUpsellReadingsPackOfferType = validator.infer<
  typeof SectionUpsellReadingsPackOfferSchema
>;
export type SectionSubscriptionDiscountType = validator.infer<
  typeof SectionSubscriptionDiscountSchema
>;
export type SectionUpsellConsultationOfferType = validator.infer<
  typeof SectionUpsellConsultationOfferSchema
>;
export type SectionUpsellConsultationBenefitsType = validator.infer<
  typeof SectionUpsellConsultationBenefitsSchema
>;
export type SectionUpsellConsultationInsightsType = validator.infer<
  typeof SectionUpsellConsultationInsightsSchema
>;
export type SectionUpsellConsultationGuaranteeType = validator.infer<
  typeof SectionUpsellConsultationGuaranteeSchema
>;
export type SectionLegacyListType = validator.infer<typeof SectionLegacyListSchema>;
export type SectionLegacyBannerType = validator.infer<typeof SectionLegacyBannerSchema>;
export type SectionLegacyFooterType = validator.infer<typeof SectionLegacyFooterSchema>;
export type SectionLegacyReviewsType = validator.infer<typeof SectionLegacyReviewsSchema>;
export type SectionLegacyInfoPanelType = validator.infer<typeof SectionLegacyInfoPanelSchema>;
export type SectionLegacyMediaPanelType = validator.infer<typeof SectionLegacyMediaPanelSchema>;
export type SectionLegacyPricePanelType = validator.infer<typeof SectionLegacyPricePanelSchema>;
export type SectionLegacyMoneyBackGuaranteeType = validator.infer<
  typeof SectionLegacyMoneyBackGuaranteeSchema
>;

export const SectionAttributesUnionSchema = validator.union([
  SectionFaqAttributesSchema,
  SectionInfoAttributesSchema,
  SectionOfferAttributesSchema,
  SectionTrialOfferAttributesSchema,
  SectionGuideAttributesSchema,
  SectionQuoteAttributesSchema,
  SectionFaqV2AttributesSchema,
  SectionBadgeAttributesSchema,
  SectionBannerAttributesSchema,
  SectionRatingAttributesSchema,
  SectionReviewsAttributesSchema,
  SectionInsidesAttributesSchema,
  SectionPaymentAttributesSchema,
  SectionExpertsAttributesSchema,
  SectionPartnersAttributesSchema,
  SectionBenefitsAttributesSchema,
  SectionGuaranteeAttributesSchema,
  SectionPaymentV2AttributesSchema,
  SectionHighlightsAttributesSchema,
  SectionAdvantagesAttributesSchema,
  SectionIntroOfferAttributesSchema,
  SectionDeckSliderAttributesSchema,
  SectionComparisonAttributesSchema,
  SectionWhatYouGetAttributesSchema,
  SectionFeaturedInAttributesSchema,
  SectionPaywallHeroAttributesSchema,
  SectionMentionedInAttributesSchema,
  SectionNebulaRatingAttributesSchema,
  SectionUpsellOffersAttributesSchema,
  SectionPalmAnalysisAttributesSchema,
  SectionSuccessOrderAttributesSchema,
  SectionIntroOfferV2AttributesSchema,
  SectionDiscountModalAttributesSchema,
  SectionPlansOverviewAttributesSchema,
  SectionPaywallHeaderAttributesSchema,
  SectionPaywallFooterAttributesSchema,
  SectionDiscountTicketAttributesSchema,
  SectionDiscountBannerAttributesSchema,
  SectionBlurredContentAttributesSchema,
  SectionUpsellGuidanceAttributesSchema,
  SectionBannerClickableAttributesSchema,
  SectionReviewsCarouselAttributesSchema,
  SectionDiscountModalV2AttributesSchema,
  SectionBannerWithTimerAttributesSchema,
  SectionRelationshipPlanAttributesSchema,
  SectionReadingsPackSliderAttributesSchema,
  SectionMoneyBackGuaranteeAttributesSchema,
  SectionAdvantagesWithEmojiAttributesSchema,
  SectionStickyPaymentButtonAttributesSchema,
  SectionSubscriptionDiscountAttributesSchema,
  SectionAttachmentStyleProfileAttributesSchema,
  SectionUpsellReadingsPackOfferAttributesSchema,
  SectionUpsellConsultationOfferAttributesSchema,
  SectionBannerWithTimerAndOfferAttributesSchema,
  SectionUpsellConsultationInsightsAttributesSchema,
  SectionUpsellConsultationBenefitsAttributesSchema,
  SectionUpsellConsultationGuaranteeAttributesSchema,
  SectionLegacyListAttributesSchema,
  SectionLegacyBannerAttributesSchema,
  SectionLegacyFooterAttributesSchema,
  SectionLegacyReviewsAttributesSchema,
  SectionLegacyInfoAttributesPanelSchema,
  SectionLegacyPricePanelAttributesSchema,
  SectionLegacyMediaPanelAttributesSchema,
  SectionLegacyMoneyBackGuaranteeAttributesSchema,
  SectionGuaranteeV2AttributesSchema,
]);

export type SectionAttributesUnionType = validator.infer<typeof SectionAttributesUnionSchema>;
