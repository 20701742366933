import { notAvailable } from 'core/common/constants';
import { normalizeError } from 'core/common/errors';
import { SpanStatusCode } from 'core/common/observability/entities';
import { AppThunk } from 'core/common/store';
import { updateOrderId } from './paymentForm';

export const fetchOrderByOfferIdRequest =
  (offerId: string): AppThunk =>
  async (dispatch, _, context) => {
    const {
      purchaseService,
      loggingService: logger,
      observabilitySystem,
      errorMonitoringService,
    } = context;
    // TODO check if we can remove it at all, after orderId saving has been moved to sessionStorage
    dispatch(updateOrderId(''));

    const span = observabilitySystem.startSpan('create_order_from_email_upsells');
    span.addEvent('Pending');

    try {
      const data = await purchaseService.createOrderByOfferId(offerId);

      dispatch(updateOrderId(data.orderId));

      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      const error = normalizeError(err);
      span.recordException(error);
      errorMonitoringService.captureError(error, {
        tags: {
          offer_id: offerId,
          get_params: location.search || notAvailable,
          error_message: error.message,
          error_reason: 'create_order_from_email_upsells',
        },
      });

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();

      logger.error(error);
    }
  };
