import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { FeatureFlags } from 'core/feature-flags/services';
import { featureFlagsAttrObserver } from 'core/feature-flags/store';
import { getCurrentLanguage } from 'core/localization/utils/getCurrentLanguage';
import { fetchUserLocationRequest, markUtmSourceForIncomingUser } from 'core/user/store/actions';
import { UserUtmTagsState } from 'core/user/store/state';
import { Services } from '../contexts';
import rootReducer, { RootInitialState } from './rootReducer';
import { AppDispatch } from './types';

export default function createStore(services: Services, featureFlags: FeatureFlags) {
  const { appEnvironment, utmTagsService, persistentStorageService } = services;

  const system = {
    isTestingEnv: appEnvironment.isTestingEnv(),
    language: getCurrentLanguage(),
    source: utmTagsService.getUtmSource(),
    isApplePaySupported: appEnvironment.isApplePaySupported(),
    isAppleMobile: appEnvironment.isAppleMobile(),
    isMobileDevice: appEnvironment.isMobileDevice(),
  };

  const userUtmTags: UserUtmTagsState = {
    tags: utmTagsService.getUtmTags(),
  };

  const preloadedState: Partial<RootInitialState> = {
    ...persistentStorageService.load(),
    system,
    userUtmTags,
  };

  const enhancers = [featureFlagsAttrObserver(featureFlags)];
  const thunkWithContext = thunk.withExtraArgument({ ...services, featureFlags });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: [thunkWithContext],
    enhancers,
  });
  const dispatch = store.dispatch as AppDispatch;

  dispatch(fetchUserLocationRequest());
  dispatch(markUtmSourceForIncomingUser());

  store.subscribe(() => {
    const { ...state } = store.getState();
    persistentStorageService.save(state);
  });

  return store;
}
