import { ToEntity } from 'core/common/mappers';
import { Order, OrderQuiz } from '../entities';
import { OrderDTO } from '../services/dto';

export class OrderDtoMapper implements ToEntity<Order, OrderDTO> {
  toEntity(dto: OrderDTO): Order {
    return {
      orderId: dto.order_id,
      userId: dto.user_id,
      offerName: dto.offer_name,
    };
  }
}

export class OrderQuizDtoMapper implements ToEntity<OrderQuiz, OrderDTO> {
  toEntity(dto: OrderDTO): OrderQuiz {
    return {
      // TODO: pick only used props, rename keys to camelCase
      ...dto,
      order_quiz_data: {
        ...dto.order_quiz_data,
        partnerDate: dto.order_quiz_data.partner_birth_date,
        partnerGender: dto.order_quiz_data.partner_gender,
        date: dto.order_quiz_data.birth_date,
        time: dto.order_quiz_data.birth_time,
        location: dto.order_quiz_data.birth_place,
        partnerLocation: dto.order_quiz_data.partner_birth_place,
      },
    };
  }
}
