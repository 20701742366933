import { normalizeApiError, normalizeError } from 'core/common/errors';
import { Logger } from 'core/common/services/logger/interfaces';
import { centsToDollars } from 'core/common/utils/money';
import { CardPaymentEntities, PaymentOrderDescription, PaymentType } from 'core/payments/entities';
import { Currency, UtmTags } from 'core/user/entities';
import { UtmTagsService } from 'core/user/services/utmTags';
import { ConversionsApiAttributionParameters } from '../../entities';
import {
  AmplitudeService,
  AnalyticsService,
  BackendAnalyticsService,
  BingAdsService,
  FacebookService,
  GTMService,
  LtvPredictService,
  PinterestService,
  TikTokService,
} from '../../interfaces';
import { ConversionsAPIService } from '../conversionsAPI';

export class AnalyticsClientService implements AnalyticsService {
  private amplitudeService: AmplitudeService;

  private conversionsAPIService: ConversionsAPIService;

  private facebookService: FacebookService;

  private tikTokService: TikTokService;

  private pinterestService: PinterestService;

  private gtmService: GTMService;

  private utmTagsService: UtmTagsService;

  private ltvPredictService: LtvPredictService;

  private logger: Logger;

  private userId: string;

  private backendAnalyticsService: BackendAnalyticsService;

  private bingAdsService: BingAdsService;

  constructor(
    amplitudeService: AmplitudeService,
    conversionsAPIService: ConversionsAPIService,
    tikTokService: TikTokService,
    facebookService: FacebookService,
    pinterestService: PinterestService,
    gtmService: GTMService,
    utmTagsService: UtmTagsService,
    ltvPredictService: LtvPredictService,
    backendAnalyticsService: BackendAnalyticsService,
    logger: Logger,
    bingAdsService: BingAdsService,
  ) {
    this.logger = logger;

    this.userId = this.loadUserIdFromStorage();

    this.amplitudeService = amplitudeService;
    this.conversionsAPIService = conversionsAPIService;
    this.facebookService = facebookService;
    this.tikTokService = tikTokService;
    this.pinterestService = pinterestService;
    this.gtmService = gtmService;
    this.utmTagsService = utmTagsService;
    this.ltvPredictService = ltvPredictService;
    this.backendAnalyticsService = backendAnalyticsService;
    this.bingAdsService = bingAdsService;
    this.setUtmTags(this.utmTagsService.getUtmTags());
  }

  getAttributionParameters(): ConversionsApiAttributionParameters {
    return {
      ...this.conversionsAPIService.getAttributionParameters(),
    };
  }

  getDeviceId() {
    return this.amplitudeService.getDeviceId();
  }

  getSessionId() {
    return this.amplitudeService.getSessionId();
  }

  trackEvent(quizEvent: string, eventProperties: Record<string, unknown> = {}) {
    this.amplitudeService.trackEvent(quizEvent, eventProperties);
  }

  funnelOpened(funnelName: string) {
    this.tikTokService.funnelOpened();
    this.amplitudeService.funnelOpened(funnelName);
    this.ltvPredictService.setCurrentQuizName(funnelName);
  }

  flowOpened(flowName: string) {
    this.amplitudeService.flowOpened(flowName);
  }

  trackUserLanguage(language: string) {
    this.amplitudeService.trackUserLanguage(language);
  }

  optOtSellUserData() {
    this.gtmService.optOtSellUserData();
  }

  updateUserProperties(properties: Record<string, unknown>) {
    this.amplitudeService.setUserProperties(properties);
  }

  setUserId(userId: string) {
    this.amplitudeService.setUserId(userId);
    this.userId = userId;
  }

  setIsUserTester(isTester: boolean) {
    this.amplitudeService.setUserProperties({
      tester: isTester,
    });
  }

  setEmailConsent(value: string) {
    this.amplitudeService.setEmailConsent(value);
  }

  paymentPlanChosen(orderId: string) {
    try {
      this.facebookService.addToCard(orderId);
    } catch (e) {
      const error = normalizeError(e);
      this.logger.error(error);
    }
  }

  flowProcessingPagePopupOpen(value: string) {
    this.amplitudeService.flowProcessingPagePopupOpen(value);
  }

  flowProcessingPagePopupClicked(value: string) {
    this.amplitudeService.flowProcessingPagePopupClicked(value);
  }

  paymentFormOpened(orderId: string) {
    try {
      this.facebookService.initiateCheckout(orderId);
    } catch (e) {
      const error = normalizeError(e);
      this.logger.error(error);
    }
  }

  policyPageOpened(pageName: string, contextName: string) {
    this.amplitudeService.policyPageOpened(pageName, contextName, this.userId);
  }

  faqPageOpened(pageName: string, contextName: string) {
    this.amplitudeService.faqPageOpened(pageName, contextName, this.userId);
  }

  contactUsPageOpened(pageName: string, contextName: string) {
    this.amplitudeService.contactUsPageOpened(pageName, contextName, this.userId);
  }

  setUserExperimentGroupName(experimentName: string, groupName: string) {
    try {
      this.amplitudeService.setUserExperimentGroupName(experimentName, groupName);
      this.backendAnalyticsService.setSplitExperimentProperties({ experimentName, groupName });
    } catch (e) {
      const error = normalizeError(e);

      this.logger.error(error);
    }
  }

  launch() {
    this.amplitudeService.launch();
  }

  landingPageOpened(pageName: string) {
    this.amplitudeService.landingPageOpened(this.userId, pageName);
  }

  landingPageClick(pageName: string, product: string) {
    this.amplitudeService.landingPageClick(this.userId, pageName, product);
  }

  discountLpOpened() {
    this.amplitudeService.discountLpOpened();
  }

  discountLpGetReport() {
    this.amplitudeService.discountLpGetReport();
  }

  paymentClick(paymentType: PaymentType) {
    switch (paymentType) {
      case PaymentType.APPLE_PAY:
        return this.amplitudeService.applePayClick();
      case PaymentType.PAYPAL:
        return this.amplitudeService.paypalPayClick();
      case PaymentType.GOOGLE_PAY:
        return this.amplitudeService.googlePayClick();
      default:
        return this.amplitudeService.paymentCardClick();
    }
  }

  paymentCardChosen(paymentType: CardPaymentEntities) {
    if (paymentType === CardPaymentEntities.APPLE_BTN) {
      this.amplitudeService.appleBtnChosen(this.userId);
      return;
    }

    if (paymentType === CardPaymentEntities.GOOGLE_BTN) {
      this.amplitudeService.googleBtnChosen(this.userId);
      return;
    }

    this.amplitudeService.paymentCardChosen(this.userId);
  }

  paymentPaypalChosen() {
    this.amplitudeService.paymentPaypalChosen(this.userId);
  }

  paymentRetried() {
    this.amplitudeService.paymentRetried(this.userId);
  }

  paymentError(reason: string) {
    this.amplitudeService.paymentError(this.userId, reason);
  }

  async paymentSuccess(paymentData: {
    productId: string;
    paymentProviderOrder: PaymentOrderDescription;
    orderId: string;
    paymentMethod: string;
    currency: Currency;
    email: string;
  }) {
    const { productId, paymentProviderOrder, orderId, currency, email } = paymentData;

    this.amplitudeService.paymentSuccess(paymentData);

    const ltvPredict = await this.ltvPredictService.getLTVPredict(productId);
    const price = centsToDollars(paymentProviderOrder.amount);

    try {
      this.gtmService.purchase({
        price,
        orderId: paymentProviderOrder.order_id,
        productId,
        ltvPredict,
        currency,
        email,
      });
      this.tikTokService.paymentSuccess({ price, orderId, productId, ltvPredict, currency, email });
      this.facebookService.purchase({ ltvPredict, orderId, currency });
      this.pinterestService.checkout({ price, orderId, currency });
      this.bingAdsService.purchase({
        price,
        orderId,
        productId,
        ltvPredict,
        currency,
        email,
      });
    } catch (e) {
      const error = normalizeError(e);
      this.logger.error(error);
    }
  }

  lead(orderId: string) {
    this.pinterestService.lead(orderId);
  }

  upsellLpOpened() {
    this.amplitudeService.upsellLpOpened(this.userId);
  }

  upsellLpGetReport(product: string) {
    this.amplitudeService.upsellLpGetReport(product);
  }

  upsellLpSkip() {
    this.amplitudeService.upsellLpSkip();
  }

  upsellCreditsLpOpened() {
    this.amplitudeService.upsellCreditsLpOpened();
  }

  upsellCreditsLpGetReport(product: string) {
    this.amplitudeService.upsellCreditsLpGetReport(product);
  }

  consultationUpsellGetOffer(product: string) {
    this.amplitudeService.consultationUpsellGetOffer(product);
  }

  consultationUpsellWithDiscountGetOffer(product: string) {
    this.amplitudeService.consultationUpsellWithDiscountGetOffer(product);
  }

  readingsPackUpsellGetOffer(product: string) {
    this.amplitudeService.readingsPackUpsellGetOffer(product);
  }

  readingsPackUpsellWithDiscountGetOffer(product: string) {
    this.amplitudeService.readingsPackUpsellWithDiscountGetOffer(product);
  }

  upsellCreditsLpSkip() {
    this.amplitudeService.upsellCreditsLpSkip();
  }

  emailUpsellScreenOpen(context: string) {
    this.amplitudeService.emailUpsellScreenOpen(context);
  }

  onIntroPageOpened() {
    this.amplitudeService.onIntroPageOpened();
  }

  onExpertPageOpened() {
    this.amplitudeService.onExpertPageOpened();
  }

  onQuestionsPageOpened() {
    this.amplitudeService.onQuestionsPageOpened();
  }

  onCreditsPageOpened() {
    this.amplitudeService.onCreditsPageOpened();
  }

  onMatchingPageOpened(expertId: string) {
    this.amplitudeService.onMatchingPageOpened(expertId);
  }

  onMatchingPageClicked() {
    this.amplitudeService.onMatchingPageClicked();
  }

  onLoadingPageOpened() {
    this.amplitudeService.onLoadingPageOpened();
  }

  emailMarketingLpOpened(data: { page: string; view: string; source: string }) {
    this.amplitudeService.emailMarketingLpOpened(this.userId, data);
  }

  emailMarketingLpScrolledToEnd(data: { page: string; view: string; source: string }) {
    this.amplitudeService.emailMarketingLpScrolledToEnd(this.userId, data);
  }

  emailMarketingLpGetProduct(data: {
    page: string;
    productId: string;
    button: string;
    source: string;
  }) {
    this.amplitudeService.emailMarketingLpGetProduct(this.userId, data);
  }

  addToCart({
    price,
    trialPrice,
    productId,
    email,
    orderId,
    currency,
  }: {
    price: number;
    trialPrice?: number;
    productId: string;
    email: string;
    orderId: string;
    currency: Currency;
  }) {
    this.gtmService.addToCart({ price, productId, email, orderId, currency });
    this.tikTokService.addToCard({ price, productId, email, orderId, currency });

    if (trialPrice) {
      this.bingAdsService.addToCart({ productId, email, orderId, currency, trialPrice });
    }
  }

  initiateCheckout({
    price,
    productId,
    email,
    orderId,
    currency,
    trialPrice,
  }: {
    price: number;
    productId: string;
    email: string;
    orderId: string;
    currency: Currency;
    trialPrice?: number;
  }) {
    this.gtmService.initiateCheckout({ price, productId, email, orderId, currency });
    this.tikTokService.initiateCheckout({ price, productId, email, orderId, currency });

    if (trialPrice) {
      this.bingAdsService.beginCheckout({ trialPrice, productId, email, orderId, currency });
    }
  }

  addEmail(email: string, userId: string) {
    this.gtmService.addEmail(email, userId);
    this.tikTokService.addEmail(email, userId);
    this.amplitudeService.setUserEmail(email);
  }

  addUserId(id: string) {
    this.amplitudeService.setUserIdProperty(id);
  }

  flowPersonalInfoPolicyPageOpened() {
    this.amplitudeService.flowPersonalInfoPolicyPageOpened();
  }

  flowPersonalInfoPolicyPageClicked(context: 'do_not_sell' | 'return') {
    this.amplitudeService.flowPersonalInfoPolicyPageClicked(context);
  }

  hiddenTrialOpen() {
    this.amplitudeService.hiddenTrialOpen();
  }

  hiddenTrialClick() {
    this.amplitudeService.hiddenTrialClick();
  }

  hiddenTrialSkip() {
    this.amplitudeService.hiddenTrialSkip();
  }

  hiddenTrialClose() {
    this.amplitudeService.hiddenTrialClose();
  }

  purchaseSucceed(productName: string) {
    this.amplitudeService.purchaseSucceed(productName);
  }

  purchaseError(productName: string) {
    this.amplitudeService.purchaseError(productName);
  }

  cameraLaunched() {
    this.amplitudeService.cameraLaunched();
  }

  cameraErrorPopUpOpened() {
    this.amplitudeService.cameraErrorPopUpOpened();
  }

  cameraErrorPopUpClicked() {
    this.amplitudeService.cameraErrorPopUpClicked();
  }

  setUserAge(age: number) {
    this.amplitudeService.setUserAge(age);
    this.tikTokService.addBirthdate();
  }

  upsellPremiumScreenOpened() {
    this.amplitudeService.upsellPremiumScreenOpened();
  }

  consultationUpsellScreenOpened() {
    this.amplitudeService.consultationUpsellScreenOpened();
  }

  readingsPackUpsellScreenOpened() {
    this.amplitudeService.readingsPackUpsellScreenOpened();
  }

  consultationUpsellWithDiscountScreenOpened() {
    this.amplitudeService.consultationUpsellWithDiscountScreenOpened();
  }

  readingsPackUpsellWithDiscountScreenOpened() {
    this.amplitudeService.readingsPackUpsellWithDiscountScreenOpened();
  }

  upsellPremiumScreenClicked() {
    this.amplitudeService.upsellPremiumScreenClicked();
  }

  upsellPremiumScreenGetReport(offerId: string) {
    this.amplitudeService.upsellPremiumScreenGetReport(offerId);
  }

  consultationUpsellScreenClicked() {
    this.amplitudeService.consultationUpsellScreenClicked();
  }

  consultationUpsellWithDiscountScreenClicked() {
    this.amplitudeService.consultationUpsellWithDiscountScreenClicked();
  }

  readingsPackUpsellScreenClicked() {
    this.amplitudeService.readingsPackUpsellScreenClicked();
  }

  readingsPackUpsellWithDiscountScreenClicked() {
    this.amplitudeService.readingsPackUpsellWithDiscountScreenClicked();
  }

  natalChartUpsellScreenClicked() {
    this.amplitudeService.natalChartUpsellScreenClicked();
  }

  phoneNumberScreenOpen() {
    this.amplitudeService.phoneNumberScreenOpen();
  }

  phoneNumberScreenAdd() {
    this.amplitudeService.phoneNumberScreenAdd();
  }

  phoneNumberScreenSkip() {
    this.amplitudeService.phoneNumberScreenSkip();
  }

  w2aPromoScreenClick(value: string) {
    this.amplitudeService.w2aPromoScreenClick(value);
  }

  w2aPromoScreenOpen() {
    this.amplitudeService.w2aPromoScreenOpen();
  }

  earlyW2APromoScreenOpen() {
    this.amplitudeService.earlyW2APromoScreenOpen();
  }

  earlyW2APromoScreenClick(value: string) {
    this.amplitudeService.earlyW2APromoScreenClick(value);
  }

  natalChartUpsellScreenOpened() {
    this.amplitudeService.natalChartUpsellScreenOpened();
  }

  natalChartUpsellGetReport(product: string) {
    this.amplitudeService.upsellLpGetReport(product);
  }

  humanDesignReportMissed() {
    this.amplitudeService.humanDesignReportMissed();
  }

  private loadUserIdFromStorage(): string {
    try {
      const userStorage = localStorage.getItem('nebula') || '';
      const userId = userStorage ? JSON.parse(userStorage).userProfile.id : '';
      return userId;
    } catch (err) {
      const error = normalizeApiError(err);
      this.logger.error(error);
      return '';
    }
  }

  private setUtmTags(tags: UtmTags) {
    this.amplitudeService.setUserProperties({
      media_source: tags.utm_source,
      ...tags,
    });
  }

  secretDiscountClose() {
    this.amplitudeService.secretDiscountClose();
  }

  secretDiscountOpen() {
    this.amplitudeService.secretDiscountOpen();
  }

  secretDiscountClick() {
    this.amplitudeService.secretDiscountClick();
  }
}
