import { createReducer } from '@reduxjs/toolkit';
import { PaymentProcessSteps } from 'core/payments/entities';
import {
  clearPaymentState,
  initializePaymentFormFailed,
  initializePaymentFormPending,
  updateOrderId,
  initializePaymentFormSucceed,
  initializeResignFormFailed,
  initializeResignFormPending,
  initializeResignFormSucceed,
  paymentFormFailed,
  paymentFormVerify,
  recurringPaymentFailed,
  recurringPaymentPending,
  recurringPaymentSucceed,
  resignFormFailed,
  resignFormSucceed,
} from './actions';
import { paymentsInitialState } from './state';

export const paymentsReducer = createReducer(paymentsInitialState, (builder) => {
  builder
    .addCase(updateOrderId, (state, { payload }) => {
      state.orderId = payload;
    })
    .addCase(initializePaymentFormPending, (state) => {
      state.paymentProcessStep = PaymentProcessSteps.IN_PROCESS;
      state.paymentError = null;
      state.formPaypalData = null;
      state.formMerchantData = null;
    })
    .addCase(initializePaymentFormSucceed, (state, { payload }) => {
      state.paymentProcessStep = PaymentProcessSteps.INITIALIZED;
      state.formMerchantData = payload.formMerchantData;
      state.formPaypalData = payload.paypalPaymentData;
    })
    .addCase(initializePaymentFormFailed, (state, { payload }) => {
      state.paymentProcessStep = PaymentProcessSteps.FAILED;
      state.paymentError = payload;
    })
    .addCase(paymentFormVerify, (state) => {
      state.paymentProcessStep = PaymentProcessSteps.VERIFY;
    })
    .addCase(recurringPaymentPending, (state) => {
      state.paymentProcessStep = PaymentProcessSteps.IN_PROCESS;
      state.paymentError = null;
    })
    .addCase(recurringPaymentSucceed, (state) => {
      state.paymentProcessStep = PaymentProcessSteps.SUCCESS;
    })
    .addCase(recurringPaymentFailed, (state, { payload }) => {
      state.paymentProcessStep = PaymentProcessSteps.FAILED;
      state.paymentError = payload;
    })
    .addCase(initializeResignFormPending, (state) => {
      state.paymentProcessStep = PaymentProcessSteps.IN_PROCESS;
      state.resignFormData = null;
      state.paymentError = null;
    })
    .addCase(initializeResignFormSucceed, (state, { payload }) => {
      state.paymentProcessStep = PaymentProcessSteps.RESIGN_INITIALIZED;
      state.resignFormData = payload;
    })
    .addCase(initializeResignFormFailed, (state, { payload }) => {
      state.paymentProcessStep = PaymentProcessSteps.FAILED;
      state.paymentError = payload;
    })
    .addCase(resignFormSucceed, (state) => {
      state.paymentProcessStep = PaymentProcessSteps.SUCCESS;
    })
    .addCase(resignFormFailed, (state) => {
      state.paymentProcessStep = PaymentProcessSteps.FAILED;
    })
    .addCase(paymentFormFailed, (state) => {
      state.paymentProcessStep = PaymentProcessSteps.FAILED;
    })
    .addCase(clearPaymentState, (state) => {
      return {
        ...state,
        paymentProcessStep: PaymentProcessSteps.IDLE,
        paymentError: null,
        formMerchantData: null,
        formPaypalData: null,
        resignFormData: null,
      };
    });
});
