export enum ThemeTypes {
  EMAIL = 'email',
  DEFAULT = 'default',
  PALMISTRY = 'palmistry',
  NATAL_CHART = 'natal-chart',
  WITCH_POWER = 'witch-power',
  PALMISTRY_QUIZ = 'palmistry-quiz',
  COMPATIBILITY_QUIZ = 'compatibility-quiz',
  MOON_COMPATIBILITY = 'moon-compatibility',
  APP_SUBSCRIPTION_INTRO = 'app-sub-intro',
}

export enum BreakpointValues {
  MAX_MOBILE_WIDTH = 1024,
  MIN_DESKTOP_WIDTH = 1025,
}

export type ColorVariants =
  | 'primary'
  | 'primaryLink'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'primary-outlined'
  | 'secondary-outlined';

export enum Breakpoints {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export type Theme = {
  name: ThemeTypes;
  colors: {
    black: string;
    white: string;

    primary100: string;
    primary200: string;
    primary300: string;
    primary400: string;
    primary500: string;
    primary600: string;
    primary700: string;
    primary800: string;
    primary900: string;

    typography100: string;
    typography200: string;
    typography300: string;
    typography400: string;
    typography500: string;
    typography600: string;
    typography700: string;
    typography800: string;
    typography900: string;

    secondary100: string;
    secondary200: string;
    secondary300: string;
    secondary400: string;
    secondary500: string;
    secondary600: string;
    secondary700: string;
    secondary800: string;
    secondary900: string;

    tertiary100: string;
    tertiary200: string;
    tertiary300: string;
    tertiary400: string;
    tertiary500: string;
    tertiary600: string;
    tertiary700: string;
    tertiary800: string;
    tertiary900: string;

    vibrant500: string;
    vibrant900: string;

    accent: string;

    success100: string;
    success200: string;
    success300: string;
    success400: string;
    success500: string;
    success600: string;
    success700: string;
    success800: string;
    success900: string;

    error100: string;
    error200: string;
    error300: string;
    error400: string;
    error500: string;
    error600: string;
    error700: string;
    error800: string;
    error900: string;

    warning100: string;
    warning200: string;
    warning300: string;
    warning400: string;
    warning500: string;
    warning600: string;
    warning700: string;
    warning800: string;
    warning900: string;

    buttonPrimary: string;
    buttonTertiary: string;
    buttonSecondary: string;
    buttonPrimaryOutlinedHover: string;
    buttonSecondaryOutlinedHover: string;

    textPrimary: string;
    themeBackground: string;
    themeBackgroundLight: string;
    themeBackgroundSemiLight: string;
    themeBackgroundLightSecondary: string;
    primaryLeo: string;
    primarySagitarius: string;
    primaryLight: string;
    secondaryDark: string;
    error: string;
    warning: string;
    warningLight: string;
    info: string;
    infoLight: string;
    primaryLink: string;
    mainLink: string;
    lunarViolet: string;
    chart500: string;
    dark: string;
  };
  gradients: {
    primary: string;
    primaryHover: string;
    secondary: string;
    secondaryLight: string;
    background: string;
    geminiGradient: string;
    gradientPinkDropDown: string;
    lightActive: string;
    aquarius: string;
  };
  breakpoints: {
    values: {
      mobile: BreakpointValues;
      desktop: BreakpointValues;
    };
    mobile: string;
    desktop: string;
  };
};
