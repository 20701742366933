import { Config } from 'core/common/contexts';
import { isValidUuid } from 'core/common/utils/string';
import { CookiesService } from '../cookie';
import { AppEnvironment } from './AppEnvironment';

export class AppClientEnvironment implements AppEnvironment {
  private cookiesService: CookiesService;

  private config: Config;

  constructor(cookiesService: CookiesService, config: Config) {
    this.cookiesService = cookiesService;
    this.config = config;
  }

  isTestingEnv() {
    return this.isTestingTokenPresent();
  }

  private isTestingTokenPresent() {
    const token = this.cookiesService.get(this.config.testingTokenCookieKey) || '';

    return isValidUuid(token);
  }

  getSplitId() {
    return this.cookiesService.get('split_id') || '';
  }

  isSafari() {
    return !!navigator.userAgent.match(/Version\/[\d\\.]+.*Safari/);
  }

  isAppleMobile() {
    return (
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !(window as any).MSStream
    );
  }

  isInAppBrowser() {
    const inAppBrowsers = [
      'instagram',
      'fban',
      'fbav',
      'twitter',
      'pinterest',
      'linkedin',
      'snapchat',
      'tiktok',
      'wechat',
      'whatsapp',
      'viber',
      'telegram',
      'reddit',
    ];

    return inAppBrowsers.some((app) => navigator.userAgent.toLowerCase().includes(app));
  }

  isApple() {
    return navigator.userAgent.search(/OS X|iPhone|iPad|iOS/i) !== -1;
  }

  isApplePaySupported() {
    return this.isSafari() || this.isAppleMobile();
  }

  isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor;

    const mobileKeywords = [
      'Android',
      'webOS',
      'iPhone',
      'iPad',
      'iPod',
      'BlackBerry',
      'Windows Phone',
      'IEMobile',
      'Opera Mini',
    ];

    return mobileKeywords.some((keyword) => userAgent.includes(keyword));
  }
}
