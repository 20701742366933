import { monthsListEn } from 'core/common/constants';
import { RootInitialState } from 'core/common/store/rootReducer';
import { getIsTestingEnv } from 'core/common/store/system';
import { calculateAges } from 'core/common/utils/calculateAges';
import { getRelationshipType } from 'core/funnel/entities';
import { ZodiacService } from 'core/zodiac/services';

export const getUserInformation = (state: RootInitialState) => {
  const zodiac = getZodiac(state);
  const partnerZodiac = getPartnerZodiac(state);
  const totalUsersGenderZodiac = getTotalUsersGenderZodiac(state);
  const age = getUserAge(state);
  const ageDecade = getAgeDecade(state);
  const relationshipType = getRelationshipType(state.userProfile.relationshipStatus);
  const userProfile = getUserProfile(state);
  const relationshipProgress = getRelationshipProgress(state);

  const partnerGender = getPartnerGender(state);
  const birthdate = getBirthdate(state);
  const partnerBirthdate = getPartnerBirthdate(state);
  const location = getBirthPlace(state);
  const partnerPlace = getPartnerBirthPlace(state);
  const partnerLocation = getPartnerLocation(state);

  return {
    ...userProfile,
    zodiac,
    partnerZodiac,
    totalUsersGenderZodiac,
    age,
    ageDecade,
    relationshipType,
    ...relationshipProgress,
    partnerGender,
    date: birthdate,
    partnerDate: partnerBirthdate,
    partnerLocation,
    partnerPlace,
    location,
  };
};

export const getUserProfile = (state: RootInitialState) => state.userProfile;

export const getOrderData = (state: RootInitialState) => state.userProfile.orderData;
export const getPlan = (state: RootInitialState) => state.userProfile.plan;

export const getGender = (state: RootInitialState) => state.userProfile.gender;

export const getZodiac = (state: RootInitialState) => {
  return ZodiacService.getZodiacSignByDate(state.userProfile.date);
};

export const getPartnerZodiac = (state: RootInitialState) => {
  return ZodiacService.getZodiacSignByDate(state.userProfile.partnerDate);
};

export const getPartnerGender = (state: RootInitialState) => state.userProfile.partnerGender;

export const getBirthdate = (state: RootInitialState) => state.userProfile.date;

export const getPartnerBirthdate = (state: RootInitialState) => state.userProfile.partnerDate;

export const getBirthPlace = (state: RootInitialState) => state.userProfile.location;

export const getPartnerBirthPlace = (state: RootInitialState) => state.userProfile.partnerPlace;

export const getPartnerLocation = (state: RootInitialState) => state.userProfile.partnerLocation;

export const getTotalUsersGenderZodiac = (state: RootInitialState) => {
  const gender = getGender(state);
  const zodiac = getZodiac(state);

  if (!zodiac || !gender) return '';

  return ZodiacService.getZodiacData(zodiac)[gender];
};

export const getUserAge = (state: RootInitialState) => {
  if (!state.userProfile.date) {
    return NaN;
  }

  return calculateAges(state.userProfile.date);
};

export const getAgeDecade = (state: RootInitialState) => {
  const userAge = getUserAge(state);
  const agesAmount = userAge.toString();
  const firstInteger = agesAmount[0];
  const flooredAges = Number(firstInteger.padEnd(agesAmount.length, '0'));
  return flooredAges;
};

export const getEmail = (state: RootInitialState) => state.userProfile.email;

export const getIsUserTester = (state: RootInitialState) => {
  const emailWithPlusRegex = /\+(.+?)@/;
  const isTestingEnv = getIsTestingEnv(state);
  const userEmail = getEmail(state);

  if (isTestingEnv) return true;

  if (!userEmail) return false;

  return userEmail.includes('@gen.tech') || emailWithPlusRegex.test(userEmail);
};

export const getRelationshipProgress = (state: RootInitialState) => {
  const currentMonthIdx = new Date().getMonth();
  const currentMonth = monthsListEn[currentMonthIdx];
  const futureMonth = monthsListEn[(currentMonthIdx + 2) % monthsListEn.length];
  const futureDate = `${futureMonth} ${state.userProfile.relationshipSuccessDay ?? 25}`;

  return { currentMonth, futureMonth, futureDate };
};
