/* eslint-disable max-len */

import { config } from 'core/config';

export const en = {
  translation: {
    common: {
      aquarius: 'aquarius',
      aries: 'aries',
      cancer: 'cancer',
      capricorn: 'capricorn',
      gemini: 'gemini',
      leo: 'leo',
      libra: 'libra',
      pisces: 'pisces',
      sagittarius: 'sagittarius',
      scorpio: 'scorpio',
      taurus: 'taurus',
      virgo: 'virgo',
      male: 'males',
      female: 'females',
      man: 'man',
      woman: 'woman',
      plural: {
        aquarius: 'aquarians',
        aries: 'aries',
        cancer: 'cancers',
        capricorn: 'capricorns',
        gemini: 'geminis',
        leo: 'leos',
        libra: 'libras',
        pisces: 'pisces',
        sagittarius: 'sagittarians',
        scorpio: 'scorpios',
        taurus: 'tauruses',
        virgo: 'virgos',
        man: 'men',
        woman: 'women',
      },
    },
    home: {
      header: {
        title: "Navigate life's challenges with the astrology as your guide",
        subtitle:
          'Start your self-discovery journey today by completing our quiz and receiving a tailored plan just for you',
        descriptionTitle: 'Step into the world of Hyper-personalized Astrology',
        descriptionSubtitle:
          'Nebula combines data from different sources to create a personalized astrological blueprint, ' +
          'while offering friendly and enjoyable guidance from professional astrologers. Its approach ensures ' +
          'personalized insights that make exploring astrology both fun and accessible.',
      },
      advantages: {
        advantage1: {
          title: 'One-on-One guidance',
          subtitle:
            'Get personalized advice from our expert advisors tailored to your astrological blueprint',
        },
        advantage2: {
          title: 'Personal astrology report',
          subtitle:
            'Receive an in-depth report highlighting your astrological profile and its influence on your ' +
            'life and relationships',
        },
        advantage3: {
          title: 'Deeper understanding of yourself',
          subtitle:
            'Explore the depths of your personality, strengths, weaknesses, and innate tendencies to foster ' +
            'self-growth and personal development',
        },
        advantage4: {
          title: 'Insightful relationship patterns',
          subtitle:
            'Illuminate the patterns governing your relationships and unravel the subtleties of your emotional and ' +
            'sexual needs.',
        },
      },
    },
    'app-subscription-4-7d-trial-per-day': {
      gender: {
        title: 'Understand Yourself and Improve Relationships Using Astrology',
        subtitle: '1-Minute Personal Assessment',
        signIn: 'Already have an account? Sign in',
        address: `${config.companyName}, ${config.address}`,
        actionTitle: 'Select your gender:',
        answers: {
          male: 'male',
          female: 'female',
        },
        tiktok: {
          title: 'Understand Yourself and Discover Compatibility Using Astrology',
          disclaimer: 'For entertainment purposes only',
        },
      },
      mentionedIn: {
        title: '42+ million people',
        subtitle: 'already use Nebula',
        testimonial: 'Nebula aims to illuminate interpersonal connections using astrological signs',
        mentionedInText: 'MENTIONED IN',
        next: 'Continue',
      },
      flowChoice: {
        title: 'So we can get to know you better, tell us about your relationship status.',
        single: 'Single',
        relationship: 'In a relationship',
        married: 'Married',
        complicated: 'Complicated',
        unsure: 'Unsure / Other',
      },
      relationshipGoalSingle: {
        title: 'What is your goal?',
        answers: {
          perfectPartner: 'Find my perfect partner',
          understandBetter: 'Understand myself better',
          getMarried: 'Get married',
          happiness: 'Achieve happiness',
          growth: 'Personal growth',
          compatibility: 'Check compatibility',
          all: 'All above',
          loadingBoth: 'Both',
        },
        store: {
          perfectPartner: 'find your perfect partner',
          understandBetter: 'understand yourself better',
          loadingBoth: 'find your perfect partner and understand yourself better',
          getMarried: 'find love for your marriage',
          happiness: 'achieve happy life',
          growth: 'grow personally',
          compatibility: 'check compatibility',
          findLoveAndUnderstandBetter: 'find love and understand yourself better',
        },
      },
      goalSetup: {
        title: 'Great! You just set your first goal!',
        text: "Let's keep going so we can get to know you better.",
        next: 'Next',
        back: 'Back',
      },
      parent: {
        title: 'Are you a parent?',
        answers: { yes: 'Yes', no: 'No' },
      },
      astrologyKnowledge: {
        title: 'How would you describe your level of knowledge about astrology?',
        subtitle: "We'd like to tailor the insights to suit your knowledge level",
        answers: { expert: 'An expert', curious: 'Curious', beginner: 'A beginner' },
      },
      whatIs: {
        title: '<span>What is <b>hyper-personalized</b><b> astrology,</b> anyway?</span>',
        text:
          '<span>Personalized astrology is based on the understanding that each person has a unique astrological ' +
          'blueprint. The position of the stars in the sky on your date, place and time ' +
          'of birth are exceptional and only happen in one lifetime. You are more than just your zodiac sign!</span>' +
          '</br>' +
          '</br>' +
          '<strong>So how does it work?</strong>' +
          '</br>' +
          '</br>' +
          '<span>We analyze hundreds of data points to generate your unique astrological blueprint. ' +
          'This is combined with AI to craft customized astrological insights, based on your specific responses. ' +
          'We’re going to transform your relationship using astrology.</span>',
        next: 'Next',
      },
      date: {
        title: "What's your date of birth?",
        next: 'Next',
      },
      singleZodiacInfo: {
        next: 'Next',
      },
      birthTime: {
        title: 'Do you know your time of birth?',
        answers: { yes: 'Yes', no: 'No' },
      },
      time: {
        title: 'What time were you born?',
        text: 'We use NASA data to identify the exact position of the planets in the sky at the time of your birth.',
        label: '',
        next: 'Next',
      },
      noTime: {
        title:
          '<strong>No problem! You can still find plenty of great insights without knowing your' +
          ' exact birth time.</strong>',
        text: 'Tip: If you find out later, you can adjust this in your profile settings.',
        next: 'Next',
        back: 'Back',
      },
      place: {
        title: 'Where were you born?',
        text: 'Knowing this helps us figure out the time zone you were born in.',
        next: 'Next',
        cancel: 'Cancel',
      },
      perfectPartner: {
        titleStart: "We've helped ",
        titleMiddle: ' other ',
        titleEnd: " to find their perfect partner and we can't wait to help you too!",
        subtitle: '*as of 24 February 2023',
        list: ['Connecting to database...'],
      },
      understandBetter: {
        titleStart: "We've helped ",
        titleMiddle: ' other ',
        titleEnd: " to understand themselves better and we can't wait to help you too!",
        subtitle: '*as of 24 February 2023',
        list: ['Connecting to database...'],
      },
      loadingBoth: {
        titleStart: "We've helped ",
        titleMiddle: ' other ',
        titleEnd:
          " to understand themselves better and find their perfect partner and we can't wait to help you too!",
        subtitle: '*as of 24 February 2023',
        list: ['Connecting to database...'],
      },
      singleProblem: {
        young: 'under their 20s',
        old: 'above their 60s',
        adult: 'in their',
        male: 'male',
        female: 'female',
        parent: 'who have children',
        title: {
          relationship:
            'need a slightly different approach ' +
            'to improve their relationship. Which statement best describes your current relationship satisfaction?',
          singleStart: '{{capitalize (translated zodiac)}}',
          singleEnd:
            ' need a slightly different approach ' +
            'to find their perfect partner. But first, how did you feel in your last relationship?',
        },
        answers: {
          things: 'I was unhappy with how things were going in my relationship',
          parts: 'I was unhappy with parts of my relationship, but some things were working',
          happy: 'I was generally happy with my relationship',
          never: 'I’ve never been in a relationship',
        },
      },
      traitsWorks: {
        title:
          "To find out what works for us, we often need to understand what doesn't. " +
          'We’ll give you insights on how to build a happy and lasting relationship with your new partner.',
        text: 'Now, we need some information about your <b>Personality Traits</b> to dig deeper!',
        next: 'Next',
        back: 'Back',
        tiktok: {
          title:
            "To find out what works for us, we often need to understand what doesn't." +
            ' Discover how astrology can guide you in building a happy' +
            ' and meaningful relationship with your new partner.',
        },
      },
      traitsMoveOn: {
        title:
          "Sometimes that happens and you need to move on! We'll give you insights on how to build a long" +
          ' lasting relationship with your next partner.',
        text: 'Now, we need some information about your <b>Personality Traits</b> to dig deeper!',
        next: 'Next',
        back: 'Back',
        tiktok: {
          title:
            'Sometimes that happens and you need to move on!' +
            ' Discover how astrology can guide you in building a happy' +
            ' and meaningful relationship with your next partner.',
        },
      },
      traitsCompatible: {
        title:
          "Well, exciting times ahead! We'll give you insights about your most compatible partner.",
        text: 'Now, we need some information about your <b>Personality Traits</b> to dig deeper!',
        next: 'Next',
        back: 'Back',
      },
      relateToStatement: {
        title: 'Do you relate to the statement below?',
        subtitle: '<strong>“I really dislike being alone as much as I hate to admit it.”</strong>',
        answers: { yes: 'Yes', no: 'No' },
      },
      notice: {
        title: 'Do you tend to focus more on the negative than the positive?',
        answers: {
          veryFrequently: 'Very frequently',
          frequently: 'Frequently',
          occasionally: 'Occasionally',
          rarely: 'Rarely',
          never: 'Never',
        },
      },
      sensitive: {
        title: 'Are you sensitive to criticism?',
        answers: { yes: 'Yes', sometimes: 'Sometimes', rarely: 'Rarely', notAtAll: 'Not at all' },
      },
      tendToOverthink: {
        title: 'Do you tend to overthink?',
        answers: { yes: 'Yes', no: 'No' },
      },
      mostImportant: {
        title: 'What is most important to you?',
        answers: {
          success: 'Success',
          romance: 'Romance',
          stability: 'Stability',
          freedom: 'Freedom',
          happiness: 'Happiness',
          health: 'Health',
          all: 'All above',
        },
      },
      emotionalControl: {
        title: 'Is emotional control tricky for you?',
        answers: { yes: 'Yes', sometimes: 'Sometimes', rarely: 'Rarely', notAtAll: 'Not at all' },
      },
      notAlone: {
        title:
          'You&apos;re not alone.<br/> A lot of <span>{{translated pluralZodiac}}</span> find it difficult to control emotions due to their zodiac sign traits. Don&apos;t worry, we know how to address that!',
        next: 'Next',
        back: 'Back',
      },
      allRight: {
        title:
          'All right!<br/> You keep your emotions in check <span>{{translated zodiac}}</span>, but sometimes people want you to show more passion. We can give you some insights about that!',
        next: 'Next',
        back: 'Back',
      },
      attitude: {
        title: 'Do you love your job?',
        answers: {
          doWhatLove: 'Yes! I do something I love',
          notWorking: 'I’m not working',
          hateJob: 'I hate my job',
          paysBills: 'It’s okay, it pays the bills',
          underPaid: 'I’m underpaid',
          growing: 'I have stopped growing professionally',
        },
      },
      want: {
        title: 'Do you always know exactly what you want?',
        answers: { yes: 'Yes', no: 'No', notSure: 'Not sure' },
      },
      relaxing: {
        title: 'Do you have trouble relaxing?',
        answers: { notAtAll: 'Not at all', yes: 'Yes', sometimes: 'Sometimes', rarely: 'Rarely' },
      },
      almostThere: {
        title:
          'Almost there!' +
          '<br/>' +
          "Now let's personalize your plan by" +
          '<br />' +
          ' revealing your <b>Relationship Pattern</b>.',
        text: 'Please take your time to answer thoughtfully. Each response helps us to craft a tailored guidance plan for you.',
        next: 'Next',
        back: 'Back',
      },
      priority: {
        title: 'How strongly do you agree with the following statement?',
        subtitle: '<strong>When I am in a relationship, sex is a priority.</strong>',
        answers: {
          stronglyAgree: 'Strongly agree',
          agree: 'Agree',
          neutral: 'Neither agree nor disagree',
          disagree: 'Disagree',
          stronglyDisagree: 'Strongly disagree',
        },
      },
      comfortable: {
        title: 'How strongly do you agree with the following statement?',
        subtitle: '<strong>I feel comfortable around people I barely know.</strong>',

        answers: {
          notAtAll: 'Not at all',
          yes: 'Yes',
          sometimes: 'Sometimes',
          rarely: 'Rarely',
          // Experiment quiz visual element
          // TODO: Remove unnecessary answers after end test
          stronglyAgree: 'Strongly agree',
          agree: 'Agree',
          neutral: 'Neither agree nor disagree',
          disagree: 'Disagree',
          stronglyDisagree: 'Strongly disagree',
        },
      },
      goodEnough: {
        title: 'Do you often worry that you’re not good enough?',
        answers: { notAtAll: 'Not at all', yes: 'Yes', sometimes: 'Sometimes', rarely: 'Rarely' },
      },
      angry: {
        title: 'Do you easily get angry or irritated?',
        answers: { notAtAll: 'Not at all', yes: 'Yes', sometimes: 'Sometimes', rarely: 'Rarely' },
      },
      innerSelf: {
        title: 'Do you share your inner self with people you like?',
        answers: { yes: 'Yes', no: 'No', notSure: 'Not sure' },
      },
      aboutPeople: {
        title: 'Do you remember small details about the people who are important to you?',
        answers: { notAtAll: 'Not at all', yes: 'Yes', sometimes: 'Sometimes', rarely: 'Rarely' },
      },
      idealDate: {
        title: 'What is your ideal date?',
        answers: {
          restaurant: 'Dinner at a fancy restaurant',
          nature: 'A hike in nature',
          talking: 'Talking under the stars',
          walk: 'A walk in the park',
          skydiving: 'Skydiving',
        },
      },
      futurePartner: {
        title:
          'Would you describe your future partner as a detail-oriented or a big-picture person?',
        answers: {
          detailOriented: 'Detail-oriented',
          bigPicture: 'Big-picture',
          both: 'A bit of both',
        },
      },
      idealPartner: {
        title: 'Is your ideal partner an introvert or an extrovert?',
        answers: { introvert: 'Introvert', extrovert: 'Extrovert', both: 'A bit of both' },
      },
      relationshipGoal: {
        title: 'When you think about your relationship goals, you feel...',
        answers: {
          rightPlace: 'Optimistic! They are totally doable — with some guidance.',
          thing: 'Cautious. I’ve struggled before, but I’m hopeful.',
          totallyNormal: 'A little anxious, honestly.',
        },
      },
      rightPlace: {
        title: `You’ve come to the right place,<br/><span>{{translated zodiac}}</span>!`,
        subtitle:
          'Knowing that your goal is already doable puts you ahead. ' +
          'Let’s keep going so we can build a guidance plan based on your astrological blueprint.',
        next: 'Next',
        back: 'Back',
      },
      thing: {
        title: `<span>{{translated zodiac}}</span>, here's the thing...<br/>You can absolutely do this!`,
        subtitle:
          "We’ll start with bite-sized insights so it doesn't feel too overwhelming. Let's " +
          'keep going and build a guidance plan tailored to your cosmic blueprint.',
        next: 'Next',
        back: 'Back',
      },
      totallyNormal: {
        title: `It’s totally normal to feel nervous,<br/><span>{{translated zodiac}}</span>.`,
        subtitle:
          'But we know you can do this. ' +
          'Let’s keep going and build a guidance plan based on your astrological blueprint.',
        next: 'Next',
        back: 'Back',
      },
      communicate: {
        title: 'Do you find it easy to communicate with other people?',
        answers: { yes: 'Yes', no: 'No' },
      },
      decisionsSingle: {
        title: 'Do you make decisions with your head or your heart?',
        answers: { heart: 'Heart', head: 'Head', both: 'Both' },
      },
      withHeartSingle: {
        title: `Based on our data, 49% of <span>{{translated pluralZodiac}}</span> also make decisions with their hearts. We'll consider that while creating your guidance plan.`,
        next: 'Next',
        back: 'Back',
      },
      withHeadSingle: {
        title: `Based on our data, 39% of <span>{{translated pluralZodiac}}</span> also make decisions with their heads. We'll consider that while creating your guidance plan.`,
        titleSecondLine: 'Based on our data, 39% of ',
        titleEnd:
          ' people also make decisions with their head. ' +
          "We'll consider that while creating your guidance plan.",
        next: 'Next',
        back: 'Back',
      },
      bothSingle: {
        title: `Wonderful!<br/>Based on our data, only the top 17% of <span>{{translated pluralZodiac}}</span> make decisions with their hearts and heads. Using both in equal measure is the key to feeling harmonious in your relationships.`,
        next: 'Next',
        back: 'Back',
      },
      aboutUsSingle: {
        title: 'Where did you hear about us?',
        answers: {
          poster: 'Poster or billboard',
          friend: 'Friend or family',
          instagram: 'Instagram',
          mail: 'Direct mail or package insert',
          streaming: 'Online or streaming TV',
          tv: 'TV',
          radio: 'Radio',
          search: 'Search engine (Google, Bing, etc.)',
          newspaper: 'Newspaper or magazine',
          facebook: 'Facebook',
          review: 'Blog post or website review',
          podcast: 'Podcast',
          influencer: 'Influencer',
          youtube: 'Youtube',
          pinterest: 'Pinterest',
          other: 'Other',
        },
      },
      relationshipGoalInARelationship: {
        title: 'What is your goal?',
        answers: {
          fixRelationship: 'Fix relationship problems',
          increaseSatisfaction: 'Increase relationship satisfaction',
          buildStrongMarriage: 'Build a strong marriage',
          checkCompatibility: 'Check compatibility',
          all: 'All above',
          both: 'Both',
        },
        store: {
          fixRelationship: 'fix relationship problems',
          improveRelationship: 'improve your relationship',
          buildStrongMarriage: 'build a strong marriage',
          increaseSatisfaction: 'increase relationship satisfaction',
          checkCompatibility: 'check compatibility',
          both: 'fix relationship problems and increase relationship satisfaction',
        },
      },
      loadingInRelationship: {
        titleStart: "We've helped ",
        titleMiddle: ' with a',
        titleAfterZodiac: 'Sun sign',
        titleEnd: " to improve their relationship and we can't wait to help you too!",
        titleEndShort: " and we can't wait to help you too!",
        subtitle: '*as of 24 February 2023',
        goal: {
          perfectPartner: 'to find their perfect partner',
          understandBetter: 'to understand themselves better',
          growth: 'to grow personally',
          getMarried: 'to get married',
          achieveHappiness: 'to achieve happiness',
          checkCompatibility: 'to check compatibility',
          increaseSatisfaction: 'to increase relationship satisfaction',
          fixRelationship: 'to fix relationship problems',
          buildStrongMarriage: 'to build a strong marriage',
          allAboveSingle: 'with personal guidance',
          allAboveRelationship: 'with relationship guidance',
        },
        list: ['Connecting to database...'],
        tiktok: {
          titleStart: "We've helped ",
          titleEnd:
            " to understand themselves better and discover compatibility and we can't wait to help you too",
        },
      },
      relationshipProblem: {
        answers: {
          absolutelyUnhappy: 'I’m very unhappy with how things are going in my relationship',
          particularyHappy: "Some aspects of my relationship are working, but others aren't",
          generallyHappy: 'I’m generally happy with my relationship',
        },
      },
      worksForUs: {
        title:
          'We’ve got you covered! We’ll start with small, personalized ' +
          'insights into you and your partner’s personality traits.',
        text:
          'Now, we need to gather some details about <br/><b>Your Partner’s Profile</b> so we can create ' +
          'the astrological synastry blueprint of your relationship.',
        next: 'Next',
        back: 'Back',
      },
      moveOn: {
        title:
          'You’re probably doing better than you think! We’ll help you pinpoint what to work on, and provide support to stick with it.',
        text:
          'Now, we need to gather some details about <br/><b>Your Partner’s Profile</b> so we can create ' +
          'the astrological synastry blueprint of your relationship.',
        next: 'Next',
        back: 'Back',
      },
      mostCompatible: {
        title:
          'Wonderful!' +
          '<br/>' +
          "Let's find out what's working (and what isn’t) and go from there.",
        text:
          'Now, we need to gather some details about <br/><b>Your Partner’s Profile</b> so we can create ' +
          'the astrological synastry blueprint of your relationship.',
        next: 'Next',
        back: 'Back',
      },
      partnerGender: {
        title: 'What is your partner’s gender?',
        answers: {
          male: 'Male',
          female: 'Female',
        },
      },
      partnerDate: {
        title: 'What is your partner’s date of birth?',
        text: 'Their date of birth is essential information to build your partner’s personal astrological blueprint.',
        // TODO: refactor after close test quiz with visual elements
        experimentText: 'This highlights the duality of your masculine and feminine energies.',
        next: 'Next',
      },
      relationshipZodiacInfo: {
        next: 'Next',
        captionTitle: 'So how compatible are you?',
        captionDescription: "Let's go further and find out",
      },
      partnerBirthTime: {
        title: 'Do you know the time your partner was born?',
        answers: { yes: 'Yes', no: 'No' },
      },
      partnerTime: {
        title: 'What time was your partner born?',
        text: 'Knowing the exact birth time allows us to use NASA data to identify the exact position of the planets in the sky at the moment.',
        label: '',
        next: 'Next',
      },
      partnerNoTime: {
        title:
          '<strong>No problem! You can still find plenty of great insights without ' +
          'knowing the exact birth time of your partner.</strong>',
        text: 'Tip: If you find out later, you can adjust this in your partner’s profile.',
        next: 'Next',
        back: 'Back',
      },
      partnerPlace: {
        title: 'Where was your partner born?',
        text: "If you don't know the specific location, simply enter the country of birth",
        next: 'Next',
        cancel: 'Cancel',
      },
      relationshipAlmostThere: {
        title:
          'Almost there!' +
          '<br/>' +
          "Now let's personalize your plan by revealing your " +
          '<b>Relationship & Personality Patterns</b>.',
        text:
          'Please take your time to answer thoughtfully. ' +
          'Each response helps us to craft a tailored guidance plan for you and your partner.',
        next: 'Next',
        back: 'Back',
      },
      issueTogether: {
        title: 'How strongly do you agree with the following statement?',
        subtitle: '<strong>My partner and I can talk about any issue together</strong>',
        answers: {
          stronglyAgree: 'Strongly agree',
          agree: 'Agree',
          neutral: 'Neither agree nor disagree',
          disagree: 'Disagree',
          stronglyDisagree: 'Strongly disagree',
        },
      },
      currentlyAffecting: {
        title: 'Are any of these factors currently affecting your relationship?',
        answers: {
          infidelity: 'Infidelity',
          depression: 'Depression',
          lowSexualDesire: 'Low sexual desire',
          poorBodyImage: 'Poor body image',
          postpartumDepression: 'Postpartum depression',
          anxiety: 'Anxiety',
          parentingIssues: 'Parenting issues',
          blendedFamilyIssues: 'Blended family issues',
          divorceIsOnTheTable: 'Divorce on the table',
          noneOfThese: 'None of these',
        },
        next: 'Next',
      },
      partnerPriority: {
        title: 'How strongly do you agree with the following statement?',
        subtitle: '<strong>My partner and I make sex a priority in our relationship</strong>',
        answers: {
          stronglyAgree: 'Strongly agree',
          agree: 'Agree',
          neutral: 'Neither agree nor disagree',
          disagree: 'Disagree',
          stronglyDisagree: 'Strongly disagree',
        },
      },
      satisfied: {
        title: 'Are you satisfied with how you and your partner communicate?',
        answers: { yes: 'Yes', no: 'No' },
      },
      satisfiedYes: {
        title: `Well done!<br/>Based on our data, only the top 30% of <span>{{translated pluralZodiac}}</span> find it easy to communicate with their <span>{{translated partnerZodiac}}</span> partner.`,
        next: 'Next',
        back: 'Back',
      },
      satisfiedNo: {
        title: `You’re not alone.<br/>Based on our data, 74% of <span>{{translated pluralZodiac}}</span> find it difficult to communicate with their <span>{{translated partnerZodiac}}</span> partner.<br/>We can help you improve this.`,
        next: 'Next',
        back: 'Back',
      },
      emotionalConnection: {
        title: 'How strongly do you agree with the following statement?',
        subtitle:
          '<strong>Strengthening our emotional connection is a priority for both my partner and me</strong>',
        answers: {
          stronglyAgree: 'Strongly agree',
          agree: 'Agree',
          neutral: 'Neither agree nor disagree',
          disagree: 'Disagree',
          stronglyDisagree: 'Strongly disagree',
        },
      },
      strengthen: {
        title:
          'Nebula helps to <b> strengthen your ' +
          '</br>' +
          'relationship</b> and <b>improve your ' +
          '</br>' +
          'love life</b>*',
        tooltip: 'Survey with 1126 respondents, conducted in June 2021',
        text: '* 75% of our users have reported astrology helped improve their relationship',
        next: 'Next',
      },
      bigPicture: {
        title: 'Would you describe your partner as a detail-oriented or a big-picture person?',
        answers: {
          detailOriented: 'Detail-oriented',
          bigPicture: 'Big-picture',
          both: 'A bit of both',
        },
      },
      introvertOrExtravert: {
        title: 'Is your partner an introvert or an extrovert?',
        answers: { introvert: 'Introvert', extrovert: 'Extrovert', both: 'A bit of both' },
      },
      irritated: {
        title: 'Does your partner get angry or irritated easily?',
        answers: { yes: 'Yes', sometimes: 'Sometimes', rarely: 'Rarely', notAtAll: 'Not at all' },
      },
      conflict: {
        title: 'Are you satisfied with the way you and your partner deal with conflict?',
        answers: {
          honest: 'Yes, we are both honest and direct',
          situation: 'Depends on the situation',
          hurt: "No, we end up hurting each other / we're afraid to hurt each other",
          nervous: 'No, conflicts stress me out',
        },
      },
      aboutGoals: {
        title: 'When you think about your relationship goals, you feel...',
        answers: {
          partnerRightPlace: 'Optimistic! They are totally doable — with some guidance.',
          partnerThing: 'Cautious. I’ve struggled before, but I’m hopeful.',
          partnerTotallyNormal: 'A little anxious, honestly.',
          notSure: 'Not sure / Don’t know',
        },
      },
      partnerRightPlace: {
        title: `You’ve come to the right place,<br/><span>{{translated zodiac}}</span>!`,
        subtitle:
          'Knowing that your goal is already doable puts you ahead. ' +
          'Let’s keep going so we can build a guidance plan tailored to your cosmic blueprint.',
        next: 'Next',
        back: 'Back',
      },
      partnerThing: {
        title: `<span>{{translated zodiac}}</span>, here’s the thing...<br/>You can absolutely do this!`,
        subtitle:
          "We'll start with bite-sized insights so it doesn't feel too overwhelming. Let's " +
          'keep going and build a guidance plan tailored to your cosmic blueprint.',
        next: 'Next',
        back: 'Back',
      },
      partnerTotallyNormal: {
        title: `It’s totally normal to feel nervous,<br/><span>{{translated zodiac}}</span>.`,
        subtitle:
          'But we know you can do this. ' +
          'Let’s keep going so we can build a guidance plan tailored to your cosmic blueprint.',
        next: 'Next',
        back: 'Back',
      },
      appreciated: {
        title: 'How strongly do you agree with the following statement?',
        subtitle: '<strong>My partner makes me feel really appreciated.</strong>',
        answers: {
          stronglyAgree: 'Strongly agree',
          agree: 'Agree',
          neutral: 'Neither agree nor disagree',
          disagree: 'Disagree',
          stronglyDisagree: 'Strongly disagree',
        },
      },
      decisions: {
        title: 'Do you make decisions with your head or your heart?',
        answers: { heart: 'Heart', head: 'Head', both: 'Both' },
      },
      withHeart: {
        title: `Based on our data, 49% of <span>{{translated pluralZodiac}}</span> also make decisions with their hearts. We'll consider that while creating you guidance plan.`,
        next: 'Next',
        back: 'Back',
      },
      withHead: {
        title: `Based on our data, 39% of <span>{{translated pluralZodiac}}</span> also make decisions with their heads. We'll consider that while creating you guidance plan.`,
        next: 'Next',
        back: 'Back',
      },
      both: {
        title: `Wonderful!<br/>Based on our data, only the top 17% of <span>{{translated pluralZodiac}}</span> make decisions with their hearts and heads. Using both in equal measure is the key to feeling harmonious in your relationships.`,
        titleFirstLine: 'Wonderful!',
        titleSecondLine: 'Based on our data, only the top 17% of ',
        titleEnd:
          ' people make decisions with their heart and head. ' +
          'Using both in equal measure is the key to feeling harmonious in your relationships.',
        next: 'Next',
        back: 'Back',
      },
      aboutUs: {
        title: 'Where did you hear about us?',
        answers: {
          poster: 'Poster or billboard',
          friend: 'Friend or family',
          instagram: 'Instagram',
          mail: 'Direct mail or package insert',
          streaming: 'Online or streaming TV',
          tv: 'TV',
          radio: 'Radio',
          search: 'Search engine (Google, Bing, etc.)',
          newspaper: 'Newspaper or magazine',
          facebook: 'Facebook',
          review: 'Blog post or website review',
          podcast: 'Podcast',
          influencer: 'Influencer',
          youtube: 'Youtube',
          pinterest: 'Pinterest',
          other: 'Other',
        },
      },
      loading: {
        initialText: 'Analyzing your profile...',
        steps: {
          position: 'Identifying the planetary positions when you were born...',
          blueprint: 'Creating your astrological blueprint...',
          profile: 'Assessing personality profile...',
          strength: 'Identifying your strengths and weaknesses...',
          compatibility: 'Analyzing your compatibility...',
          relationship: 'Analyzing relationship needs...',
          guidance: 'Charting best guidance plan...',
          predicting: 'Predicting future results...',
          alone: 'Do you enjoy time spent alone?',
          adventurous: 'Are you an adventurous person?',
          remedies: 'Have you ever tried any alternative remedies or rituals?',
        },
        question: { yes: 'Yes', no: 'No' },
        text: 'Please wait while we’re building your personalized guidance plan based on your unique astrological blueprint and millions of data points from successful Nebula users.',
      },
      email: {
        title: 'Enter your email to see how you can',
        nebula: 'with Nebula',
        placeholder: 'Your email',
        error: 'Please enter a valid email address',
        text:
          'Nebula respects your privacy and does not share your personal information. ' +
          "We'll email you a copy of your personalized program for convenient access.",
        label:
          'I would like to receive my report and personalized offers' +
          ' & updates from nebula via this email.',
        button: 'Continue',
        tiktok: {
          title:
            'Enter your email to see how you can discover compatibility and understand yourself better with Nebula',
        },
      },
      emailConfirm: {
        title:
          'Get access to your <b>exclusive report</b>, ' +
          'special offers, hyper-personalized horoscopes, relationship tips, rituals, remedies and free gifts.',
        subtitle: "Just confirm you want to receive emails so you don't miss out on anything",
        button: 'Confirm',
        skip: 'I know everything about astrology and relationships',
      },
      onboarding: {
        list: [
          'Welcome to Nebula',
          'Based on your answers',
          'We’ve created your astrological blueprint and guidance plan',
          'To help you better understand yourself',
          'And to improve your relationship —<br/>for good.',
          'Let’s get started.',
        ],
        listWithGoal: [
          'Welcome to Nebula',
          'Based on your answers',
          'We’ve created your astrological blueprint and guidance plan',
          'To help you {{goal}}',
          'And to improve your relationship —<br/>for good.',
          'Let’s get started.',
        ],
      },
      salesScreen: {
        title: 'Your Personalized Clarity & Love Reading is ready!',
        fixedHeader: {
          title: `Discount expires in`,
          minutes: 'min',
          seconds: 'sec',
          button: 'get my reading',
        },
        userDetails: {
          youImageText: 'You',
          partnerImageText: 'Partner',
          zodiac: 'Zodiac Sign',
          gender: 'Gender',
          dateOfBirth: 'Date of birth',
          placeOfBirth: 'Place of birth',
          female: 'Female',
          male: 'Male',
        },
        sectionGoal: {
          title: 'The goal to be achieved',
          'Find my perfect partner': 'Find my perfect partner',
          'Understand myself better': 'Understand myself better',
          'To get married': 'Get married',
          'Achieve happiness': 'Achieve happiness',
          'Personal growth': 'Personal growth',
          'Check compatibility': 'Check compatibility',
          'Increase relationship satisfaction': 'Increase relationship satisfaction',
          'Fix relationship problems': 'Fix relationship problems',
          'Build a strong marriage': 'Build a strong marriage',
          'All above': 'Find love and understand myself better',
          'All above in relationships': 'Improve relationship',
        },
        specialOffer: {
          header: 'Special offer',
          trialTitle: 'Personalized reading for',
          total: 'Total today:',
          discount: 'Discount expires in',
          saveMoney: 'Save {{discount}} every period',
          perWeeksCostOld: 'Your cost per month <bold>after trial</bold>',
          perWeeksCost: 'Including 1-week trial personal reading',
          optionTitle: 'Choose option:',
          expensiveOption:
            'Supporting those who need to choose the lowest trial price by choosing this option',
          getAccess: 'get my reading',
        },
        guaranteeText: 'Guaranteed security payments',
        blurredReport: {
          title: 'Your reading',
          zodiac: 'Zodiac Sign',
          gender: 'Gender',
          contentTitle: 'Content',
          points: [
            'Compatibility with your partner in other areas of your life.',
            'Deep analysis of the relationships with your partner based on a unique birth chart matching system',
            'Simple and actionable guide to improving your relationship with your partner',
            'Warning about astrological events and practical advice that will help you get through this period well',
            'Your horoscope and upcoming events for 2023',
            'Your unique strengths and weaknesses and how to get the most out of them',
          ],
          personalityTitle: 'Personality',
          personalityContent:
            'Aquarius is the 11th sign of the zodiac and represents ' +
            'inventiveness and brilliance. The ruling planets are Uranus and Saturn, ' +
            'which gives this sign a unique blend of a restricted yet liberated personality. ' +
            'Saturn is associated with limits and boundaries while Uranus is ',
          accessDescription: 'To read the full report, you need to get access',
          getAccess: 'get my reading',
        },
        userReviews: {
          title: 'Users love us',
          list: {
            user1: {
              review:
                'It was really helpful and had provided me the clarity that ' +
                'I needed for my current relationship situation. ' +
                'It gives me hope that my relationship could still be save. Thank you. Highly recommended!',
            },
            user2: {
              review:
                'Amazing, absolutely amazing! The affirmations ' +
                'I received and nurturing advice, was worth everything ! Truly, thank you !!',
            },
            user3: {
              review:
                'It helps me be able to trust my self and my choices for the future ' +
                'by giving me reassurance with the information i get. ' +
                'My goals and dreams are going to happen and and now i trust myself ' +
                'to do as a need and wish',
            },
          },
        },
        benefits: {
          title: 'What you get',
          points: [
            'Your personalised plan',
            '1:1 guidance from your trusted advisor',
            'Finding the most compatible partner',
            'Insights into your relationship patterns and emotional and sexual needs',
            'A better understanding of yourself',
          ],
        },
        featuredInTitle: 'As featured in',
        QASection: {
          title: 'People often ask:',
          question1: 'How accurate is the astrology reading on this platform?',
          answer1:
            'The accuracy of an astrology reading can vary and be subjective. ' +
            'Astrology is not an exact science, but many find that it can provide valuable ' +
            'insights and perspectives. Our platform uses advanced algorithms and expert ' +
            'astrologers to provide the most accurate readings possible.',
          question2: 'Can I get a compatibility reading for relationships?',
          answer2:
            'Yes, you can get a compatibility reading for relationships. This type of reading ' +
            'includes a comprehensive astrological analysis of two people to assess their ' +
            'compatibility in various areas, including love, communication and shared values.',
          question3: 'Are the astrology readings on this platform confidential and private?',
          answer3:
            'Yes, all readings on our platform are strictly confidential. We respect ' +
            "our users' privacy and ensure that all personal data and readings are securely " +
            'stored and not shared with third parties without consent.',
        },
        charge: {
          trial:
            'You will be charge only <bold>{{trialPrice}} for your 7-day trial.</bold><br/>' +
            'We’ll <bold>email you a reminder</bold> before your trial period ends. ' +
            '<bold>Cancel anytime.</bold>',
          europeanTrial:
            'You will be charged only <bold>{{europeanTrialPrice}}</bold> ' +
            'for your 7-day trial.<br/> We’ll <bold>email you a reminder</bold> ' +
            'before your trial period ends. <bold>Cancel anytime.</bold>',
          trialUSStart: 'You are enrolling in a 2-week subscription to ',
          trialUSEnd:
            " service. By continuing you agree that if you don't" +
            ' cancel prior to the end of the 7-day trial for the {{trialPrice}} you will automatically be charged' +
            ' {{fullPrice}} every 2 weeks until you cancel in settings.' +
            ' Learn more about cancellation and refund policy in',
          trialUS:
            "You are enrolling in monthly subscription to NEBULA service. By continuing you agree that if you don't" +
            ' cancel prior to the end of the 7-day trial for the {{trialPrice}} you will automatically be charged' +
            ' {{fullPrice}} every 30 days until you cancel in settings. The charge will appear on your bill as' +
            ' {{domainUrl}}. Learn more about cancellation and refund policy in ',
        },
        chargeTest:
          'You will be charge only <bold>{{price}} for your 7-day trial.</bold><br/>' +
          'We’ll <bold>email you a reminder</bold> before your trial period ends. ' +
          '<bold>Cancel anytime.</bold>',
        description: {
          text:
            'You are enrolling in 2 weeks subscription to NEBULA service. ' +
            "By continuing you agree that if you don't cancel prior to the end of the 7-day trial " +
            'for the <bold>{{trialPrice}}</bold> ' +
            'you will automatically be charged <bold>{{plan}} ' +
            'every 2 weeks </bold> until you cancel in settings. The charge will appear on your bill as {{domainUrl}}. Learn more about ' +
            'cancellation and refund policy in ',
          textEU:
            'You are enrolling in 2 weeks subscription to NEBULA service. ' +
            "By continuing you agree that if you don't cancel prior to the end of the 7-days trial " +
            'for the <bold>{{europeanTrialPrice}}</bold>, you will ' +
            'automatically be charged {{europeanTrialPrice}}  ' +
            'thereafter the standard rate of <bold>{{plan}} every 2 weeks</bold> until ' +
            'you cancel in settings. The charge will appear on your bill as {{domainUrl}}. Learn more about cancellation and refund policy in ',
          terms: 'Subscription policy',
          termsEU: 'Subscription terms',
          termsExperiment: 'Subscription terms',
        },
        trialChoiceDescription: {
          header:
            "We've helped millions of people to have happier lives and better relationships, and " +
            'we want to help you, too.',
          money:
            'Money shouldn’t stand in the way of finding astrology guidance that finally works. So, please choose an amount that you think is reasonable to try us out for one week.',
          paymentDescription:
            'It costs us  {{priceForUs}} to offer a 7-day trial, but please choose the amount ' +
            'you are comfortable with.',
        },
      },
      trialChoice: {
        header:
          "We've helped millions of people to have happier lives and better relationships, and " +
          'we want to help you, too.',
        money:
          'Money shouldn’t stand in the way of finding astrology guidance that finally works. So, please choose an amount that you think is reasonable to try us out for one week.',
        paymentDescription:
          'It costs us  {{priceForUs}} to offer a 7-day trial, but please choose the amount ' +
          'you are comfortable with.',
        expensiveOption:
          'This option will help us support those who need to select the lowest trial prices!',
        button: 'See my plan',
        note: '*as of February 2023',
        information: {
          title: 'people joined today',
          list: [
            'anton**** <b>chose {{ lowerPrice }}</b>',
            'chiar**** <b>chose {{ middlePrice }}</b>',
            'edwar**** <b>chose {{ lowerPrice }}</b>',
            'talen**** <b>chose {{ middlePrice }}</b>',
            'janin**** <b>chose {{ middlePrice }}</b>',
            'peter**** <b>chose {{ middlePrice }}</b>',
            'heath**** <b>chose {{ middlePrice }}</b>',
            'lucas**** <b>chose {{ lowerPrice }}</b>',
            'tamar**** <b>chose {{ lowerPrice }}</b>',
            'izzy_**** <b>chose {{ greaterPrice }}</b>',
            'coryz**** <b>chose {{ highPrice }}</b>',
            'diann**** <b>chose {{ middlePrice }}</b>',
            'gofor**** <b>chose {{ lowerPrice }}</b>',
            'benne**** <b>chose {{ middlePrice }}</b>',
            'matth**** <b>chose {{ middlePrice }}</b>',
            'Olive**** <b>chose {{ highPrice }}</b>',
            'teres**** <b>chose {{ middlePrice }}</b>',
            'tommy**** <b>chose {{ lowerPrice }}</b>',
            'betha**** <b>chose {{ greaterPrice }}</b>',
            'yukin**** <b>chose {{ middlePrice }}</b>',
            'ilove**** <b>chose {{ greaterPrice }}</b>',
            'fitzg**** <b>chose {{ lowerPrice }}</b>',
            'r_mel**** <b>chose {{ middlePrice }}</b>',
            'clare**** <b>chose {{ greaterPrice }}</b>',
            'elize**** <b>chose {{ lowerPrice }}</b>',
            'carlo**** <b>chose {{ greaterPrice }}</b>',
            'lucil**** <b>chose {{ lowerPrice }}</b>',
            'ellen**** <b>chose {{ highPrice }}</b>',
            'carli**** <b>chose {{ middlePrice }}</b>',
            'rebec**** <b>chose {{ greaterPrice }}</b>',
            'cinth**** <b>chose {{ greaterPrice }}</b>',
            'flavi**** <b>chose {{ lowerPrice }}</b>',
            'awint**** <b>chose {{ middlePrice }}</b>',
            'linda**** <b>chose {{ lowerPrice }}</b>',
            'jazzy**** <b>chose {{ lowerPrice }}</b>',
            'claud**** <b>chose {{ lowerPrice }}</b>',
            'katja**** <b>chose {{ highPrice }}</b>',
            'james**** <b>chose {{ middlePrice }}</b>',
            'krist**** <b>chose {{ lowerPrice }}</b>',
            'alice**** <b>chose {{ lowerPrice }}</b>',
            'johnr**** <b>chose {{ lowerPrice }}</b>',
            'lucyl**** <b>chose {{ lowerPrice }}</b>',
            'hanna**** <b>chose {{ greaterPrice }}</b>',
            'dariu**** <b>chose {{ highPrice }}</b>',
            'jean.**** <b>chose {{ middlePrice }}</b>',
            'palom**** <b>chose {{ highPrice }}</b>',
            'oweng**** <b>chose {{ lowerPrice }}</b>',
            'aless**** <b>chose {{ lowerPrice }}</b>',
            'nadia**** <b>chose {{ lowerPrice }}</b>',
            'agnes**** <b>chose {{ lowerPrice }}</b>',
          ],
        },
      },
      productOverview: {
        banner: 'Get 30% off + 7 days trial with code <highlight>Nebula22</highlight>',
        title: 'Hey, <zodiac>{{translated zodiac}}</zodiac> Sun,',
        subtitle:
          'Based on your answers and birth chart, ' +
          "<highlight>we've prepared your personalized plan.</highlight>",
        includedTitle: 'Highlights of your plan:',
        list: {
          advice: '1:1 advice from your own astrologer',
          finding: 'Finding the most compatible partner',
          improving: 'Improving your relationship with your partner',
          patterns: 'Insights into your relationship patterns, and emotional and sexual needs',
          betterUnderstanding: 'Better understanding of yourself',
          loved: 'and your loved one',
        },
        reviewsTitle: 'Customer reviews:',
        featuredTitle: 'As featured in:',
        button: 'Continue',
      },
      trialPayment: {
        specialOffer: 'Special offer',
        title: 'Start your 7-day trial',
        subtitle: {
          text: 'Cancel anytime.',
          noPressure: 'No pressure.',
          other: 'No commitment. ',
        },
        total: 'Total today:',
        readingTitle: 'Get your reading',
        readingSubtitle: 'Personalized just for you.',
        specialCode: 'Code NEBULA22 applied!',
        timer: 'Reserved for',
        price: {
          text: 'Your cost per 2 weeks ',
          trial: 'after trial',
          save: 'Save ',
          readingTitle: 'Your price for the reading:',
          period: 'every period',
        },
        gender: {
          male: 'male',
          female: 'female',
        },
        information: {
          title: 'people learned relationship insights in last hour',
          list: [
            'wesle**** <b>got 7 insights</b>',
            'karol**** <b>got 2 insights</b>',
            'david**** <b>got 9 insights</b>',
            'craig**** <b>got 1 insights</b>',
            'brian**** <b>got 8 insights</b>',
            'joann**** <b>got 5 insights</b>',
            'grego**** <b>got 6 insights</b>',
            'amyma**** <b>got 6 insights</b>',
            'sandy**** <b>got 1 insights</b>',
            'chloe**** <b>got 9 insights</b>',
            'rache**** <b>got 9 insights</b>',
            'darre**** <b>got 4 insights</b>',
            'karin**** <b>got 1 insights</b>',
            'miche**** <b>got 12 insights</b>',
            'georg**** <b>got 8 insights</b>',
            'hanna**** <b>got 4 insights</b>',
            'erica**** <b>got 3 insights</b>',
            'kelly**** <b>got 8 insights</b>',
            'jessi**** <b>got 6 insights</b>',
            'geral**** <b>got 8 insights</b>',
            'patri**** <b>got 1 insights</b>',
            'carlo**** <b>got 2 insights</b>',
            'melan**** <b>got 5 insights</b>',
            'gordo**** <b>got 6 insights</b>',
            'marye**** <b>got 12 insights</b>',
            'calvi**** <b>got 7 insights</b>',
            'rebec**** <b>got 7 insights</b>',
            'yolan**** <b>got 10 insights</b>',
            'simon**** <b>got 8 insights</b>',
            'maggi**** <b>got 10 insights</b>',
            'jade.**** <b>got 1 insights</b>',
            'court**** <b>got 7 insights</b>',
            'jesse**** <b>got 6 insights</b>',
            'tammy**** <b>got 2 insights</b>',
            'felic**** <b>got 11 insights</b>',
            'ivan.**** <b>got 10 insights</b>',
            'carol**** <b>got 2 insights</b>',
            'karen**** <b>got 9 insights</b>',
            'trish**** <b>got 6 insights</b>',
            'pamel**** <b>got 9 insights</b>',
            'marks**** <b>got 2 insights</b>',
            'brend**** <b>got 9 insights</b>',
            'lucyl**** <b>got 6 insights</b>',
            'hanna**** <b>got 9 insights</b>',
            'dariu**** <b>got 11 insights</b>',
            'jean.**** <b>got 12 insights</b>',
            'palom**** <b>got 9 insights</b>',
            'oweng**** <b>got 4 insights</b>',
            'aless**** <b>got 8 insights</b>',
            'nadia**** <b>got 5 insights</b>',
          ],
        },
        charge: {
          trial:
            'You will be charged only <bold>{{trialPrice}} for your 7-day trial.</bold><br/>' +
            'We’ll <bold>email you a reminder</bold> before your trial period ends. ' +
            '<bold>Cancel anytime.</bold>',
          europeanTrial:
            'You will be charged only <bold>{{europeanTrialPrice}}</bold> ' +
            'for your 7-day trial.<br/> We’ll <bold>email you a reminder</bold> ' +
            'before your trial period ends. <bold>Cancel anytime.</bold>',
          email:
            'We’ll <bold>email you a reminder</bold> before your trial period ends. ' +
            '<bold>Cancel anytime.</bold>',
          reading: 'You will be charged only <bold>{{trialPrice}}</bold> for your reading.',
        },
        pay: 'Get access',
        description: {
          text:
            'You are enrolling in {{period}} subscription to <lnk>{{mainAppUrl}}</lnk> service. ' +
            'Extended biweekly after 1 week trial for the ' +
            '<bold>{{currencySymbol}}1/{{currencySymbol}}5/{{currencySymbol}}9/{{currencySymbol}}13.67</bold> ' +
            'at the price of {{plan}} {{currencySymbol}}. Taxes calculated at checkout.',
          textEU:
            'You are enrolling in {{period}} subscription to <lnk>{{mainAppUrl}}</lnk> service. ' +
            "By continuing you agree that if you don't cancel prior to the end of the 7-days trial for the " +
            '<bold>{{currencySymbol}}1/{{currencySymbol}}5/{{currencySymbol}}9/{{currencySymbol}}13.67</bold>, ' +
            'you will automatically be charged {{europeanTrialPrice}} {{currency}} ' +
            'thereafter the standard rate of {{plan}} {{currency}} every {{period}} until ' +
            'you cancel in settings. Learn more about cancellation and refund policy in ',
          terms: 'Subscription policy',
          termsEU: 'Subscription terms',
        },
        address: `${config.companyName}, ${config.address}`,
      },
      payment: {
        title: 'Choose payment method',
        divider: 'or',
        form: {
          default: {
            submitButtonText: 'Start',
            charged: 'You will be charged only {{price}}{{currencySymbol}} for your 7-day trial.',
            chargedExperiment:
              'You will be charged only {{price}}{{currencySymbol}} for your 3-day trial.',
          },
          resign: {
            submitButtonText: 'Buy',
          },
        },
        charge: {
          trial:
            'You will be charged only <bold>{{price}} for your {{trialPeriod}} trial.</bold><br/>' +
            'We’ll <bold>email you a reminder</bold> before your trial period ends. Cancel anytime.',
          trialUS:
            'You will only be charged the <bold>{{price}} for your 7-day trial period.</bold> ' +
            'We <bold>will send you an email reminder</bold> before the end of your trial period.  ' +
            'You can cancel at any time. The charge will appear on your invoice as {{domainUrl}}.',
          full: 'You will be charged only <bold>{{price}} for your {{trialPeriod}} trial.</bold>',
          email:
            'We’ll <bold>email you a reminder</bold> before your trial period ends. ' +
            '<bold>Cancel anytime.</bold>',
        },
        pay: {
          trial: 'Your <bold>{{trialPeriod}} trial</bold>',
          price: 'Only',
          tax: 'Value added tax',
          total: 'Total',
        },
        resources: 'Guaranteed security payments',
      },
      introOffer: {
        charge: {
          trial:
            'You will be charged only <bold>{{price}} for your {{trialPeriod}} {{trialWord}}.</bold><br/>' +
            'We’ll <bold>email you a reminder</bold> before your trial period ends. Cancel anytime.',
          trialUS:
            'You will only be charged the <bold>{{price}} for your 7-day trial period.</bold> ' +
            'We <bold>will send you an email reminder</bold> before the end of your trial period.  ' +
            'You can cancel at any time. The charge will appear on your invoice as {{domainUrl}}.',
          full: 'You will be charged only <bold>{{price}} for your {{trialPeriod}} trial.</bold>',
          email:
            'We’ll <bold>email you a reminder</bold> before your trial period ends. ' +
            '<bold>Cancel anytime.</bold>',
        },
      },
      additionalDiscount: {
        title: 'Save 65% off!',
        plan: '65% off on your personalized plan',
        trial: '7-day trial',
        price: 'instead of',
        button: 'Get secret discount!',
      },
      trialPaymentWithDiscount: {
        offer: 'SECRET discount <bold>65% off</bold> applied',
        title: 'You get a secret discount!',
        pay: 'Start your 7-day trial',
        subtitle: 'No pressure. Cancel anytime.',
        specialCode: 'Secret discount applied!',
        price: 'Your cost per 2 weeks after trial:',
        priceExperiment: 'Your cost per 1 month after trial:',
        saveMoney: 'You save {{savedMoney}} every period',
        saveMoneyExperiment: 'You save {{savedMoney}}',
        total: 'Total today:',
        description: `By continuing you agree that if you don't cancel prior to the end of the 7-days trial, you will automatically be charged {{discountPrice}} for the introductory period of 2 weeks thereafter the standard rate of {{fullPrice}} every 2 weeks until you cancel in settings. Learn more about cancellation and refund policy in `,
        descriptionExperiment: `By continuing you agree that if you don't cancel prior to the end of the 7-days trial, you will automatically be charged {{discountPrice}} for the introductory period of 30 days thereafter the standard rate of {{fullPrice}} every 30 days until you cancel in settings. Learn more about cancellation and refund policy in `,
        terms: 'Subscription terms',
      },
      unlimitedReadings: {
        title: 'Access the unlimited astrology guides and compatibility readings',
        contains: {
          affirmations: 'personal affirmations for everyday',
          moreInsights: 'more insight in less time',
          learnVisually: 'learn visually',
          minutesToRead: 'minutes to read',
        },
        benefits: {
          unlimitedCompatibilityChecks: 'check compatibility as many times as you need',
          manyInsight: 'hundreds of insights to help you to achieve your relationship goals',
          dailyHoroscopes: 'personalized daily horoscopes and astrological guides with no ads',
          autoRenewable: 'auto-renewable 1-month plan, cancel anytime',
        },
        button: 'Add unlimited readings',
        skip: 'Skip this offer and proceed further',
        disclaimer:
          "Please note: In addition to your subscription, your account will incur recurring charges of {{price}} per month for the selected add-ons when you choose the 'Add unlimited readings' option. Items on this page are {{period}} subscriptions. Unless you cancel it in your profile before the end of the then-current period, you agree that the subscription will renew automatically at the end of each period. If you need help with how to cancel, visit our ",
      },
      upsells: {
        header: {
          step1: 'Buy reading',
          step2: 'Consultations',
          step3: 'Access product',
          banner: {
            title: 'Thank you!',
            text: 'Your order was successful!',
          },
          title: 'Choose your sign-up offer',
          subtitle: 'Available only now',
        },
        headerNew: {
          addReport: 'Add Report',
          unlimitedReadings: 'Unlimited Readings',
          addConsultation: 'Add Consultation',
          accessProduct: 'Access Product',
        },
        footer: {
          description:
            '*You will be charged for the add-on services or offers selected at the time of their preparation and delivery to your email.<br/>' +
            'This is a non-recurring payment.',
        },
        button: 'Get my copy',
        link: 'Skip this offer and proceed further',
      },
      trialCredits: {
        header: {
          step1: 'Buy reading',
          step2: 'Consultations',
          step3: 'Access product',
          title: 'More for you',
          subtitle: 'Exclusive offer recommended for you to achieve your goals faster',
        },
        payment: {
          title: 'Your unique individual consultation',
          consultation:
            '<highlight>30-minute private </highlight><br />' +
            '<highlight>consultation with an expert</highlight>',
          description:
            'You can ask for prediction of the future, live compatibility analysis; love compatibility etc',
          offerPrice: 'One time price offer:',
          originalPrice: {
            price: 'Original price:',
            discount: 'Save',
          },
        },
        footer: {
          description:
            '*You will be charged for the add-on services or offers selected at the time of their preparation and delivery to your email.' +
            '<br /> This is a non-recurring payment.',
        },
        button: 'Get my consultation',
        link: 'Skip this offer and proceed further',
      },
      onboardingLP: {
        welcome: 'Welcome to',
        title: 'AskNebula',
        text:
          'Hyper <highlight>personalized</highlight> consultations & readings ' +
          '<highlight>with top advisors</highlight>',
      },
      talkWithExpert: {
        text:
          'As a part of your guidance plan, you need to talk with the psychic advisor about <br />' +
          '<highlight>{{userAnswer}}</highlight>',
        single: {
          perfectPartner: 'finding your perfect partner',
          understandBetter: 'understanding yourself better',
          loadingBoth: 'finding your perfect partner and understanding yourself better',
        },
        relationship: {
          fixProblems: 'fixing relationship problems',
          relationshipSatisfaction: 'increasing relationship satisfaction',
          both: 'fixing relationship problems and increasing relationship satisfaction',
        },
      },
      questions: {
        title: 'What our users ask about',
        tip: '* based on survey and with consent of participants',
        firstMessage: {
          single: 'Does my ex still love me?',
          notSingle: 'Does my partner cheat on me?',
        },
        secondMessageSingle: {
          understandBetter: 'What is my divine life purpose?',
          default: 'When will I get married?',
        },
        secondMessageRelationship: {
          relationshipSatisfaction: 'Am I with my soulmate?',
          default: 'Why has my partner become so distant?',
        },
        thirdMessageSingle: {
          understandBetter: 'What do I need to do to understand myself better?',
          default: 'What do I need to do to find my perfect partner?',
        },
        thirdMessageRelationship: {
          relationshipSatisfaction: 'What do I need to do to improve relationship satisfaction?',
          default: 'What do I need to do to fix my relationship problems?',
        },
      },
      credits: {
        text:
          'Your plan includes 150 credits. <highlight> You will receive ' +
          'additional {{creditsAmount}} credits every month to discuss the progress with your advisor </highlight>',
      },
      loadingLP: {
        title:
          'Hooray! <highlight>Matching</highlight> you to <highlight>the right advisor</highlight>',
        list: [
          'Analyzing your answers...',
          'Fetching a list of available advisors...',
          'Matching you to the best advisors...',
          'Personalizing your experience...',
        ],
      },
      additionalQuestionOne: {
        title: 'Would you like to get even more?',
        text: 'Discuss your relationship and other life issues with a psychic advisor!',
        redirectButton: 'Find advisor',
        continueButton: 'I’m not interested',
      },
      additionalQuestionTwo: {
        title: "Are you sure you don't want to find answers that affect the quality of your life?",
        text:
          'AskNebula has top psychic advisors that ' +
          'can provide <bold>accurate readings on love&relationship, life and other topics</bold>',
        redirectButton: 'I don’t want any answers',
        continueButton: 'I want to find answers',
      },
    },
    'intro-offer': {
      credits: {
        text:
          'Your plan includes {{creditsAmount}} credits. <highlight> You will receive ' +
          'additional 300 credits every month to discuss the progress with your advisor </highlight>',
      },
    },
    'natal-chart': {
      progressBar: 'Take control of your life',
      billingScreen: {
        description:
          'By continuing you agree that you will automatically be charged {{price}} every month until you cancel in settings. Learn more about cancellation and refund policy in ',
        trialDescription:
          "You are enrolling in <bold>7-day subscription</bold> to NEBULA service. By continuing you agree that if you don't cancel prior to the end of the <bold>7-days trial for {{trialPrice}}</bold>, you will automatically be charged {{price}} every month until you cancel in settings. Learn more about cancellation and refund policy in",
        terms: 'Subscription terms.',
      },
    },
    ultimatePackages: {
      week1: {
        title: 'Week 1:',
        text: 'Numerology analysis',
      },
      week2: {
        title: 'Week 2:',
        text: 'Tarot reading',
      },
      week3: {
        title: 'Week 3:',
        text: 'Palmistry guide',
      },
      'week4-6': {
        title: 'Weeks 4-6:',
        text: 'Angel readings',
      },
      'week7-10': {
        title: 'Week 7-10:',
        text: 'Destiny matrix & Wealth prediction',
      },
    },
    regularPackages: {
      week1: {
        title: 'Week 1:',
        text: 'Numerology analysis',
      },
      week2: {
        title: 'Week 2:',
        text: 'Tarot reading',
      },
      week3: {
        title: ' Week 3:',
        text: 'Palmistry guide',
      },
    },
    plans: {
      long: '10 readings',
      short: '3 readings',
      numerologyAnalysis: {
        oldPriceDescription: 'was',
        discount: '50% OFF',
        title: 'NUMEROLOGY ANALYSIS',
      },
      tarotReading: {
        oldPriceDescription: 'was',
        discount: '45% OFF',
        title: 'TAROT READING',
      },
      palmistryGuide: {
        oldPriceDescription: 'was',
        discount: '30% OFF',
        title: 'PALMISTRY GUIDE',
      },
      ultraPack: {
        oldPriceDescription: 'regular price',
        discount: '50% OFF',
        description: '(3 in 1 + 2 secret bonus readings)',
        title: 'ULTRA PACK',
      },
      numerologyAnalysisSubscription: {
        oldPriceDescription: 'was',
        discount: '50% OFF',
        title: 'NUMEROLOGY ANALYSIS',
      },
      tarotReadingSubscription: {
        oldPriceDescription: 'was',
        discount: '45% OFF',
        title: 'TAROT READING',
      },
      palmistryGuideSubscription: {
        oldPriceDescription: 'was',
        discount: '30% OFF',
        title: 'PALMISTRY GUIDE',
      },
      ultraPackSubscription: {
        oldPriceDescription: 'regular price',
        discount: '50% OFF',
        description: '(3 in 1 + 2 secret bonus readings)',
        title: 'ULTRA PACK',
      },
    },
    progressHeader: {
      step1: {
        top: 'Buy',
        bottom: 'report',
      },
      step2: {
        top: 'Add',
        bottom: 'readings',
      },
      step3: {
        top: 'Access',
        bottom: 'product',
      },
    },
    progressBarTitle: {
      profile: 'Your profile',
      personality: 'Personality traits',
      relationship: 'Relationship Pattern',
      partner: 'Your Partner’s Profile',
      personalityPatterns: 'Relationship & Personality Patterns',
    },
    agreement: {
      text: 'By continuing, I agree to',
      policy: "Nebula's Privacy policy",
      separator: 'and',
      terms: 'Terms of Use.',
    },
    paymentFailed: {
      title: 'Payment failed',
      text: 'Your transaction has failed due to some technical error. Please, try again.',
      button: 'Try again',
    },
    zodiac: {
      aquarius: 'aquarius',
      aries: 'aries',
      cancer: 'cancer',
      capricorn: 'capricorn',
      gemini: 'gemini',
      leo: 'leo',
      libra: 'libra',
      pisces: 'pisces',
      sagittarius: 'sagittarius',
      scorpio: 'scorpio',
      taurus: 'taurus',
      virgo: 'virgo',
    },
    zodiacDescription: {
      aquarius: {
        male: 'Revolutionary in thought, the Aquarius male breaks boundaries, envisioning a brighter, unconventional tomorrow.',
        female:
          'The Aquarius female, with her avant-garde spirit, dances to her own rhythm, forever championing innovation.',
      },
      aries: {
        male: 'The Aries male charges forward with unparalleled energy, always ready to conquer new frontiers.',
        female:
          'Radiating confidence, the Aries female often leads the pack, fueled by ambition and determination.',
      },
      cancer: {
        male: 'Deeply intuitive, the Cancer male guards his emotional realm, drawing strength from familial bonds.',
        female:
          'The nurturing spirit of a Cancer female creates an embracing cocoon of comfort for loved ones.',
      },
      capricorn: {
        male: "The Capricorn male, with mountain goat tenacity, climbs life's peaks with disciplined dedication.",
        female:
          'Grounded and wise, the Capricorn female stands as a pillar of resilience, merging ambition with purpose.',
      },
      gemini: {
        male: 'Ever-curious, the Gemini male is a whirlwind of ideas, often switching between topics with excitement.',
        female:
          'Sparkling with wit, the Gemini female charms with her versatility, constantly adapting to change.',
      },
      leo: {
        male: 'With his regal demeanor, the Leo male has a magnetic charisma that demands the spotlight.',
        female:
          'Vibrant and confident, the Leo female radiates warmth, ruling her domain with generosity and grace.',
      },
      libra: {
        male: "Driven by harmony, the Libra male gracefully balances life's challenges, always seeking the middle ground.",
        female:
          'Charm personified, the Libra female is the embodiment of elegance, wielding diplomacy with an artful touch.',
      },
      pisces: {
        male: 'Dreamy and empathetic, the Pisces male navigates realms of emotion, often expressing his soul through artistry.',
        female:
          'Ethereal and compassionate, the Pisces female feels deeply, weaving tales of romance and magic in her wake.',
      },
      sagittarius: {
        male: 'With wanderlust in his heart, the Sagittarius male chases knowledge and adventure, ever the eternal optimist.',
        female:
          'Vivacious and free-spirited, the Sagittarius female journeys through life, spreading joy and infectious enthusiasm.',
      },
      scorpio: {
        male: "The Scorpio male delves deep, with an intensity that can unravel life's mysteries, driven by passion and determination.",
        female:
          'The enigmatic Scorpio female possesses a magnetic allure, her depths veiling strength and vulnerability.',
      },
      taurus: {
        male: 'The Taurus male values stability, often displaying a potent mix of resilience and sensuality.',
        female:
          'Grounded and graceful, the Taurus female appreciates the beauty and luxury in life, always seeking comfort.',
      },
      virgo: {
        male: 'With an eye for detail, the Virgo male seeks perfection, often being the methodical problem solver in the room.',
        female:
          'Discerning and diligent, the Virgo female navigates life with analytical prowess and a pure heart.',
      },
    },
    randomZodiacDescription: {
      templates: [
        `A union of a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} brings together two distinct astrological energies.`,
        `The dynamic between a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} is always one of cosmic curiosity.`,
        `In the zodiac tapestry, the threads of a {{translated gender}} {{translated zodiac}} intertwine uniquely with those of a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `When a {{translated gender}} {{translated zodiac}} meets a {{translated partnerGender}} {{translated partnerZodiac}}, the universe surely takes a momentary pause.`,
        `A {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} coming together is like two stars aligning in the celestial dance.`,
        `The story of a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} is one written in the constellations.`,
        `Every encounter between a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} holds a universe of possibilities.`,
        `The celestial bond between a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} is an enigma waiting to be explored.`,
        `The skies whisper tales of interactions between a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `A {{translated gender}} {{translated zodiac}} joining forces with a {{translated partnerGender}} {{translated partnerZodiac}} is a cosmic event of its own.`,
        `In the great cosmic dance, a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} find a rhythm unique to them.`,
        `The mysteries of the universe are reflected in the meeting of a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `The astral energies of a {{translated gender}} {{translated zodiac}} merge uniquely with those of a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `Between a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}}, there lies an interstellar journey of discovery.`,
        `The stars shimmer differently when a {{translated gender}} {{translated zodiac}} aligns paths with a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `Cosmic wonders unfold when a {{translated gender}} {{translated zodiac}} meets a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `A {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} share a bond that resonates with the universe's mysteries.`,
        `The cosmic narrative takes an interesting turn with the convergence of a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `In the vastness of the cosmos, the alliance of a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} shines brightly.`,
        `The celestial saga of a {{translated gender}} {{translated zodiac}} meeting a {{translated partnerGender}} {{translated partnerZodiac}} is one for the astral ages.`,
        `Each interaction between a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} weaves a new starry tale.`,
        `The universe pauses to witness the synergy between a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `A {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} together form a constellation of possibilities.`,
        `In the grand theater of the cosmos, a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} play a notable duet.`,
        `A {{translated gender}} {{translated zodiac}} sharing space with a {{translated partnerGender}} {{translated partnerZodiac}} creates a unique celestial resonance.`,
        `The galaxies nod in agreement when a {{translated gender}} {{translated zodiac}} crosses paths with a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `There's a cosmic song that only a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} can harmonize together.`,
        `The astral winds change direction when a {{translated gender}} {{translated zodiac}} meets a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `In the zodiac's grand narrative, the tale of a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} holds its own allure.`,
        `The interplay between a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} is a cosmic dance of unique steps.`,
        `A {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} together form a starry spectacle worth observing.`,
        `When a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} unite, the cosmos leans in with interest.`,
        `A {{translated gender}} {{translated zodiac}}'s journey alongside a {{translated partnerGender}} {{translated partnerZodiac}} is a celestial story in the making.`,
        `The universe hums a special tune for the alliance of a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `A {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} together hold a special place in the zodiac's chronicles.`,
        `The cosmic currents flow distinctively when a {{translated gender}} {{translated zodiac}} interacts with a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `The astral realms take notice when a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} form a connection.`,
        `In the vast cosmic dance, a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} find a rhythm all their own.`,
        `A {{translated gender}} {{translated zodiac}} meeting a {{translated partnerGender}} {{translated partnerZodiac}} is a moment the stars always await.`,
        `The celestial spheres whisper tales of a {{translated gender}} {{translated zodiac}}'s encounters with a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `For every {{translated gender}} {{translated zodiac}} and {{translated partnerGender}} {{translated partnerZodiac}} duo, the universe crafts a unique storyline.`,
        `The cosmos has its own way of celebrating the meeting of a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `In the cosmic playbook, the bond between a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} is a chapter of its own.`,
        `The astral tides shift intriguingly with every interaction between a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `A {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} together chart a unique course across the zodiac skies.`,
        `The zodiac's mysteries deepen with the union of a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `When a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} align, the universe takes a fascinated glance.`,
        `The cosmic dance finds a distinct beat when a {{translated gender}} {{translated zodiac}} shares space with a {{translated partnerGender}} {{translated partnerZodiac}}.`,
        `A {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} together are a testament to the universe's endless wonders.`,
        `In the celestial orchestra, the notes played by a {{translated gender}} {{translated zodiac}} and a {{translated partnerGender}} {{translated partnerZodiac}} create a melody of their own.`,
      ],
    },
    input: {
      placeholder: {
        place: 'Enter place of birth',
        date: {
          year: 'year',
          month: 'month',
          day: 'day',
        },
      },
    },
    gender: {
      male: 'males',
      female: 'females',
    },
    general: {
      other: 'other',
    },
    pinnedInfoButton: {
      help: 'Help',
    },
    contactUs: {
      title: 'Contact Us',
      text:
        'We will be glad to assist you via email {{supportEmail}}. ' +
        'You can also send your question and feedback to ',
      supportCenter: 'Support center',
    },
  },
};
