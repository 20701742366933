import * as Sentry from '@sentry/nextjs';
import { AnalyticsClientService } from 'core/analytics/services';
import {
  ErrorMonitoring,
  ExclusiveEventHintOrCaptureContext,
  SentryInterface,
} from './ErrorMonitoring';

export class ErrorMonitoringClientService implements ErrorMonitoring {
  private sentryInstance: SentryInterface = Sentry;
  constructor(private analyticsService: AnalyticsClientService) {}

  addCustomTag(key: string, value: string) {
    this.sentryInstance.setTag(key, value);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  captureError(error: any, hint?: ExclusiveEventHintOrCaptureContext) {
    this.sentryInstance.captureException(error, hint);
  }

  identify() {
    const deviceId = this.analyticsService.getDeviceId();

    this.addCustomTag('device_id', deviceId);
  }
}
