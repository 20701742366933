import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { OrganicChannelAttributionStrategy } from 'core/common/services/channelAttribution';
import { AppThunk } from 'core/common/store/types';
import { isOrganicChannel } from 'core/common/utils/isOrganicChannel';
import { UtmTags } from 'core/user/entities';

export const syncUtmTags =
  (utm?: Record<string, string>): AppThunk =>
  async (dispatch, getState, services) => {
    const { utmTagsService, userService, loggingService: logger, analyticsService } = services;

    try {
      const utmTags = utmTagsService.getUtmTags();
      await userService.updateUtmTags({ ...utmTags, ...utm });
      analyticsService.updateUserProperties({ ...utmTags, ...utm });
    } catch (e) {
      const err = normalizeError(e);
      logger.error(err);
    }
  };

export const userUtmTagsUpdated = createAction<UtmTags>('userUtmTags/UPDATED');

export const markUtmSourceForIncomingUser =
  (): AppThunk => async (dispatch, getState, services) => {
    const { utmTagsService, loggingService, channelAttributionService } = services;

    const isUtmSourceAlreadyExist = !!utmTagsService.getUtmSource();

    if (isUtmSourceAlreadyExist) return;

    try {
      const referrer = document.referrer;
      const isOrganicUser = isOrganicChannel(referrer);

      if (isOrganicUser) {
        channelAttributionService.setAttributionStrategy(
          new OrganicChannelAttributionStrategy(referrer),
        );
      }

      const utmTags = channelAttributionService.getUtmTags();

      const updatedUtmTags = utmTagsService.updateUtmTags(utmTags);

      dispatch(userUtmTagsUpdated(updatedUtmTags));
    } catch (err) {
      const error = normalizeError(err);

      loggingService.error(error);
    }
  };
