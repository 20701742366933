import { ChannelAttributionService } from './ChannelAttributionService';

export class ChannelAttributionServerService implements ChannelAttributionService {
  getUtmTags() {
    return {};
  }

  setAttributionStrategy() {
    return {};
  }
}
