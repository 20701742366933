import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { ATTRS_USER_ID } from 'core/common/observability/entities';
import { AppThunk } from 'core/common/store';
import { ReportType } from 'core/flow/entities';
import { CreateOrderParams } from 'core/payments/entities';
import { updateOrderId } from 'core/payments/store/actions';
import { UserUpdateDTO } from 'core/user/services';
import { getUtmTags } from '../userUtmTags';
import { getIsUserTester, getUserInformation } from './selectors';

export const updateUserInformation = createAction(
  'user/UPDATE_USER_INFORMATION',
  (name: string, value: unknown) => ({
    payload: {
      name,
      value,
    },
  }),
);

export const updateUserPreferences = createAction<Record<string, unknown>>(
  'user/UPDATE_USER_PREFERENCES',
);

// do not remove fetchNebulaAppLink, it will be used in future tests
export const fetchNebulaAppLinkPending = createAction('user/FETCH_NEBULA_APP_LINK/pending');

export const fetchNebulaAppLinkSucceed = createAction<string>('user/FETCH_NEBULA_APP_LINK/succeed');

export const fetchNebulaAppLinkFailed = createAction('user/FETCH_NEBULA_APP_LINK/failed');

export const fetchNebulaAppLink = (): AppThunk => async (dispatch, _, context) => {
  const { userService, loggingService } = context;
  dispatch(fetchNebulaAppLinkPending());

  try {
    const { link } = await userService.getNebulaAppDownloadLink();

    dispatch(fetchNebulaAppLinkSucceed(link));
  } catch (e) {
    dispatch(fetchNebulaAppLinkFailed());
    const err = normalizeError(e);
    loggingService.error(err);
  }
};

export const updateUserPending = createAction('user/UPDATE_USER/pending');
export const updateUserSucceed = createAction('user/UPDATE_USER/succeed');

export const updateUser =
  ({ phone_number, quiz, utm_params }: UserUpdateDTO): AppThunk =>
  async (dispatch, _, context) => {
    const { userService, loggingService } = context;
    dispatch(updateUserPending());

    try {
      await userService.updateUser({ phone_number, quiz, utm_params });
      dispatch(updateUserSucceed());
    } catch (e) {
      const err = normalizeError(e);
      loggingService.error(err);
      return null;
    }
  };

export const createOrder =
  ({
    email,
    offerName,
    quizName,
    analyticsIds,
    emailFlow,
    reports,
  }: {
    email: string;
    offerName: string;
    quizName: string;
    analyticsIds: {
      facebook: Array<string>;
      tikTok: Array<string>;
    };
    emailFlow: string;
    reports?: Record<string, ReportType[]>;
  }): AppThunk =>
  async (dispatch, getState, context) => {
    const { purchaseService, analyticsService, observabilitySystem, heatmapRecordingService } =
      context;
    const userInformation = getUserInformation(getState());
    const isUserTester = getIsUserTester(getState());
    const mediaSources = analyticsService.getAttributionParameters();
    const utmTags = getUtmTags(getState());
    const sessionId = analyticsService.getSessionId();
    const deviceId = analyticsService.getDeviceId();

    const index = email.indexOf('@');
    const name = userInformation.name || email.slice(0, index);

    const hasFacebookPixelIds = analyticsIds.facebook.length > 0;
    const hasTikTokPixelIds = analyticsIds.tikTok.length > 0;
    const hasAnalyticsIds = hasTikTokPixelIds || hasFacebookPixelIds;

    const data: CreateOrderParams = {
      order_data: {
        subjects: [
          {
            assigned_to: 'partner_1',
            birth_place: {
              place: userInformation.location,
              longitude: userInformation.place[0] || 0.05,
              latitude: userInformation.place[1] || 51.45,
            },
            gender: userInformation.gender,
            birth_date: userInformation.date || '1990-01-01',
            birth_time: userInformation.time,
          },
          {
            assigned_to: 'partner_2',
            birth_place: {
              place: userInformation.partnerLocation,
              latitude: userInformation.partnerPlace[0] || 0.05,
              longitude: userInformation.partnerPlace[1] || 51.45,
            },
            gender: userInformation.partnerGender || 'male',
            birth_date: userInformation.partnerDate || '1990-01-01',
            birth_time: userInformation.partnerTime,
          },
        ],
        reports,
      },
      quiz: quizName,
      media_sources: {
        ...mediaSources,
        ...utmTags,
      },
      email,
      offer_name: offerName,
      project: 'Nebula',
      customer_settings: {
        do_not_sell_data: false,
        report_type: userInformation.relationshipType,
        ...(hasAnalyticsIds
          ? {
              pixels: {
                ...(hasFacebookPixelIds ? { facebook: analyticsIds.facebook } : {}),
                ...(hasTikTokPixelIds ? { tik_tok: analyticsIds.tikTok } : {}),
              },
            }
          : {}),
        emailFlow,
      },
      quiz_data: {
        email,
        name,
        birth_time: userInformation.time || '12:00:00',
        gender: userInformation.gender,
        birth_date: userInformation.date || '1990-01-01',
        partnerName: userInformation.partnerName,
        partnerTime: userInformation.partnerTime || '12:00:00',
        partner_gender: userInformation.partnerGender || 'male',
        partner_birth_date: userInformation.partnerDate || '1990-01-01',
        partner_birth_place: userInformation.partnerLocation,
        flow_choice: userInformation.relationshipType,
        flow_goal:
          userInformation.palmReadingGoal ||
          userInformation.wpGoal ||
          userInformation.relationshipGoal,
        wpGoal: userInformation.wpGoal,
        relationship: userInformation.relationship,
        mainPain: userInformation.mainPain,
        challenges: userInformation.challenges,
        topics: userInformation.topics,
        relationshipGoal: userInformation.relationshipGoal,
        palmReadingGoal: userInformation.palmReadingGoal,
        astrology_knowledge: userInformation.astrologyKnowledge,
        traits_relate_to_statement: userInformation.relateToStatement,
        traits_most_important: userInformation.mostImportant,
        relationshipStatus: userInformation.relationshipStatus,
        relationship_not_good_enough: userInformation.goodEnough,
        relationship_ideal_date: userInformation.idealDate,
        relationship_future_partner: userInformation.futurePartner,
        relationship_ideal_partner: userInformation.idealPartner,
        relationship_how_to_make_decisions: userInformation.decisions,
        palmistry_scan_id: userInformation.scan.photoId,
        ancestor_data: userInformation.ancestor_data,
        nature_data: userInformation.nature_data,
        tradition_data: userInformation.tradition_data,
        crowdComfort_data: userInformation.crowdComfort_data,
        intuitiveHealing_data: userInformation.intuitiveHealing_data,
        moonPhase_data: userInformation.moonPhase_data,
        worryPartnerLeaves: userInformation.worryPartnerLeaves,
      },
      sessionId,
      deviceId,
    };

    const { orderId, userId } = await purchaseService.createOrder(data);

    observabilitySystem.setAttributes({
      [ATTRS_USER_ID]: userId,
    });

    analyticsService.setIsUserTester(isUserTester);
    analyticsService.addEmail(email, userId);
    analyticsService.addUserId(userId);
    analyticsService.lead(orderId);
    heatmapRecordingService.identify(email);

    dispatch(updateUserInformation('orderData', data.order_data));
    dispatch(updateUserInformation('email', email));
    dispatch(updateOrderId(orderId));
  };

export const fetchUserInformation = (): AppThunk => async (dispatch, _, context) => {
  const { userService, loggingService: logger, heatmapRecordingService } = context;

  try {
    const data = await userService.getUserInformation();
    heatmapRecordingService.identify(data.email);

    dispatch(updateUserPreferences(data));
  } catch (e) {
    const err = normalizeError(e);
    logger.error(err);
    return null;
  }
};
