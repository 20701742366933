import { RootInitialState } from 'core/common/store/rootReducer';

export const getPaymentProcessStep = (state: RootInitialState) => {
  return state.payments.paymentProcessStep;
};

export const getPaymentError = (state: RootInitialState) => {
  return state.payments.paymentError;
};

export const getPaymentFormMerchantData = (state: RootInitialState) => {
  return state.payments.formMerchantData;
};

export const getPaymentFormPaypalData = (state: RootInitialState) => {
  return state.payments.formPaypalData;
};

export const getResignFormData = (state: RootInitialState) => {
  return state.payments.resignFormData;
};

export const getOrderId = (state: RootInitialState) => state.payments.orderId;
