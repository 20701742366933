import { createAction } from '@reduxjs/toolkit';
import { notAvailable } from 'core/common/constants';
import { normalizeError } from 'core/common/errors';
import { SpanStatusCode } from 'core/common/observability/entities';
import { AppThunk } from 'core/common/store';
import { PaymentError, PaymentFormData } from '../../entities';
import { getOrderId } from '../selectors';

export const initializePaymentFormPending = createAction(
  'payments/INITIALIZE_PAYMENT_FORM/pending',
);
export const updateOrderId = createAction<string>('payments/UPDATE_ORDER_ID');

export const initializePaymentFormSucceed = createAction<PaymentFormData>(
  'payments/INITIALIZE_PAYMENT_FORM/succeed',
);
export const initializePaymentFormFailed = createAction<PaymentError>(
  'payments/INITIALIZE_PAYMENT_FORM/failed',
);

export const initializePaymentFormRequest =
  (offerId: string): AppThunk =>
  async (dispatch, getState, context) => {
    const {
      purchaseService,
      loggingService: logger,
      observabilitySystem,
      analyticsService,
      errorMonitoringService,
    } = context;

    const orderId = getOrderId(getState());

    dispatch(initializePaymentFormPending());

    const span = observabilitySystem.startSpan('initialize_form_request');
    span.addEvent('Pending');

    try {
      if (!orderId) return;

      const formData = await purchaseService.createPaymentFromProductName(orderId, offerId);

      span.addEvent('Succeed');
      span.end();

      dispatch(initializePaymentFormSucceed(formData));
      analyticsService.trackEvent('initialize_form_succeed');
    } catch (err) {
      const error = normalizeError(err);
      logger.error(error);

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();
      errorMonitoringService.captureError(error, {
        tags: {
          offer_id: offerId,
          order_id: orderId,
          get_params: location.search || notAvailable,
          error_message: error.message,
          error_reason: 'initialize_form_failed',
        },
      });

      dispatch(initializePaymentFormFailed({ message: error.message }));
      analyticsService.trackEvent('initialize_form_failed', {
        orderId,
        offerId,
      });
    }
  };

export const paymentFormVerify = createAction(`payments/PAYMENT_FORM_VERIFY`);

export const paymentFormFailed = createAction(`payments/PAYMENT_FORM_FAILED`);
