import { OrganicChannel } from 'core/common/entities';

export const ORGANIC_REFERRER_PATTERNS_OBJ: Record<OrganicChannel, RegExp> = {
  [OrganicChannel.GOOGLE]: /^(https?:\/\/)?(www\.|m\.)?google\.[a-z]{2,3}(?:\.[a-z]{2})?(\/|$)/,
  [OrganicChannel.BING]: /^(https?:\/\/)?(www\.)?bing\.[a-z]{2,3}(?:\.[a-z]{2})?(\/|$)/,
  [OrganicChannel.YAHOO]:
    /^(https?:\/\/)?(www\.|m\.)?(search\.)?yahoo\.[a-z]{2,3}(?:\.[a-z]{2})?(\/|$)/,

  [OrganicChannel.DUCKDUCKGO]: /^(https?:\/\/)?(www\.)?duckduckgo\.com(\/|$)/,
  [OrganicChannel.ECOSIA]: /^(https?:\/\/)?(www\.)?ecosia\.org(\/|$)/,
};
